import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { GlobalModule } from '@app/_global/global.module';
import { SiteService } from '@app/site/site.service';
import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { AbonneSelectorModule } from '@app/abonne/abonne-selector/abonne-selector';
import { SiteSelectorModule } from '@app/site/site-selector';


import { uid } from '@helpers/utils';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { AbonneService } from '@app/abonne/abonne.service';

@Component({
	selector: 'diagnostic-duplication-dialog',
	template: `
		<form class="mb-2" #duplicationForm="ngForm" (ngSubmit)="submit()">
			<div class="p-fluid formgrid grid">

				<!-- Abonné -->
				<div class="field col-10">
					<label>
						{{'abonnes.abonne_label' | translate}}
					</label>
					<abonne-selector
						*ngIf="isCurrentUserAdmin"
						name="destination_abo_id"
						[(ngModel)]="destination_abo_id"
						required
					>
					</abonne-selector>
				</div>

				<!-- Site -->
				<div class="field col-10">
					<label>
						{{'diagnostics.target_site_label' | translate}}
					</label>
					<site-selector
						name="destination_sit_id"
						[abo_id]="destination_abo_id"
						[(ngModel)]="destination_sit_id"
						[excludedSites]="[sit_id_source]"
						required
					>
					</site-selector>
				</div>
			</div>

			<!-- Submit -->
			<div class="text-center w-full mt-4">
				<button
					type="submit"
					pButton
					[label]="'common.duplicate_button_label' | translate"
					[disabled]="!duplicationForm.form.valid">
				</button>
			</div>
		</form>
	`,
	providers: []
})
export class DiagnosticDuplicationDialogComponent implements OnInit, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	sit_id_source: number;
	destination_abo_id: number;
	destination_sit_id: number;

	isCurrentUserAdmin = false;

	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private translateService: TranslateService,
		private siteService: SiteService,
		private utilisateurService: UtilisateurService,
		private abonneService: AbonneService
	) {
		this.sit_id_source = config.data.sit_id_source;
		this.isCurrentUserAdmin = utilisateurService.currentUtilisateurValue!.uti_administrateur;

		if (!this.isCurrentUserAdmin) {
			this.destination_abo_id = this.abonneService.currentAbonneValue!.abo_id;
		}
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {});
	}

	submit(){
		this.siteService.duplicateDiagnostic(this.sit_id_source, this.destination_abo_id, this.destination_sit_id)
		.subscribe({
			next: ({ partial }) => {
				let translationKey =
					partial ? 'diagnostics.duplication_success_but_partial_because_limited_access'
					: 'diagnostics.duplication_success';

				this.eventManager.emit('toast', { severity: 'success', summary: this.translateService.instant(translationKey) });
				this.dialogRef.close();
			}
		})
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		GlobalModule,
		ButtonModule,
		InputTextareaModule,
		AbonneSelectorModule,
		SiteSelectorModule
	],
	exports: [DiagnosticDuplicationDialogComponent],
	declarations: [DiagnosticDuplicationDialogComponent]
})
export class DiagnosticDuplicationDialogModule { }

