import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { UIRouterModule } from '@uirouter/angular';

import { MenuModule } from '@app/primeng-overrides/menu';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { Abonne } from '@app/abonne/abonne.model';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';


@Component({
	selector: 'abonne-abonnement',
	templateUrl: `abonne-abonnement.component.html`
})
export class AbonneAbonnementComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input({alias: 'abonne_courant'}) abonne: Abonne;

	constructor() {}

	ngOnInit(): void {
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
	],
	exports: [AbonneAbonnementComponent],
	declarations: [AbonneAbonnementComponent]
})
export class AbonneAbonnementModule { }
