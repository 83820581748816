import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, NgModule, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { GlobalModule } from '@app/_global/global.module';
import { NomenclatureSelectorModule } from '@app/nomenclature/nomenclature-selector';
import { Article } from '@app/texte/texte.model';
import { EventManagerService, IEventListener } from '@global/event-manager.service';

import { uid } from '@helpers/utils';

@Component({
	selector: 'deplacement-article-dialog',
	template: `
		<form #form class="mb-2 h-full" #ajoutRubriqueDialog="ngForm" (ngSubmit)="submit()">

			<div class="text-center pb-4 border-bottom-1 border-gray-200">
				<!-- Bouton enregistrer -->
				<button
					type="button"
					pButton
					class="label-md-none"
					[label]="'common.validate_button_label' | translate"
					icon="pi pi-check"
					(click)="submit()"
				></button>
			</div>

			<!-- Articles -->
			<div class="h-30rem overflow-y-auto">
				<div
					*ngFor="let article of articles"
					class="border-bottom-1 border-gray-200 flex align-items-center"
					id="article-mover{{article.art_id}}"
				>
					<span class="text-sm flex-shrink-0 mr-1">#{{article.art_ordre +1}}</span>
					<span class="flex-grow-1 white-space-nowrap overflow-hidden text-overflow-ellipsis" [ngClass]="{'font-bold': article.art_id == focusedArticleId}">
						{{article.art_titre || article.art_corps_plaintext?.slice(0, 50)}}
					</span>

					<div class="flex align-items-center flex-shrink-0">
						<!-- Déplacement vers le haut -->
						<button
							type="button"
							pButton
							icon="pi pi-arrow-up"
							class="p-button-rounded p-button-text p-button-sm"
							(click)="moveArticleUp(article)"
							[title]="'textes.move_article_up' | translate"
						></button>

						<!-- Déplacement vers le bas -->
						<button
							type="button"
							pButton
							icon="pi pi-arrow-down"
							class="p-button-rounded p-button-text p-button-sm"
							(click)="moveArticleDown(article)"
							[title]="'textes.move_article_down' | translate"
						></button>
					</div>
				</div>
			</div>
		</form>
	`,
	providers: []
})
export class DeplacementArticlesDialogComponent implements OnInit, AfterViewInit, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	articles: Article[];
	focusedArticleId?: number;

	@ViewChild('form') formElement: ElementRef<HTMLFormElement>;

	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
	) {
		this.articles = config.data.articles;
		this.focusedArticleId = config.data.focusedArticleId;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			if (this.focusedArticleId) {
				let element = this.formElement.nativeElement.querySelector('#article-mover' + this.focusedArticleId) as HTMLElement;

				element.scrollIntoView({behavior: 'smooth', block: 'start'});
			}
		}, 100)
	}

	async moveArticleUp(article: Article) {
		let index = this.articles.findIndex((art: Article) => art.art_id == article.art_id)
		if (index <= 0) {
			return;
		}

		// Echange
		let replacedArticle = this.articles[index-1];
		this.articles[index] = replacedArticle
		replacedArticle.art_ordre++;

		this.articles[index-1] = article;
		article.art_ordre--;
		this.articles = [...this.articles];
	}

	async moveArticleDown(article: Article) {
		let index = this.articles.findIndex((art: Article) => art.art_id == article.art_id)
		if (index == this.articles.length - 1) {
			return;
		}

		// Echange
		let replacedArticle = this.articles[index+1];
		this.articles[index] = replacedArticle;
		replacedArticle.art_ordre--;

		this.articles[index+1] = article;
		article.art_ordre++;
		this.articles = [...this.articles];
	}

	submit(){
		this.dialogRef.close(this.articles);
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		GlobalModule,
		NomenclatureSelectorModule
	],
	exports: [DeplacementArticlesDialogComponent],
	declarations: [DeplacementArticlesDialogComponent]
})
export class DeplacementArticlesDialogModule { }

