<!-- Logo et menu -->
<div class="flex flex-row align-items-center">

	<div class="logo mr-2 hidden block" title="{{version}}">
		<img src="/assets/images/logo.png" class="logo-small">
	</div>

	<button
		type="button"
		pButton
		class="bg-white text-gray-900 border-0"
		(click)="accessToSeveralAbonnes && goToAbonneSelection()"
		[ngClass]="{'cursor-pointer': accessToSeveralAbonnes}"
	>
		<div *ngIf="currentAbonne" class="flex align-items-center" title="{{'abonnes.change_abonne' | translate}}">
			<div class="flex align-items-center mr-1">
				<logo-display [abonne]="currentAbonne" width="1rem"></logo-display>
			</div>
			<div>{{currentAbonne.abo_nom_abonne}}</div>

		</div >

		<div *ngIf="!currentAbonne" title="{{'abonnes.current_abonne_selector' | translate}}">
			{{'abonnes.current_abonne_selector' | translate}}
		</div>

		<i *ngIf="accessToSeveralAbonnes" class="pi pi-sync text-sm ml-2"></i>
	</button>

</div>

<!-- Profil utilisateur -->
<div class="flex align-items-center">
	<button
		type="button"
		pButton
		class="p-button-rounded p-button-text user-menu-opener"
		icon="pi pi-user"
		(click)="currentUtilisateurMenu.toggle($event)"
		[label]="
			currentUtilisateur?.uti_administrateur ? ('users.admin_role_name' | translate)
			: isSubscriptionManager ? ('users.subscription_manager_role_name' | translate)
			: (currentUtilisateur|utilisateur:false)
		"
	></button>
	<p-menu styleClass="min-w-20rem" #currentUtilisateurMenu [popup]="true" [model]="currentUtilisateurMenuItems" appendTo="body"></p-menu>
</div>

