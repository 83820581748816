<!-- Titre -->
<div class="page-title">
	{{ 'users.user_list_page_title' | translate }}
</div>

<p-toolbar styleClass="pb-0">
	<ng-template pTemplate="left">
		<div class="grid formgrid fluid w-full">
			<!-- Recherche -->
			<div class="field col-fixed">
				<div class="p-inputgroup ">
					<input
						pInputText
						type="text"
						[(ngModel)]="params.search"
						(onDebounce)="refresh()"
						[attr.placeholder]="'common.search_input_placeholder' | translate"
					/>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!params.search || loading" (click)="resetSearch()"></button>
				</div>
			</div>

			<!-- Sélecteur d'abonné -->
			<div class="field col-fixed">
				<abonne-selector
					*ngIf="!abonne_courant"
					name="abo_id"
					[(ngModel)]="params.abo_id"
					(ngModelChange)="refresh()"
					[placeholder]="'abonnes.abonne_label' | translate"
					[nullOption]="true"
					[noAbonneId]="-1"
				></abonne-selector>
			</div>

			<!-- Coche Actif/Inactif -->
			<div class="col-fixed field field-checkbox">
				<p-checkbox
					name="uti_actif"
					inputId="uti_actif"
					[(ngModel)]="params.uti_actif"
					[binary]="true"
					[label]="'users.inactive_state_plural' | translate"
					[trueValue]="false"
					[falseValue]="true"
					(ngModelChange)="refresh()"
				></p-checkbox>
			</div>
		</div>

	</ng-template>

	<ng-template pTemplate="right">
		<div class="grid formgrid fluid w-full">
			<div class="field col-fixed">
				<button
					type="button"
					pButton
					icon="pi pi-bars"
					[label]="'common.actions_button_label' | translate"
					class="p-panel-header-icon p-link"
					(click)="menu_list_utilisateurs.toggle($event)"
					*ngIf="menuItems.length"
				></button>
				<p-menu #menu_list_utilisateurs [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
			</div>
		</div>
	</ng-template>
</p-toolbar>

<p-table
	#tableUtilisateurs
	[value]="values"
	[lazy]="true"
	(onLazyLoad)="loadUtilisateurs($event)"
	[loading]="loading"
	[paginator]="true"
	[rows]="rows"
	[showCurrentPageReport]="true"
	[totalRecords]="totalRecords"
	currentPageReportTemplate="{{totalRecords | number}} {{'common.table_total_records'|translate}}"
	[rowsPerPageOptions]="[10,25,50]"
	styleClass="p-datatable-sm p-datatable-gridlines table-scrollable-hover"
	[autoLayout]="true"
	responsiveLayout="scroll"
	scrollDirection="both"
	[scrollable]="true"
	scrollHeight="flex"
>
	<ng-template pTemplate="header">
		<tr>
			<th class="text-center">
				{{ 'users.salutation_label' | translate }}
			</th>

			<th class="text-center" pSortableColumn="uti_nom">
				{{ 'users.last_name_label' | translate }} <p-sortIcon field="uti_nom"></p-sortIcon>
			</th>

			<th class="text-center" pSortableColumn="uti_prenom">
				{{ 'users.first_name_label' | translate }} <p-sortIcon field="uti_prenom"></p-sortIcon>
			</th>

			<th class="text-center">
				{{ 'users.function_label' | translate }}
			</th>

			<th class="text-center">
				{{ 'common.email_label' | translate }}
			</th>

			<th class="text-center">
				{{ 'common.landline_phone_label' | translate }}
			</th>

			<th class="text-center">
				{{ 'common.cellphone_label' | translate }}
			</th>

			<th class="text-center">
				{{ 'users.subscription_manager_role_name' | translate }}
			</th>

			<th></th> <!-- Boutons -->
		</tr>
	</ng-template>
	<ng-template pTemplate="emptymessage">
		<tr>
			<td class="text-center" colspan="100">Aucun résultat pour ces critères</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-utilisateur>
		<tr [ngClass]="{'inactive': !utilisateur.uti_actif, 'uti-bloque': utilisateur.uti_bloque}">
			<td>{{utilisateur.uti_civilite}}</td>
			<td>{{utilisateur.uti_nom}}</td>
			<td>{{utilisateur.uti_prenom}}</td>
			<td>{{utilisateur.uti_fonction}}</td>
			<td>{{utilisateur.uti_email}}</td>
			<td class="nowrap">{{utilisateur.uti_telephone_fixe}}</td>
			<td class="nowrap">{{utilisateur.uti_telephone_mobile}}</td>
			<td class="nowrap text-center"
				[ngClass]="{'font-bold': gestionnairesAbonnement[utilisateur.uti_id]}"
			>{{
					gestionnairesAbonnement[utilisateur.uti_id] ?
					('common.yes' | translate) : ('common.no' | translate)
				}}
			</td>
			<td class="nowrap" *ngIf="!readonly">
				<a
					pButton
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					uiSref=".single"
					[uiParams]="{uti_id: utilisateur.uti_id}"
				></a>
				<button
					type="button"
					pButton
					icon="pi pi-trash"
					class="p-button-rounded p-button-text p-button-danger"
					(click)="deleteUtilisateur(utilisateur)"
					*ngIf="canEdit && utilisateur.uti_id != me.uti_id"
				></button>
			</td>
		</tr>
	</ng-template>
</p-table>

