<form
	class="p-fluid grid formgrid p-1 readonly-texte"
	#textForm="ngForm"
>
	<div #topBar class="field col-12 flex flew-row align-items-center">
		<!-- Enregistrer -->
		<button
			*ngIf="canEdit"
			type="button"
			pButton
			class="w-max mr-2"
			icon="pi pi-save"
			[label]="'common.save_button_label' | translate"
			(click)="submit()"
			[disabled]="!hasChanges"
		>
		</button>

		<!-- Quitter -->
		<button
			type="button"
			pButton
			class="p-button-secondary w-max"
			[label]="
				((hasChanges && canEdit) ? 'common.exit_without_saving_button_label' : 'common.exit_button_label')
				| translate
			"
			(click)="exit()"
		>
		</button>

		<!-- Afficher/cacher les détails du texte -->
		<button
			type="button"
			pButton
			class="p-button-rounded p-button-text w-max"
			(click)="showTexteDetails = !showTexteDetails"
			icon="p-button-icon-left pi {{showTexteDetails ?  'pi-arrow-down-left-and-arrow-up-right-to-center' : 'pi-arrow-up-right-and-arrow-down-left-from-center'}}"
		>
			{{
				(showTexteDetails ? 'recolements.hide_text_details' : 'recolements.show_text_details')
				| translate
			}}
		</button>
	</div>

	<!-- Référence -->
	<div class="field col-12 md:col-6 xl:col-4">
		<label for="txt_reference">
			{{ 'textes.reference_label' | translate }}
		</label>

		<input
			type="text"
			pInputText
			name="txt_reference"
			[(ngModel)]="texte.txt_reference"
			[readonly]="true"
			placeholder="{{'textes.reference_label' | translate}}"
			required
		>
	</div>

	<!-- Edition -->
	<div class="field col-12 md:col-6 xl:col-4">
		<label for="txt_edition">
			{{ 'textes.edition_label' | translate }}
		</label>

		<input
			type="text"
			pInputText
			name="txt_edition"
			[(ngModel)]="texte.txt_edition"
			[readonly]="true"
			placeholder="{{'textes.edition_label' | translate}}"
		>
	</div>

	<!-- Récolements -->
	<div
		*ngIf="texteRecolement"
		class="field col-12"
	>
		<label>{{'applicability-reviews.reviews' | translate}}</label>

		<div class="p-fluid grid">
			<div
				*ngFor="let recolementTexte of texteRecolement.recolements; let i = index"
				class="col-12 xxl:col-6 flex flex-row mt-4 border-bottom-1 border-gray-100"
			>

				<div class="font-bold w-10rem">
					{{recolementTexte.sit_libelle}}
				</div>

				<div class="flex flex-column w-full">

					<div class="flex flex-row w-full align-items-center gap-3">

						<!-- Statut -->
						<div class="field">
							<label>{{'recolements.status_short' | translate}}</label>
							<statut-recolement-selector
								name="status_recolement_site_{{recolementTexte.sit_id}}_texte_{{texte.txt_id}}"
								[(ngModel)]="recolementTexte.sta_code"
								(ngModelChange)="onRecolementTexteChange(recolementTexte)"
								[readonly]="!canEdit || forcedRecolementTextesBySiteId[recolementTexte.sit_id]"
								styleClass="w-12rem"
							>
							</statut-recolement-selector>
						</div>

						<!-- Evaluation -->
						<div *ngIf="recolementTexte.eva_code != 'aev' && recolementTexte.eva_code != 'nap'" class="field">
							<label>{{'recolements.evaluation_label' | translate}}</label>
							<evaluation-selector
								name="evaluation_site_{{recolementTexte.sit_id}}_texte_{{texte.txt_id}}"
								[(ngModel)]="recolementTexte.eva_code"
								styleClass="w-12rem"
								[disabled]="true"
								[readonly]="true"
							>
							</evaluation-selector>
						</div>

						<!-- Date de réévaluation (échéance) -->
						<div *ngIf="recolementTexte.rec_date_reevaluation" class="field">
							<label>{{'assessments.re_assessment_date_label' | translate}}</label>
							<span class="block">{{recolementTexte.rec_date_reevaluation | date:'shortDate'}}</span>
						</div>
					</div>

					<div class="flex flex-row w-full align-items-center mb-1">
						<!-- Commentaire -->
						<div class="field w-11">
							<label>{{'recolements.commentary' | translate}}</label>
							<textarea
								pInputTextarea
								name="commentaire_recolement_site_{{recolementTexte.sit_id}}_texte_{{texte.txt_id}}"
								[(ngModel)]="recolementTexte.rec_commentaire"
								(ngModelChange)="this.checkHasChanges();"
								placeholder="{{'recolements.commentary' | translate}}"
								[autoResize]="true"
								rows="1"
								[readonly]="!canEdit"
								class="w-full"
							></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>

	<!-- Evaluations -->
	<div
		*ngIf="texteEvaluation"
		class="field col-12"
	>
		<label>{{'assessments.assessments' | translate}}</label>

		<div class="p-fluid grid">
			<div
				*ngFor="let evaluationTexte of texteEvaluation.evaluations; let i = index"
				class="col-12 xxl:col-6 flex flex-row mt-4 border-bottom-1 border-gray-100"
			>

				<div class="font-bold w-10rem">
					{{evaluationTexte.sit_libelle}}
				</div>

				<div class="flex flex-column w-full">

					<div class="flex flex-row w-full align-items-center gap-3">

						<!-- Statut -->
						<div class="field">
							<label>{{'recolements.status_short' | translate}}</label>
							<statut-recolement-selector
								name="status_recolement_site_{{evaluationTexte.sit_id}}_texte_{{texte.txt_id}}"
								[(ngModel)]="evaluationTexte.sta_code"
								[readonly]="true"
								styleClass="w-10rem"
							>
							</statut-recolement-selector>
						</div>

						<!-- Evaluation -->
						<div *ngIf="evaluationTexte.sta_code == 'app'" class="field">
							<label>{{'recolements.evaluation_label' | translate}}</label>
							<evaluation-selector
								name="evaluation_site_{{evaluationTexte.sit_id}}_texte_{{texte.txt_id}}"
								[(ngModel)]="evaluationTexte.eva_code"
								[readonly]="true"
								styleClass="w-12rem"
							>
							</evaluation-selector>
						</div>

						<!-- Date de réévaluation (échéance) -->
						<div *ngIf="evaluationTexte.sta_code == 'app'" class="field col-fixed">
							<label>{{'assessments.re_assessment_date_label' | translate}}</label>
							<p-calendar
								*ngIf="canEdit"
								name="date_reevaluation_site_{{evaluationTexte.sit_id}}_texte_{{texte.txt_id}}"
								[(ngModel)]="evaluationTexte.rec_date_reevaluation"
								(ngModelChange)="this.checkHasChanges();"
								dateFormat="dd/mm/yy"
								firstDayOfWeek="1"
								view="date"
								yearRange="2020:2030"
								[minDate]="today"
								[showIcon]="true"
								[placeholder]="'common.day_month_year_date_placeholder' | translate"
								[readonly]="true"
								appendTo="body"
							></p-calendar>

							<input
								*ngIf="!canEdit"
								type="text"
								pInputText
								[attr.value]="evaluationTexte.rec_date_reevaluation | date:'shortDate'"
								[readonly]="true"
							/>
						</div>
					</div>

					<div class="flex flex-row w-full align-items-center mb-1">
						<!-- Commentaire -->
						<div class="field w-11">
							<label>{{'recolements.commentary' | translate}}</label>
							<textarea
								pInputTextarea
								name="commentaire_recolement_site_{{evaluationTexte.sit_id}}_texte_{{texte.txt_id}}"
								[(ngModel)]="evaluationTexte.rec_commentaire"
								(ngModelChange)="this.checkHasChanges();"
								placeholder="{{'recolements.commentary' | translate}}"
								[autoResize]="true"
								rows="1"
								[readonly]="!canEdit"
								class="w-full"
							></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>

	<div class="col-12"></div>

	<!-- Intitulé (HTML) -->
	<div class="field col-12">
		<label for="txt_intitule">
			{{ 'textes.intitule' | translate }}
		</label>

		<div class="w-full">
			<html-editor
				[headerSizes]="htmlEditorHeaderSizes"
				name="txt_intitule"
				[(ngModel)]="texte.txt_intitule"
				[readonly]="true"
				class="mt-1 no-padding-html-view no-border-html-view"
			></html-editor>
		</div>
	</div>

	<ng-container *ngIf="showTexteDetails">
		<!-- Rubriques -->
		<div class="field col-12 mb-4 mt-2">
			<label for="txt_edition">
				{{ 'textes.rubriques_label' | translate }}
			</label>

			<div class="flex flex-row gap-3">

				<!-- Rubriques -->
				<p-card *ngFor="let rubrique of texte.rubriques">
					<div class="flex flex-row align-items-center justify-content-between">
						<span>{{rubrique|nomenclature}}</span>
					</div>
				</p-card>

				<!-- Message si aucune rubrique -->
				<div *ngIf="texte.rubriques.length == 0">
					{{
						(texte.txt_brouillon ? 'textes.texte_cannot_have_rubriques' : 'textes.texte_linked_to_no_rubrique') | translate
					}}
				</div>
			</div>
		</div>

		<!-- URL -->
		<div class="field col-12">
			<label for="txt_url">
				{{ 'textes.link_label' | translate }}
			</label>

			<div>
				<a href="{{texte.txt_url}}" class="text-blue-500">
					{{texte.txt_url}}
				</a>
			</div>
		</div>

		<!-- Date signature -->
		<div class="field col-12 md:col-6 xl:col-3">
			<label for="txt_date_signature">
				{{ 'textes.signature_date_label' | translate }}
			</label>

			<input
				[ngClass]="{inactive: !texte.txt_date_signature}"
				type="text"
				pInputText
				[attr.value]="texte.txt_date_signature | date:'short'"
				[readonly]="true"
			>
		</div>

		<div class="col-12"></div>

		<!-- Date abrogation -->
		<div class="field col-12 md:col-6 xl:col-3">
			<label for="txt_date_abrogation">
				{{ 'textes.abrogation_date_label' | translate }}
			</label>

			<input
				[ngClass]="{inactive: !texte.txt_date_abrogation}"
				type="text"
				pInputText
				[attr.value]="texte.txt_date_abrogation | date:'short'"
				[readonly]="true"
			>
		</div>

		<!-- Abrogation (booléen) -->
		<div class="field field-checkbox col-fixed">
			<p-checkbox
				name="txt_abrogation"
				[(ngModel)]="texte.txt_abrogation"
				[binary]="true"
				[readonly]="true"
				[label]="'textes.abrogation_label' | translate"
			></p-checkbox>
		</div>

		<div class="col-12"></div>

		<!-- Date application -->
		<div class="field col-12 md:col-6 xl:col-3">
			<label for="txt_date_application">
				{{ 'textes.application_date_label' | translate }}
			</label>

			<input
				[ngClass]="{inactive: !texte.txt_date_application}"
				type="text"
				pInputText
				[attr.value]="texte.txt_date_application | date:'short'"
				[readonly]="true"
			>
		</div>

		<!-- Note d'applicaion -->
		<div class="field col-12 md:col-6 xl:col-3">
			<label for="txt_application">
				{{ 'textes.application_note_label' | translate }}
			</label>

			<input
				type="text"
				pInputText
				name="txt_application"
				[(ngModel)]="texte.txt_application"
				[readonly]="true"
				placeholder="{{'textes.application_note_label' | translate}}"
			>
		</div>

		<div class="col-12"></div>

		<!-- Date modificative -->
		<div class="field col-12 md:col-6 xl:col-3">
			<label for="txt_date_modificative">
				{{ 'textes.modification_date_label' | translate }}
			</label>

			<input
				[ngClass]="{inactive: !texte.txt_date_modificative}"
				type="text"
				pInputText
				[attr.value]="texte.txt_date_modificative | date:'short'"
				[readonly]="true"
			>
		</div>

		<!-- Note sur la modification -->
		<div class="field col-12 md:col-6 xl:col-3">
			<label for="txt_modification">
				{{ 'textes.modification_note_label' | translate }}
			</label>

			<input
				type="text"
				pInputText
				name="txt_modification"
				[(ngModel)]="texte.txt_modification"
				[readonly]="true"
				placeholder="{{'textes.modification_note_label' | translate}}"
			>
		</div>

		<div class="col-12"></div>

		<div class="field col-6 xxl:col-3">
			<label for="sta_code_defaut">
				{{ 'textes.defaut_recolement_status' | translate }}
			</label>

			<statut-recolement-selector
				name="sta_code_defaut"
				[(ngModel)]="texte.sta_code_defaut"
				[readonly]="true"
				placeholder="{{'textes.defaut_recolement_status' | translate}}"
			></statut-recolement-selector>
		</div>

		<div class="col-12"></div>

		<!-- Commentaire (HTML) -->
		<div class="field col-12">
			<label for="txt_commentaire" class="font-bold">
				{{ 'textes.commentaire_label' | translate }}
			</label>

			<div class="w-full">
				<html-editor
					[headerSizes]="htmlEditorHeaderSizes"
					name="txt_commentaire"
					[(ngModel)]="texte.txt_commentaire"
					[readonly]="true"
					class="mt-1 no-padding-html-view no-border-html-view"
				></html-editor>
			</div>
		</div>
	</ng-container>

	<p-divider class="col-12" align="center">
		<b>{{'textes.articles_section_label' | translate}}<span *ngIf="articlesConformites.length">({{articlesConformites.length}})</span></b>
	</p-divider>


	<div class="field col-12 flex flex-row justify-content-between mb-2 sticky top-0 bg-white z-5">
		<div class="flex flex-column xl:flex-row xl:align-items-center">
			<!-- Recherche -->
			<div *ngIf="texte.articles.length" class="field">
				<label for="search">{{'common.search_label'|translate}}</label>
				<div class="p-inputgroup mr-2">
					<input
						pInputText
						type="text"
						name="search"
						[(ngModel)]="search"
						(onDebounce)="this.refreshArticleList()"
						placeholder="{{'common.search_input_placeholder' | translate}}"
					/>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!search" (click)="search = null" ></button>
				</div>
			</div>

			<!-- Filtre sur le statut de récolement des articles -->

			<div *ngIf="texte.articles.length && etapeConformite == 'recolements'" class="field ml-3">
				<label for="sta_code">{{'recolements.articles_status_label' | translate}}</label>

				<statut-recolement-selector
					name="sta_code"
					[(ngModel)]="sta_code_article"
					(ngModelChange)="this.refreshArticleList()"
					[nullOption]="true"
					placeholder="{{'recolements.status' | translate}}"
					styleClass="w-full"
				></statut-recolement-selector>
			</div>

			<div class="field m-0 mt-2">
				<button
					*ngIf="canEdit"
					type="button"
					pButton
					class="p-button-rounded p-button-text w-max"
					icon="pi pi-arrow-up"
					[label]="'common.go_to_top_button_label' | translate"
					(click)="scrollToTheTop()"
				></button>
			</div>

		</div>

		<!-- Interface de changement des articles de récolement 'Inconnu' -->
		<div *ngIf="canEdit && etapeConformite == 'recolements'" class="flex flex-row align-items-end">

			<div class="field">
				<button
					type="button"
					pButton
					class="w-max"
					[label]="'recolements.change_recolements_with_unknown_status' | translate"
					(click)="replaceUnknownStatusInBulk()"
					[disabled]="!bulkStatutsReplacementSiteId || !bulkStatutsReplacement"
				>
				</button>
			</div>

			<div class="field w-12rem ml-3">
				<label for="sta_code">{{'sites.site_label' | translate}}</label>

				<site-selector
					name="bulkStatutsReplacementSiteId"
					[abo_id]="abonne_courant.abo_id"
					[(ngModel)]="bulkStatutsReplacementSiteId"
					[sit_ids]="siteIds"
				></site-selector>
			</div>

			<div class="field w-12rem ml-3">
				<label for="sta_code">{{'recolements.new_status_label' | translate}}</label>

				<statut-recolement-selector
					name="bulkStatutsReplacement"
					[(ngModel)]="bulkStatutsReplacement"
					[nullOption]="true"
					[excludedStatuses]="['inc']"
					placeholder="{{'recolements.new_status_label' | translate}}"
				></statut-recolement-selector>
			</div>
		</div>
	</div>

	<!-- Articles -->
	<div class="article-scroller w-full">

		<div *uiScroll="let article of datasource" id="article{{article.art_ordre}}">

			<!-- Séparateur entre chaque article -->
			<div
				class="block col-12 mt-2 mb-2 flex flex-row align-items-center"
			>
				<div class="border-top-1 border-gray-100 flex-grow-1"></div>
			</div>

			<!-- Header (absent en mode lecture si titre vide) -->
			<div class="flex flex-row align-items-center w-full p-2 surface-ground border-round">
				<!-- Titre -->
				<div class="flex-grow-1 flex align-items-center">
					<span class="text-sm flex-shrink-0 mr-2">#{{article.art_ordre +1}}</span>
					<span class="font-bold">
						{{article.art_titre}}
					</span>
				</div>

				<div>
					<!-- Abrogation article -->
					<div class="field-checkbox mb-0 ml-3">
						<p-checkbox
							name="actif"
							inputId="{{'article-abrogation-'+article.art_id}}"
							[(ngModel)]="article.art_abrogation"
							[binary]="true"
							label="{{'textes.abrogation_label' | translate}}"
							[readonly]="true"
						></p-checkbox>
					</div>

				</div>

				<!-- Boutons sur la droite -->
				<div class="flex flex-row">

					<!-- Réduction -->
					<button
						type="button"
						pButton
						icon="p-button-icon-left pi {{ expandedArticles[article.art_id]? 'pi-arrow-down-left-and-arrow-up-right-to-center' : 'pi-arrow-up-right-and-arrow-down-left-from-center' }}"
						class="p-button-rounded p-button-text"
						(click)="expandedArticles[article.art_id] = !!!expandedArticles[article.art_id]"
						[title]="(expandedArticles[article.art_id]? 'common.collapse': 'common.expand') | translate"
					>
					</button>
				</div>

			</div>

			<!-- Corps (HTML) -->
			<div
				*ngIf="article.art_corps"
				#articleBody
				class="article-body w-full p-2"
				[ngClass]="{expanded: expandedArticles[article.art_id]}"
				(click)="expandedArticles[article.art_id] = true"
			>
				<html-editor
					[headerSizes]="htmlEditorHeaderSizes"
					name="art_corps-{{article.art_id}}"
					[(ngModel)]="article.art_corps"
					[readonly]="true"
					class="cursor-pointer no-padding-html-view no-border-html-view"
				></html-editor>
			</div>

			<!-- Récolements -->
			<div *ngIf="etapeConformite == 'recolements'" class="p-2 mt-2">
				<div *ngFor="let recolementArticle of recolementsArticlesById[article.art_id]" class="mb-2">
					<div class="flex flex-row w-full align-items-center">
						<div class="font-bold w-10rem">
							{{recolementArticle.sit_libelle}}
						</div>

						<!-- Statut -->
						<div class="flex flex-row align-items-center gap-1">
							{{'recolements.status_short' | translate}}
							<statut-recolement-selector
								name="status_recolement_site_{{recolementArticle.sit_id}}_article_{{article.art_id}}"
								[(ngModel)]="recolementArticle.sta_code"
								(ngModelChange)="onRecolementArticleChange(recolementArticle)"
								styleClass="w-12rem"
								[readonly]="
									!canEdit
									|| effectiveStatutTexteBySite[recolementArticle.sit_id] == 'nap' && !forcedRecolementTextesBySiteId[recolementArticle.sit_id]
								"
								[excludedStatuses]="
									effectiveStatutTexteBySite[recolementArticle.sit_id] == 'inf' ? ['app'] : []
								"
							>
							</statut-recolement-selector>
						</div>

						<!-- Commentaire -->
						<div *ngIf="canEdit || recolementArticle.ear_commentaire" class="flex flex-row align-items-center gap-1 ml-4 min-w-40rem">
							{{'recolements.commentary' | translate}}
							<input
								pInputText
								type="text"
								name="commentaire_recolement_site_{{recolementArticle.sit_id}}_article_{{article.art_id}}"
								[(ngModel)]="recolementArticle.ear_commentaire"
								(change)="checkHasChanges()"
								placeholder="{{'recolements.commentary' | translate}}"
								[readonly]="!canEdit"
							/>
						</div>

						<!-- Evaluation -->
						<div *ngIf="recolementArticle.sta_code == 'app' && recolementArticle.eva_code != 'nap'" class="flex flex-row align-items-center gap-1 ml-4">
							{{'recolements.evaluation_label' | translate}}
							<evaluation-selector
								name="evaluation_site_{{recolementArticle.sit_id}}_article_{{article.art_id}}"
								[(ngModel)]="recolementArticle.eva_code"
								styleClass="w-12rem"
								[disabled]="true"
								[readonly]="true"
							>
							</evaluation-selector>
						</div>

						<!-- Date de réévaluation (échéance) -->
						<div *ngIf="recolementArticle.rec_date_reevaluation" class="flex flex-row align-items-center gap-1 ml-4">
							{{'assessments.re_assessment_date_label' | translate}}
							<span>{{recolementArticle.rec_date_reevaluation | date:'shortDate'}}</span>
						</div>

					</div>
				</div>
			</div>

			<!-- Evaluations -->
			<div *ngIf="etapeConformite == 'evaluations'" class="p-2 mt-2">
				<div *ngFor="let evaluationArticle of evaluationsArticlesById[article.art_id]" class="mb-2">
					<div class="flex flex-row w-full align-items-center">
						<div class="font-bold w-10rem">
							{{evaluationArticle.sit_libelle}}
						</div>

						<!-- Statut -->
						<div class="flex flex-row align-items-center gap-1">
							{{'recolements.status_short' | translate}}
							<statut-recolement-selector
								name="status_recolement_site_{{evaluationArticle.sit_id}}_article_{{article.art_id}}"
								[(ngModel)]="evaluationArticle.sta_code"
								styleClass="w-10rem"
								[disabled]="true"
								[readonly]="true"
							>
							</statut-recolement-selector>
						</div>

						<!-- Evaluation -->
						<div *ngIf="evaluationArticle.sta_code == 'app'" class="flex flex-row align-items-center gap-1 ml-4">
							{{'recolements.evaluation_label' | translate}}
							<evaluation-selector
								name="evaluation_site_{{evaluationArticle.sit_id}}_article_{{article.art_id}}"
								[(ngModel)]="evaluationArticle.eva_code"
								(ngModelChange)="checkHasChanges()"
								styleClass="w-12rem"
								[readonly]="!canEdit"
								[excludedValues]="['nap']"
							>
							</evaluation-selector>
						</div>

						<!-- Date de réévaluation (échéance) -->
						<div
							*ngIf="evaluationArticle.sta_code == 'app' && evaluationArticle.eva_code == 'con'"
							class="flex flex-row align-items-center gap-1 ml-4"
						>
							<label>{{'assessments.re_assessment_date_label' | translate}}</label>
							<p-calendar
								*ngIf="canEdit"
								name="date_reevaluation_site_{{evaluationArticle.sit_id}}_article_{{article.art_id}}"
								[(ngModel)]="evaluationArticle.ear_date_reevaluation"
								(ngModelChange)="checkHasChanges()"
								dateFormat="dd/mm/yy"
								firstDayOfWeek="1"
								view="date"
								yearRange="2020:2030"
								[minDate]="today"
								[showIcon]="true"
								[placeholder]="'common.day_month_year_date_placeholder' | translate"
								appendTo="body"
							></p-calendar>

							<span class="ml-1" *ngIf="!canEdit">{{evaluationArticle.ear_date_reevaluation | date:'shortDate'}}</span>
						</div>

						<!-- Commentaire -->
						<div *ngIf="canEdit || evaluationArticle.ear_commentaire" class="flex flex-row align-items-center gap-1 ml-4 min-w-40rem">
							{{'recolements.commentary' | translate}}
							<textarea
								pInputTextarea
								name="commentaire_recolement_site_{{evaluationArticle.sit_id}}_article_{{article.art_id}}"
								[(ngModel)]="evaluationArticle.ear_commentaire"
								(change)="checkHasChanges()"
								placeholder="{{'recolements.commentary' | translate}}"
								[autoResize]="true"
								[readonly]="!canEdit"
							></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</form>
