import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Subscription } from 'rxjs';

import { TranslateService } from '@codeandweb/ngx-translate';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { Abonne } from '@app/abonne/abonne.model';
import { GzTabMenuModule } from '@app/components/tabmenu';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';
import { AbonneLogoDisplayModule } from './abonne-logo-display/abonne-logo-display';

@Component({
	selector: 'abonne',
	templateUrl: `abonne.component.html`
})
export class AbonneComponent implements OnInit, OnDestroy {

	@Input() me: Utilisateur;
	@Input() abonne: Abonne;

	tabs: any[];

	initialAbonne: Abonne;
	langChangeSubscription: Subscription;

	constructor(
		private stateService: StateService,
		private translationService: TranslateService,
	) {
		this.setTabs();

		this.langChangeSubscription = translationService.onLangChange.subscribe(() => {
			this.setTabs();
		});
	}

	ngOnInit(): void {
		this.initialAbonne = structuredClone(this.abonne);
	}

	ngOnDestroy(): void {
		this.langChangeSubscription.unsubscribe();
	}

	setTabs() {
		this.tabs = [
			{
				label: this.translationService.instant('abonnes.infos_tab_name'),
				state: '.informations',
			},

			{
				label: this.translationService.instant('abonnes.logo_tab_name'),
				state: '.logo',
			},

			{
				label: this.translationService.instant('abonnes.families_tab_name'),
				state: '.familles',
			},

			{
				label: this.translationService.instant('abonnes.security_tab_name'),
				state: '.securite',
			},
		];
	}
}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ToolbarModule,
		PanelModule,
		DividerModule,
		GzTabMenuModule,
		AbonneLogoDisplayModule
	],
	exports: [AbonneComponent],
	declarations: [AbonneComponent]
})
export class AbonneModule { }
