<!-- SANS -->
<p-panel [ngClass]="{'readonly-texte': !editMode}">

	<ng-template pTemplate="header">
		<div class="flex align-items-center">
			<!-- Titre -->
			<div class="page-title-no-margin">
				{{
					isCreation ? ('textes.new_text' | translate)
					: pristineTexte.txt_reference
				}}
			</div>

			<!-- Coche actif/inactif -->
			<div class="field-checkbox mb-0 ml-3">
				<p-checkbox
					name="txt_actif"
					inputId="txt_actif"
					[(ngModel)]="texte.txt_actif"
					[binary]="true"
					[label]="'textes.active_state' | translate"
					[disabled]="!editMode"
				></p-checkbox>
			</div>

			<!-- Coche modification majeure -->
			<div *ngIf="!texte.txt_brouillon" class="field-checkbox mb-0 ml-3">
				<p-checkbox
					name="txt_modification_majeure"
					[(ngModel)]="texte.txt_modification_majeure"
					[binary]="true"
					label="{{'textes.major_modification_label' | translate}}"
					[disabled]="!editMode"
				></p-checkbox>
			</div>

		</div>
	</ng-template>

	<ng-template pTemplate="icons">
		<!-- Bouton éditer -->
		<button
			*ngIf="!editMode && canEdit"
			type="button"
			pButton
			class="label-md-none"
			[label]="'common.edition_button_label' | translate"
			icon="pi pi-pencil"
			(click)="toggleEditMode()"
		></button>

		<!-- Bouton enregistrer -->
		<button
			*ngIf="!!editMode"
			type="button"
			pButton
			class="label-md-none"
			[label]="'common.save_button_label' | translate"
			icon="pi pi-save"
			(click)="submit()"
			[disabled]="!textForm.valid || texte.articles.length == 0"
			[loading]="loading"
		></button>

		<!-- Bouton publier -->
		<button
			*ngIf="!isCreation && canEdit && texte.txt_brouillon"
			type="button"
			pButton
			class="label-md-none ml-2"
			[label]="'textes.publish_button_label' | translate"
			icon="pi pi-file-check"
			(click)="publish()"
			[disabled]="editMode"
		></button>
	</ng-template>

	<form class="p-fluid grid formgrid p-1" #textForm="ngForm">
		<ng-container>
			<!-- Référence -->
			<div class="field col-12 md:col-6 xl:col-4">
				<label for="txt_reference">
					{{ 'textes.reference_label' | translate }}
				</label>

				<input
					type="text"
					pInputText
					name="txt_reference"
					[(ngModel)]="texte.txt_reference"
					[readonly]="!editMode"
					placeholder="{{'textes.reference_label' | translate}}"
					required
				>
			</div>

			<!-- Edition -->
			<div class="field col-12 md:col-6 xl:col-4">
				<label for="txt_edition">
					{{ 'textes.edition_label' | translate }}
				</label>

				<input
					type="text"
					pInputText
					name="txt_edition"
					[(ngModel)]="texte.txt_edition"
					[readonly]="!editMode"
					placeholder="{{'textes.edition_label' | translate}}"
				>
			</div>

			<div class="col-12"></div>

			<!-- URL -->
			<div class="field col-12">
				<label for="txt_url">
					{{ 'textes.link_label' | translate }}
				</label>

				<div *ngIf="!editMode">
					<a href="{{texte.txt_url}}" class="text-blue-500">
						{{texte.txt_url}}
					</a>
				</div>

				<input
					*ngIf="editMode"
					type="text"
					pInputText
					name="txt_url"
					[(ngModel)]="texte.txt_url"
					placeholder="{{'textes.link_label' | translate}}"
				>
			</div>

			<!-- Rubriques -->
			<div class="field col-12 mb-4">
				<label for="txt_edition">
					{{ 'textes.rubriques_label' | translate }}
				</label>

				<!-- Bouton d'ajout -->
				<button
					*ngIf="editMode && canHaveRubriques"
					type="button"
					pButton
					class="p-button-rounded p-button-text"
					icon="pi pi-plus"
					(click)="showRubriqueAdditionDialog()"
				></button>

				<div class="flex flex-row gap-3">

					<!-- Rubriques -->
					<p-card *ngFor="let rubrique of texte.rubriques">
						<div class="flex flex-row align-items-center justify-content-between">
							<span>{{rubrique|nomenclature}}</span>

							<div *ngIf="editMode" class="flex flex-row align-items-center">
								<button
									type="button"
									pButton
									icon="pi pi-times-circle"
									class="p-button-rounded p-button-text p-button-danger"
									(click)="removeRubrique(rubrique)"
								></button>
							</div>
						</div>
					</p-card>

					<!-- Message si aucune rubrique -->
					<div *ngIf="texte.rubriques.length == 0">
						{{
							(texte.txt_brouillon ? 'textes.texte_cannot_have_rubriques' : 'textes.texte_linked_to_no_rubrique') | translate
						}}
					</div>
				</div>
			</div>

			<div class="col-12"></div>

			<!-- Date signature -->
			<div class="field col-12 md:col-6 xl:col-3">
				<label for="txt_date_signature">
					{{ 'textes.signature_date_label' | translate }}
				</label>

				<input
					*ngIf="!editMode"
					[ngClass]="{inactive: !texte.txt_date_signature}"
					type="text"
					pInputText
					[attr.value]="texte.txt_date_signature | date:'short'"
					[readonly]="true"
				>

				<p-calendar
					*ngIf="editMode"
					name="txt_date_signature"
					[(ngModel)]="texte.txt_date_signature"
					dateFormat="dd/mm/yy"
					firstDayOfWeek="1"
					view="date"
					yearRange="1900:2100"
					[showIcon]="true"
					placeholder="{{'textes.signature_date_label' | translate}}"
				></p-calendar>
			</div>

			<div class="col-12"></div>

			<!-- Date abrogation -->
			<div class="field col-12 md:col-6 xl:col-3">
				<label for="txt_date_abrogation">
					{{ 'textes.abrogation_date_label' | translate }}
				</label>

				<input
					*ngIf="!editMode"
					[ngClass]="{inactive: !texte.txt_date_abrogation}"
					type="text"
					pInputText
					[attr.value]="texte.txt_date_abrogation | date:'short'"
					[readonly]="true"
				>

				<p-calendar
					*ngIf="editMode"
					name="txt_date_abrogation"
					[(ngModel)]="texte.txt_date_abrogation"
					(ngModelChange)="onDateAbrogationChange()"
					dateFormat="dd/mm/yy"
					firstDayOfWeek="1"
					view="date"
					yearRange="1900:2100"
					[showIcon]="true"
					[showClear]="true"
					placeholder="{{'textes.abrogation_date_label' | translate}}"
					[required]="texte.txt_abrogation && (!pristineTexte.txt_abrogation || pristineTexte.txt_date_abrogation)"
				></p-calendar>
				<!--
					La date est requise si elle était fournie avant et que le texte est toujours 'abrogé' ou bien
				  	si on vient de cocher abrogé et qu'il faut fournir la date
				-->
			</div>

			<!-- Abrogation (booléen) -->
			<div class="field field-checkbox col-fixed">
				<p-checkbox
					name="txt_abrogation"
					[(ngModel)]="texte.txt_abrogation"
					[binary]="true"
					[readonly]="!editMode"
					[label]="'textes.abrogation_label' | translate"
				></p-checkbox>
			</div>

			<div class="col-12"></div>

			<!-- Date application -->
			<div class="field col-12 md:col-6 xl:col-3">
				<label for="txt_date_application">
					{{ 'textes.application_date_label' | translate }}
				</label>

				<input
					*ngIf="!editMode"
					[ngClass]="{inactive: !texte.txt_date_application}"
					type="text"
					pInputText
					[attr.value]="texte.txt_date_application | date:'short'"
					[readonly]="true"
				>

				<p-calendar
					*ngIf="editMode"
					name="txt_date_application"
					[(ngModel)]="texte.txt_date_application"
					dateFormat="dd/mm/yy"
					firstDayOfWeek="1"
					view="date"
					yearRange="1900:2100"
					[showIcon]="true"
					[showClear]="true"
					placeholder="{{'textes.application_date_label' | translate}}"
				></p-calendar>
			</div>

			<!-- Note d'applicaion -->
			<div class="field col-12 md:col-6 xl:col-3">
				<label for="txt_application">
					{{ 'textes.application_note_label' | translate }}
				</label>

				<input
					type="text"
					pInputText
					name="txt_application"
					[(ngModel)]="texte.txt_application"
					[readonly]="!editMode"
					placeholder="{{'textes.application_note_label' | translate}}"
				>
			</div>

			<div class="col-12"></div>

			<!-- Date modificative -->
			<div class="field col-12 md:col-6 xl:col-3">
				<label for="txt_date_modificative">
					{{ 'textes.modification_date_label' | translate }}
				</label>

				<input
					*ngIf="!editMode"
					[ngClass]="{inactive: !texte.txt_date_modificative}"
					type="text"
					pInputText
					[attr.value]="texte.txt_date_modificative | date:'short'"
					[readonly]="true"
				>

				<p-calendar
					*ngIf="editMode"
					name="txt_date_modificative"
					[(ngModel)]="texte.txt_date_modificative"
					dateFormat="dd/mm/yy"
					firstDayOfWeek="1"
					view="date"
					yearRange="1900:2100"
					[showIcon]="true"
					[showClear]="true"
					placeholder="{{'textes.modification_date_label' | translate}}"
				></p-calendar>
			</div>

			<!-- Note sur la modification -->
			<div class="field col-12 md:col-6 xl:col-3">
				<label for="txt_modification">
					{{ 'textes.modification_note_label' | translate }}
				</label>

				<input
					type="text"
					pInputText
					name="txt_modification"
					[(ngModel)]="texte.txt_modification"
					[readonly]="!editMode"
					placeholder="{{'textes.modification_note_label' | translate}}"
				>
			</div>

			<div class="col-12"></div>

			<div class="field col-6 xxl:col-3">
				<label for="sta_code_defaut">
					{{ 'textes.defaut_recolement_status' | translate }}
				</label>

				<statut-recolement-selector
					name="sta_code_defaut"
					[(ngModel)]="texte.sta_code_defaut"
					[readonly]="!editMode"
					placeholder="{{'textes.defaut_recolement_status' | translate}}"
				></statut-recolement-selector>
			</div>

			<div class="col-12"></div>

			<!-- Intitulé (HTML) -->
			<div class="field col-12">
				<label for="txt_intitule" class="font-bold">
					{{ 'textes.intitule' | translate }}
				</label>

				<input
					*ngIf="!editMode && !texte.txt_intitule"
					type="text"
					pInputText
					[readonly]="true"
					class="block w-6 xxl:w-3"
				>

				<div *ngIf="editMode || texte.txt_intitule" class="w-full">
					<html-editor
						[headerSizes]="htmlEditorHeaderSizes"
						name="txt_intitule"
						[(ngModel)]="texte.txt_intitule"
						[readonly]="!editMode"
						class="mt-1"
						[ngClass]="!editMode ? 'no-padding-html-view no-border-html-view' : ''"
					></html-editor>
				</div>
			</div>

			<!-- Commentaire (HTML) -->
			<div class="field col-12">
				<label for="txt_commentaire" class="font-bold">
					{{ 'textes.commentaire_label' | translate }}
				</label>

				<input
					*ngIf="!editMode && !texte.txt_commentaire"
					type="text"
					pInputText
					[readonly]="true"
					class="block w-6 xxl:w-3"
				>

				<div *ngIf="editMode || texte.txt_intitule" class="w-full">
					<html-editor
						[headerSizes]="htmlEditorHeaderSizes"
						name="txt_commentaire"
						[(ngModel)]="texte.txt_commentaire"
						[readonly]="!editMode"
						class="mt-1"
						[ngClass]="!editMode ? 'no-padding-html-view no-border-html-view' : ''"
					></html-editor>
				</div>
			</div>
		</ng-container>

		<p-divider class="col-12" align="center">
			<b>{{'textes.articles_section_label' | translate}}<span *ngIf="texte.articles.length">({{texte.articles.length}})</span></b>
		</p-divider>

		<!-- Bouton d'ajout si aucun article -->
		<div *ngIf="texte.articles.length == 0" class="field col-12">
			<div class="w-full mb-2 font-bold">
				{{'textes.at_least_one_article_needed' | translate}}
			</div>

			<button
				type="button"
				pButton
				icon="pi pi-plus"
				class=""
				(click)="addArticle(0)"
				[label]="'textes.add_article' | translate"
				*ngIf="editMode"
			></button>
			<span *ngIf="!editMode">{{'textes.no_article_defined'|translate}}</span>
		</div>

		<!-- Recherche -->
		<div *ngIf="texte.articles.length" class="field col-fixed">
			<label for="search">{{'common.search_label'|translate}}</label>
			<div class="p-inputgroup mr-2">
				<input
					pInputText
					type="text"
					name="search"
					[(ngModel)]="search"
					(onDebounce)="onArticleSearchChange()"
					placeholder="{{'common.search_input_placeholder' | translate}}"
				/>
				<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!search || loading" (click)="search = null" ></button>
			</div>
		</div>

		<!-- Articles -->
		<div class="article-scroller overflow-auto w-full" style="max-height: 700px">
			<div *uiScroll="let article of datasource" id="article{{article.art_ordre}}">


				<!-- Séparateur entre chaque article -->
				<div
					class="block col-12 mt-2 mb-2 flex flex-row align-items-center"
				>
					<div class="border-top-1 border-gray-100 flex-grow-1"></div>

					<!-- Bouton d'ajout d'article avant l'article courant -->
					<button
						*ngIf="editMode"
						type="button"
						pButton
						icon="pi pi-plus"
						class="p-button-rounded"
						(click)="addArticle(article.art_ordre)"
						[title]="'textes.add_article_here' | translate"
					></button>

					<div class="border-top-1 border-gray-100 flex-grow-1"></div>
				</div>

				<!-- Header (absent en mode lecture si titre vide) -->
				<div class="flex flex-row align-items-center w-full p-2 surface-ground border-round">
					<!-- Titre -->
					<div class="flex-grow-1 flex align-items-center">
						<span class="text-sm flex-shrink-0 mr-2">#{{article.art_ordre +1}}</span>
						<span *ngIf="!editMode" class="font-bold">
							{{article.art_titre}}
						</span>

						<input
							*ngIf="editMode"
							type="text"
							pInputText
							name="art_titre-{{article.art_id}}"
							[(ngModel)]="article.art_titre"
							[readonly]="!editMode"
							placeholder="{{'common.title_label' | translate}}"
							[required]="true"
						>
					</div>

					<div class="flex flex-row align-items-center mr-2">
						<!-- Abrogation article -->
						<div class="field-checkbox mb-0 ml-3">
							<p-checkbox
								name="article-abrogation{{article.art_id}}"
								[(ngModel)]="article.art_abrogation"
								[binary]="true"
								label="{{'textes.abrogation_label' | translate}}"
								[disabled]="!editMode"
							></p-checkbox>
						</div>

						<!-- Modification majeure -->
						<div *ngIf="!texte.txt_brouillon" class="field-checkbox mb-0 ml-3">
							<p-checkbox
								name="article-modif-majeure{{article.art_id}}"
								[(ngModel)]="article.art_modification_majeure"
								[binary]="true"
								label="{{'textes.major_modification_label' | translate}}"
								[disabled]="!editMode"
							></p-checkbox>
						</div>
					</div>

					<!-- Boutons sur la droite -->
					<div class="flex flex-row">

						<!-- Réduction -->
						<button
							type="button"
							pButton
							icon="p-button-icon-left pi {{ expandedArticles[article.art_id]? 'pi-arrow-down-left-and-arrow-up-right-to-center' : 'pi-arrow-up-right-and-arrow-down-left-from-center' }}"
							class="p-button-rounded p-button-text"
							(click)="expandedArticles[article.art_id] = !!!expandedArticles[article.art_id]"
							[title]="(expandedArticles[article.art_id]? 'common.collapse': 'common.expand') | translate"
						>
						</button>

						<!-- Déplacement -->
						<button
							*ngIf="editMode"
							type="button"
							pButton
							icon="pi pi-sort-alt"
							class="p-button-rounded p-button-text"
							(click)="openArticleMoveDialog(article)"
							[title]="'textes.move_article_button_label' | translate"
						></button>

						<!-- Suppression -->
						<button
							*ngIf="editMode"
							type="button"
							pButton
							icon="pi pi-trash"
							class="p-button-rounded p-button-text"
							(click)="deleteArticle(article)"
							[title]="'common.delete' | translate"
						></button>
					</div>

				</div>

				<!-- Corps (HTML) -->
				<div
					*ngIf="editMode || article.art_corps"
					#articleBody
					class="article-body w-full p-2"
					[ngClass]="{expanded: expandedArticles[article.art_id]}"
					(click)="expandedArticles[article.art_id] = true"
				>
					<html-editor
						[headerSizes]="htmlEditorHeaderSizes"
						name="art_corps-{{article.art_id}}"
						[(ngModel)]="article.art_corps"
						[readonly]="!editMode"
						class="cursor-pointer"
						[ngClass]="!editMode ? 'no-padding-html-view no-border-html-view' : ''"
					></html-editor>
				</div>
			</div>

			<!-- Bouton d'ajout d'article fin de liste -->
			<div *ngIf="editMode && texte.articles.length" class="block col-12 flex flex-row align-items-center">
				<div class="border-top-1 border-gray-100 flex-grow-1"></div>
				<button
					type="button"
					pButton
					icon="pi pi-plus"
					class="p-button-rounded"
					(click)="addArticle(texte.articles.length)"
					[title]="'textes.add_article_here' | translate"
				></button>
				<div class="border-top-1 border-gray-100 flex-grow-1"></div>
			</div>
		</div>


	</form>

</p-panel>
