import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { MenuModule } from '@app/primeng-overrides/menu';
import { ConfirmationService } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { DialogService } from 'primeng/dynamicdialog';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { EventManagerService } from '@app/_global/event-manager.service';
import { FamilleLienDialogComponent } from '@app/abonne/abonne-familles-tab/famille-lien-dialog';
import { Abonne } from '@app/abonne/abonne.model';
import { Famille } from '@app/nomenclature/nomenclature.model';
import { NomenclatureService } from '@app/nomenclature/nomenclature.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';

@Component({
	selector: 'abonnes-famille-tab',
	templateUrl: `abonne-familles-tab.component.html`
})
export class AbonneFamillesTabComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input() abonne: Abonne;

	initialAbonne: Abonne;

	isCreation = false;
	canEdit = false;
	editMode = false;

	constructor(
		private nomenclatureService: NomenclatureService,
		private stateService: StateService,
		private dialogService: DialogService,
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private translateService: TranslateService,
	) {}

	ngOnInit(): void {
	}

	openFamilleLinkDialog(){
		const ref = this.dialogService.open(FamilleLienDialogComponent, {
			header: this.translateService.instant('families.linking_modal_title'),
			width: '25rem',
			data: {
				unavailableFamilies: this.abonne.familles.map(famille => famille.fam_id),
			}
		});
		ref.onClose.subscribe((fam_id?: number) => {
			if (fam_id === undefined) {
				return;
			}

			this.nomenclatureService.linkFamilleToAbonne(fam_id, this.abonne.abo_id)
			.subscribe({
				complete: () => {
					this.stateService.go('.', undefined, {reload: '^'});
				}
			});
		});
	}

	unlinkFamille(famille: Famille){
		this.confirmationService.confirm({
				defaultFocus: 'reject',
				message: this.translateService.instant('families.unlink_confirmation'),
				accept: () => {
					this.nomenclatureService.unlinkFamilleFromAbonne(famille.fam_id, this.abonne.abo_id)
					.subscribe(
						(response: any) => {
							this.eventManager.emit('toast', {
								severity: 'success',
								summary: this.translateService.instant('families.unlink_success'),
							});
							this.stateService.go('.', undefined, {reload: '^'});
						}
					);
				}
		});
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		DividerModule,
	],
	exports: [AbonneFamillesTabComponent],
	declarations: [AbonneFamillesTabComponent]
})
export class AbonneFamillesTabModule { }
