<!-- Titre -->
<div class="page-title">
	{{ 'applicability-reviews.reviews_page_title' | translate }}
</div>


<div class="flex flew-row align-items-center">
	<p-multiSelect
		name="sit_ids"
		[options]="sites"
		[(ngModel)]="selected_sit_ids"
		[filter]="false"
		[placeholder]="'applicability-reviews.select_sites' | translate"
		optionValue="sit_id"
		optionLabel="sit_libelle"
		appendTo="body"
		styleClass="w-full"
		class="w-20rem"
	/>

	<button
		type="button"
		pButton
		class="label-md-none w-min ml-2"
		[label]="'common.load' | translate"
		icon="pi pi-arrow-down"
		(click)="load()"
	></button>
</div>

