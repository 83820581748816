import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnChanges, OnDestroy, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subscription } from 'rxjs';

import { TranslateService } from '@codeandweb/ngx-translate';

import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { SiteService } from '@app/site/site.service';
import { Site } from '@app/site/site.model';

import { ControlValueAccessorBase } from '@app/_helpers/control-value-accessor-base';


@Component({
	selector: 'site-selector',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SiteSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="sites"
			[(ngModel)]="value"
			[readonly]="readonly"
			[ngClass]="{'p-readonly': readonly}"
			[placeholder]="_placeholder"
			[disabled]="disabled"
			optionLabel="sit_libelle"
			optionValue="sit_id"
			[showClear]="nullOption"
			appendTo="body"
		>
		</p-dropdown>
	`
})
export class SiteSelectorComponent extends ControlValueAccessorBase<number> implements OnInit, OnDestroy, OnChanges {

	@Input() name: string;
	@Input({required: true}) abo_id: number;
	@Input('value') innerValue: number;
	@Input() required: boolean;
	@Input() readonly: boolean;
	@Input() placeholder: string;
	@Input() nullIfInvalid: boolean;
	@Input() nullOption: boolean;
	@Input() disabled: boolean;
	@Input() site_ids: number[];
	@Input() excludedSites: number[];

	_placeholder: string = 'Sélectionnez';

	sites: Site[] = [];

	subscriptionLangChange: Subscription;

	constructor(private siteService: SiteService, private translateService: TranslateService) {
		super();

		this.subscriptionLangChange = this.translateService.onLangChange.subscribe(() => {
			this._placeholder = this.translateService.instant('common.selector_placeholder');
		});
	}

	ngOnInit() {
		if (this.placeholder != undefined) {
			this._placeholder = this.placeholder;
		}

		if (this.abo_id != undefined) {
			this.load();
		}
	}

	ngOnDestroy(): void {
		this.subscriptionLangChange.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.abo_id != undefined && changes.abo_id.currentValue != undefined) {
			this.load();
		}
	}

	load() {
		this.siteService.getCacheSites(this.abo_id)
		.subscribe(({ sites }) => {
			if (this.excludedSites != undefined) {
				sites = sites.filter((site: Site) => !this.excludedSites.includes(site.sit_id))
			}

			this.sites = sites;
		});
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		AutoCompleteModule,
		DropdownModule,
	],
	exports: [SiteSelectorComponent],
	declarations: [SiteSelectorComponent]
})
export class SiteSelectorModule { }
