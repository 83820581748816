<!-- Titre -->
<div class="page-title">
	{{
		(
			etapeConformite == 'recolements'
			? 'applicability-reviews.reviews_page_title_with_site_names'
			: 'assessments.page_title_with_site_names'
		) | translate:{siteNames}
	}}
</div>

<p-toolbar styleClass="pb-0">
	<ng-template pTemplate="left">
		<div class="grid formgrid fluid w-full">
			<!-- Sélection de la nomenclature -->
			<div class="field col-12 md:col-4">
				<label for="search">{{'nomenclature.label'|translate}}</label>
				<nomenclature-selector
					*ngIf="mergedNomenclatureDiagnostics"
					[(ngModel)]="nomenclatureFilter"
					placeholder="{{'common.selector_placeholder' | translate}}"
					(onSelectionChange)="nomenclatureSelectionChange($event)"
					[treeSelect]="true"
					[nomenclature]="mergedNomenclatureDiagnostics"
				></nomenclature-selector>
			</div>

			<!-- Recherche -->
			<div class="field col-12 md:col-3">
				<label for="search">{{'common.search_label'|translate}}</label>
				<div class="p-inputgroup mr-2">
					<input
						pInputText
						type="text"
						name="search"
						[(ngModel)]="params.search"
						(onDebounce)="searchChange()"
						[debounce]="500"
						placeholder="{{'common.search_input_placeholder' | translate}}"
					/>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!params.search || loading" (click)="params.search = null" ></button>
				</div>
			</div>

			<!-- Recherche par référence -->
			<div class="field col-12 md:col-3">
				<label for="filter_fam">{{'textes.reference'|translate}}</label>
				<div class="p-inputgroup mr-2">
					<input
						pInputText
						type="text"
						name="search_reference"
						[(ngModel)]="params.search_reference"
						(onDebounce)="refresh()"
						[attr.placeholder]="'common.search_input_placeholder' | translate"
					/>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!params.search_reference || loading" (click)="params.search_reference = null"></button>
				</div>
			</div>

			<!-- Tri -->
			<div class="field col-12 md:col-3 xxl:col-2">
				<label for="order_by">{{'common.sort_label'|translate}}</label>
				<p-dropdown
					name="order_by"
					[options]="orderByOptions"
					[(ngModel)]="params.order_by"
					(onChange)="refresh()"
					optionValue="value"
					optionLabel="label"
					[showClear]="!params.search"
					appendTo="body"
					class="p-dropdown-w-full"
				>
				</p-dropdown>
			</div>


			<!-- Filtres pour les récolements -->
			<ng-container *ngIf="etapeConformite == 'recolements'">
				<!-- Filtre sur le statut de récolement -->
				<div class="field col-12 md:col-3 xxl:col-2">
					<label>{{'recolements.status' | translate}}</label>

					<statut-recolement-selector
						name="sta_code"
						[(ngModel)]="params.sta_code"
						(ngModelChange)="refresh()"
						[nullOption]="true"
						styleClass="w-full"
					>
					</statut-recolement-selector>
				</div>

				<!-- Filtres avancés -->
				<ng-container *ngIf="showAdvancedFilters">
					<!-- Filtre sur l'évaluation des textes -->
					<div class="field col-12 md:col-3 xxl:col-2">
						<label>{{'recolements.evaluation_label' | translate}}</label>

						<evaluation-selector
							name="eva_code"
							[(ngModel)]="params.eva_code"
							(ngModelChange)="refresh()"
							[nullOption]="true"
							styleClass="w-full"
						>
						</evaluation-selector>
					</div>

					<!-- Filtre sur l'évaluation des articles -->
					<div class="field col-12 md:col-3 xxl:col-2">
						<label>
							{{'recolements.article_evaluation_label' | translate}}
							<i
								class="pi pi-question-circle"
								pTooltip="{{'recolements.article_evaluation_filter_explanation' | translate}}"
								tooltipStyleClass="max-w-30rem"
							>
							</i>
						</label>

						<evaluation-selector
							name="eva_code_article"
							[(ngModel)]="params.eva_code_article"
							(ngModelChange)="refresh()"
							[nullOption]="true"
							styleClass="w-full"
						>
						</evaluation-selector>
					</div>

					<!-- Intervalle de date de réévaluation -->
					<div class="field col-fixed">
						<label for="dates_reevaluation">{{'recolements.reevaluation_date_range_label'|translate}}</label>
						<p-calendar
							name="dates_reevaluation"
							[(ngModel)]="dateReevaluationRange"
							selectionMode="range"
							appendTo="body"
							view="date"
							dateFormat="dd/mm/yy"
							[minDate]="today"
							[showIcon]="true"
							[showButtonBar]="true"
							[showClear]="params.date_debut_reevaluation"
							(onClose)="setReevaluationDates(dateReevaluationRange)"
							(onClear)="setReevaluationDates(null)"
							class="p-inputgroup"
							placeholder="{{'common.select_two_dates'|translate}}"
						>
						</p-calendar>
					</div>

					<!-- Intervalle de date de sortie -->
					<div class="field col-fixed">
						<label for="dates_sortie">{{'recolements.release_date_range_label'|translate}}</label>
						<p-calendar
							name="dates_sortie"
							[(ngModel)]="dateSortieRange"
							selectionMode="range"
							appendTo="body"
							view="date"
							dateFormat="dd/mm/yy"
							[maxDate]="today"
							[showIcon]="true"
							[showButtonBar]="true"
							[showClear]="params.date_debut_sortie"
							(onClose)="setReleaseDates(dateSortieRange)"
							(onClear)="setReleaseDates(null)"
							class="p-inputgroup"
							placeholder="{{'common.select_two_dates'|translate}}"
						>
						</p-calendar>
					</div>

					<!-- Coche pour afficher les textes statués hors diagnostis-->
					<div class="field col-12 md:col-3 xl:col-fixed flex flex-row align-items-end pb-2">
						<p-checkbox
							name="hors_diagnostic"
							[(ngModel)]="params.hors_diagnostic"
							(ngModelChange)="refresh()"
							[binary]="true"
							[label]="'recolements.not_included_in_diagnostics' | translate"
						></p-checkbox>
					</div>

					<!-- Coche pour afficher les textes privés seulements -->
					<div class="field col-12 md:col-3 xl:col-fixed flex flex-row align-items-end pb-2">
						<p-checkbox
							name="hors_diagnostic"
							[(ngModel)]="params.prive"
							(ngModelChange)="refresh()"
							[binary]="true"
							[label]="'recolements.private_textes_label' | translate"
						></p-checkbox>
					</div>

					<!-- Coche pour les textes abrogés -->
					<div class="field col-12 md:col-3 xl:col-fixed flex flex-row align-items-end pb-2">
						<p-checkbox
							name="hors_diagnostic"
							[(ngModel)]="params.abroge"
							(ngModelChange)="refresh()"
							[binary]="true"
							[label]="'textes.abrogated_text_filter_label' | translate"
						></p-checkbox>
					</div>

				</ng-container>
			</ng-container>


			<!-- Filtres pour les évaluations -->
			<ng-container *ngIf="etapeConformite == 'evaluations'">

				<!-- Filtre sur l'évaluation des textes -->
				<div class="field col-12 md:col-3 xxl:col-2">
					<label>{{'recolements.evaluation_label' | translate}}</label>

					<evaluation-selector
						name="eva_code"
						[(ngModel)]="params.eva_code"
						(ngModelChange)="refresh()"
						[nullOption]="true"
						[excludedValues]="['nap']"
						styleClass="w-full"
					>
					</evaluation-selector>
				</div>

				<!-- Filtre sur l'évaluation des articles -->
				<div class="field col-12 md:col-3 xxl:col-2">
					<label>
						{{'recolements.article_evaluation_label' | translate}}
						<i
							class="pi pi-question-circle"
							pTooltip="{{'recolements.article_evaluation_filter_explanation' | translate}}"
							tooltipStyleClass="max-w-30rem"
						>
						</i>
					</label>

					<evaluation-selector
						name="eva_code_article"
						[(ngModel)]="params.eva_code_article"
						(ngModelChange)="refresh()"
						[nullOption]="true"
						styleClass="w-full"
					>
					</evaluation-selector>
				</div>

				<!-- Coche à réévaluer -->
				<div class="field-checkbox col-fixed align-items-end">
					<p-checkbox
						name="orphelin"
						inputId="orphelin"
						[(ngModel)]="params.a_reevaluer"
						[binary]="true"
						label="{{'assessments.textes_to_reevaluate_label' | translate}}"
						[trueValue]="true"
						[falseValue]="false"
						(ngModelChange)="refresh()"
						class="mb-2"
					></p-checkbox>
				</div>

				<!-- Filtres avancés -->
				<ng-container *ngIf="showAdvancedFilters">

					<!-- Filtre sur le statut de récolement -->
					<div class="field col-12 md:col-3 xxl:col-2">
						<label>{{'recolements.status' | translate}}</label>

						<statut-recolement-selector
							name="sta_code"
							[(ngModel)]="params.sta_code"
							(ngModelChange)="refresh()"
							[nullOption]="true"
							styleClass="w-full"
						>
						</statut-recolement-selector>
					</div>


					<!-- Intervalle de date de réévaluation -->
					<div class="field col-fixed">
						<label>{{'recolements.reevaluation_date_range_label'|translate}}</label>
						<p-calendar
							names="dates_reevaluation"
							[(ngModel)]="dateReevaluationRange"
							selectionMode="range"
							appendTo="body"
							view="date"
							dateFormat="dd/mm/yy"
							[minDate]="today"
							[showIcon]="true"
							[showButtonBar]="true"
							[showClear]="params.date_debut_reevaluation"
							(onClose)="setReevaluationDates(dateReevaluationRange)"
							(onClear)="setReevaluationDates(null)"
							class="p-inputgroup"
							placeholder="{{'common.select_two_dates'|translate}}"
						>
						</p-calendar>
					</div>

					<!-- Intervalle de date de sortie -->
					<div class="field col-fixed">
						<label for="date_sortie">{{'recolements.release_date_range_label'|translate}}</label>
						<p-calendar
							name="dates_sortie"
							[(ngModel)]="dateSortieRange"
							selectionMode="range"
							appendTo="body"
							view="date"
							dateFormat="dd/mm/yy"
							[maxDate]="today"
							[showIcon]="true"
							[showButtonBar]="true"
							[showClear]="params.date_debut_sortie"
							(onClose)="setReleaseDates(dateSortieRange)"
							(onClear)="setReleaseDates(null)"
							class="p-inputgroup"
							placeholder="{{'common.select_two_dates'|translate}}"
						>
						</p-calendar>
					</div>

					<!-- Coche pour afficher les textes statués hors diagnostis-->
					<div class="field col-12 md:col-3 xl:col-fixed flex flex-row align-items-end pb-2">
						<p-checkbox
							name="hors_diagnostic"
							[(ngModel)]="params.hors_diagnostic"
							(ngModelChange)="refresh()"
							[binary]="true"
							[label]="'recolements.not_included_in_diagnostics' | translate"
						></p-checkbox>
					</div>

					<!-- Coche pour afficher les textes privés seulements -->
					<div class="field col-12 md:col-3 xl:col-fixed flex flex-row align-items-end pb-2">
						<p-checkbox
							name="hors_diagnostic"
							[(ngModel)]="params.prive"
							(ngModelChange)="refresh()"
							[binary]="true"
							[label]="'recolements.private_textes_label' | translate"
						></p-checkbox>
					</div>

					<!-- Coche pour les textes abrogés -->
					<div class="field col-12 md:col-3 xl:col-fixed flex flex-row align-items-end pb-2">
						<p-checkbox
							name="hors_diagnostic"
							[(ngModel)]="params.abroge"
							(ngModelChange)="refresh()"
							[binary]="true"
							[label]="'textes.abrogated_text_filter_label' | translate"
						></p-checkbox>
					</div>

				</ng-container>
			</ng-container>

			<!-- Activation des filtres avancés -->
			<div class="field col-fixed flex align-items-end md:pl-0">
				<button
					type="button"
					pButton
					class="p-button-rounded p-button-text p-button-sm"
					(click)="toggleAdvancedFilters()"
				>
					<span [translate]="showAdvancedFilters? 'common.hide_filters_button_label' : 'common.advanced_filters_button_label'"></span>
				</button>
			</div>

			<!-- Réinitialisation des filtres -->
			<div class="field col-fixed flex align-items-end">
				<button
					type="button"
					pButton
					class="p-button-rounded p-button-text p-button-sm"
					(click)="resetFilters()"
					label="{{'common.reset_filters'|translate}}"
				>
				</button>
			</div>

			<!-- Rechargement de la page -->
			<div class="field col-fixed flex align-items-end">
				<button
					type="button"
					pButton
					icon="pi pi-refresh"
					class="p-button-rounded p-button-text p-button-sm"
					(click)="refresh()"
					title="{{ 'common.refresh_button_label' | translate}}"
				>
				</button>
			</div>

			<!-- Export -->
			<div class="field col-fixed flex align-items-end">
				<button
					type="button"
					pButton
					icon="pi pi-bars"
					[label]="'common.exports_label' | translate"
					class="p-button-rounded p-button-sm"
					(click)="menu_list_textes.toggle($event)"
					*ngIf="menuItems.length"
				></button>
				<p-menu #menu_list_textes [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
			</div>
		</div>

	</ng-template>
</p-toolbar>

<div class="bg-primary p-2 text-white mb-2 border-round flex-shrink-0" *ngIf="nomenclatureFilter">
	{{nomenclatureFilter|nomenclature}}
</div>

<p-table
	#tableTextes
	[value]="values"
	[lazy]="true"
	(onLazyLoad)="loadTextes($event)"
	[loading]="loading"
	[paginator]="true"
	[rows]="rows"
	[showCurrentPageReport]="true"
	[totalRecords]="totalRecords"
	currentPageReportTemplate="{{totalRecords | number}} {{'common.table_total_records'|translate}}"
	[rowsPerPageOptions]="[10,25,50]"
	styleClass="p-datatable-sm p-datatable-gridlines table-scrollable-hover"
	[autoLayout]="true"
	responsiveLayout="scroll"
	scrollDirection="both"
	[scrollable]="true"
	scrollHeight="flex"
	dataKey="txt_id"
	[expandedRowKeys]="expandedRows"
>
	<ng-template pTemplate="emptymessage">
		<tr>
			<td class="text-center" colspan="100">
				{{ 'common.no_table_results' | translate }}
			</td>
		</tr>
	</ng-template>

	<ng-template pTemplate="header">
		<tr>
			<th class="text-center w-17rem xl:min-w-15rem">{{'textes.reference'|translate}}</th>
			<th class="text-center min-w-20rem xl:min-w-30rem">{{'textes.intitule'|translate}}</th>
			<th class="text-center min-w-15rem xl:min-w-30rem">{{'rubriques.rubrique_label'|translate}}</th>
			<th class="text-center">{{'textes.date_signature'|translate}}</th>
			<th class="text-center">{{'textes.date_application'|translate}}</th>
			<th class="text-center">{{'textes.date_modificative'|translate}}</th>
			<th class="text-center">{{'textes.date_abrogation'|translate}}</th>
			<th class="text-center">{{'textes.date_de_saisie'|translate}}</th>
			<th class="text-center">{{'textes.abrogated'|translate}}</th>
		</tr>
	</ng-template>

	<ng-template pTemplate="body" let-texte let-expanded="expanded">
		<tr class="texte-avec-recolements" [ngClass]="{'inactive': !texte.txt_actif, 'text-green-500 font-bold': texte.txt_brouillon}">
			<td>
				<span>
					{{texte.txt_reference}}
				</span>
			</td>
			<td>{{texte.txt_intitule_plaintext}}</td>
			<td>

				<div class="mb-1" *ngIf="texte.rubriques.length">
					{{texte.rubriques[0] | nomenclature}}
				</div>

				<ng-container *ngIf="texte.rubriques.length > 1">
					<p-accordion class="accordion-transparent">
						<p-accordionTab [header]="'common.and_x_more'|translate:{number: texte.rubriques.length -1}">
							<ng-container *ngFor="let rub of texte.rubriques; first as isFirst">
								<div class="text-sm mb-1" *ngIf="!isFirst">
									• {{rub | nomenclature}}
								</div>
							</ng-container>
						</p-accordionTab>
					</p-accordion>
				</ng-container>
			</td>

			<td class="text-center nowrap">{{texte.txt_date_signature | date:'shortDate'}}</td>
			<td class="text-center nowrap">{{texte.txt_date_application | date:'shortDate'}}</td>
			<td class="text-center nowrap">{{texte.txt_date_modificative | date:'shortDate'}}</td>
			<td class="text-center nowrap">{{texte.txt_date_abrogation | date:'shortDate'}}</td>
			<td class="text-center nowrap">{{texte.txt_date | date:'shortDate'}}</td>
			<td class="text-center nowrap font-bold">
				{{
					texte.txt_abrogation ?
					('common.yes' | translate)
					: ''
				}}
			</td>
		</tr>
	</ng-template>

	<ng-template pTemplate="rowexpansion" let-texte>
		<!-- Récolements -->
		<ng-container *ngIf="etapeConformite == 'recolements'">

			<!-- Une ligne par site -->
			<tr *ngFor="let recolementTexte of texte.recolements; let i = index">
				<td colspan="100">
					<div class="flex flex-row w-full align-items-center">
						<div class="w-14rem mr-2">
							<button
								*ngIf="i == 0"
								pButton
								class="p-button-rounded p-button-text ml-1"
								(click)="openTexteConformiteDialog(texte)"
							>
								{{
									(canEdit ?
										'applicability-reviews.perform_button_label'
										: 'applicability-reviews.show_details_button_label'
									)
									| translate
								}}
							</button>
						</div>

						<div class="font-bold w-10rem">
							{{recolementTexte.sit_libelle}}
						</div>

						<!-- Statut -->
						<div class="flex flex-row align-items-center gap-1">
							<label>{{'recolements.status_short' | translate}}</label>
							<statut-recolement-selector
								name="status_recolement_site_{{recolementTexte.sit_id}}_texte_{{texte.txt_id}}"
								[(ngModel)]="recolementTexte.sta_code"
								[readonly]="true"
								styleClass="w-12rem"
							>
							</statut-recolement-selector>
						</div>

						<!-- Commentaire -->
						<div *ngIf="recolementTexte.rec_commentaire" class="flex flex-row align-items-center gap-1 ml-4 min-w-40rem">
							<label>{{'recolements.commentary' | translate}}</label>
							<textarea
								pInputTextarea
								name="commentaire_recolement_site_{{recolementTexte.sit_id}}_texte_{{texte.txt_id}}"
								[(ngModel)]="recolementTexte.rec_commentaire"
								placeholder="{{'recolements.commentary' | translate}}"
								[autoResize]="true"
								[readonly]="true"
								class="w-full ml-4"
							></textarea>
						</div>

						<ng-container *ngIf="remainingRecolementsArticleBySiteIdByTextId[recolementTexte.sit_id]?.[texte.txt_id] as count; else texteStatue">

							<ng-container *ngIf="recolementTexte.sta_code != 'inc'">
								<i class="pi pi-exclamation-triangle ml-5 mr-2"></i>
								<span
									[translate]="'recolements.text_not_fully_reviewed'"
									[translateParams]="{count: remainingRecolementsArticleBySiteIdByTextId[recolementTexte.sit_id][texte.txt_id]}">
								</span>
							</ng-container>

						</ng-container>

						<ng-template #texteStatue>
							<!-- Evaluation -->
							<div *ngIf="recolementTexte.eva_code != 'aev' && recolementTexte.eva_code != 'nap'" class="flex flex-row align-items-center gap-1 ml-4">
								<label>{{'recolements.evaluation_label' | translate}}</label>
								<evaluation-selector
									name="evaluation_site_{{recolementTexte.sit_id}}_texte_{{texte.txt_id}}"
									[(ngModel)]="recolementTexte.eva_code"
									styleClass="w-12rem"
									[disabled]="true"
									[readonly]="true"
								>
								</evaluation-selector>
							</div>

							<!-- Date de réévaluation (échéance) -->
							<div *ngIf="recolementTexte.rec_date_reevaluation" class="flex flex-row align-items-center gap-1 ml-4">
								<label>{{'assessments.re_assessment_date_label' | translate}}</label>
								<span>{{recolementTexte.rec_date_reevaluation | date:'shortDate'}}</span>
							</div>

						</ng-template>
					</div>
				</td>
			</tr>

		</ng-container>

		<!-- Evaluations -->
		<ng-container *ngIf="etapeConformite == 'evaluations'">
			<!-- Une ligne par site -->
			<tr *ngFor="let evaluationTexte of texte.evaluations; let i = index">
				<td colspan="100">
					<div class="flex flex-row w-full align-items-center">
						<div class="w-14rem mr-2">
							<button
								*ngIf="i == 0"
								pButton
								class="p-button-rounded p-button-text ml-1"
								(click)="openTexteConformiteDialog(texte)"
							>
								 {{
									(
										canEdit && areSomeRecolementsInformative(texte)
										? 'assessments.perform_button_label'
										: 'assessments.show_details_button_label'
									)
									| translate
								}}
							</button>
						</div>

						<div class="font-bold w-10rem">
							{{evaluationTexte.sit_libelle}}
						</div>

						<!-- Statut -->
						<div class="flex flex-row align-items-center gap-1">
							<label>{{'recolements.status_short' | translate}}</label>
							<statut-recolement-selector
								name="status_recolement_site_{{evaluationTexte.sit_id}}_texte_{{texte.txt_id}}"
								[(ngModel)]="evaluationTexte.sta_code"
								styleClass="w-12rem"
								[disabled]="true"
								[readonly]="true"
							>
							</statut-recolement-selector>
						</div>

						<!-- Evaluation -->
						<div *ngIf="evaluationTexte.sta_code == 'app'" class="flex flex-row align-items-center gap-1 ml-4">
							<label>{{'recolements.evaluation_label' | translate}}</label>
							<evaluation-selector
								name="evaluation_site_{{evaluationTexte.sit_id}}_texte_{{texte.txt_id}}"
								[(ngModel)]="evaluationTexte.eva_code"
								styleClass="w-12rem"
								[readonly]="true"
							>
							</evaluation-selector>
						</div>

						<!-- Date de réévaluation (échéance) -->
						<div
							*ngIf="evaluationTexte.sta_code == 'app' && evaluationTexte.rec_date_reevaluation"
							class="flex flex-row align-items-center gap-1 ml-4"
						>
							<label>{{'assessments.re_assessment_date_label' | translate}}</label>
							<span>{{evaluationTexte.rec_date_reevaluation | date:'shortDate'}}</span>
						</div>

						<!-- Commentaire -->
						<div *ngIf="evaluationTexte.rec_commentaire" class="flex flex-row align-items-center gap-1 ml-4 min-w-30rem">
							<label>{{'recolements.commentary' | translate}}</label>
							<textarea
								pInputTextarea
								name="commentaire_recolement_site_{{evaluationTexte.sit_id}}_texte_{{texte.txt_id}}"
								[(ngModel)]="evaluationTexte.rec_commentaire"
								placeholder="{{'recolements.commentary' | translate}}"
								[autoResize]="true"
								[readonly]="true"
								class="w-full ml-4"
							></textarea>
						</div>
					</div>
				</td>
			</tr>

		</ng-container>


	</ng-template>
</p-table>
