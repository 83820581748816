import { CommonModule } from '@angular/common';
import { Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';


import { StateService, UIRouterModule } from '@uirouter/angular';

import { DividerModule } from 'primeng/divider';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { EventManagerService } from '@app/_global/event-manager.service';
import { AbonneService } from '@app/abonne/abonne.service';
import { Famille } from '@app/nomenclature/nomenclature.model';
import { NomenclatureService } from '@app/nomenclature/nomenclature.service';
import { GlobalModule } from '@global/global.module';

import { uid } from '@helpers/utils';


@Component({
	selector: 'famille-dialog',
	templateUrl: `famille-dialog.component.html`
})
export class FamilleDialogComponent implements OnInit, OnDestroy {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	famille: Famille;
	pristine: Famille;

	submitted = false;
	canEdit = false;
	isCreation = false;
	editMode = false;
	loading = false;

	constructor(
		private config: DynamicDialogConfig,
		private stateService: StateService,
		private nomenclatureService: NomenclatureService,
		private eventManager: EventManagerService,
		private dialogRef: DynamicDialogRef,
		private translationService: TranslateService,
		private abonneService: AbonneService,
	) {
		this.canEdit = config.data.canEdit;
		this.famille = config.data.famille;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});

		this.isCreation = this.famille == undefined;
		this.pristine = structuredClone(this.famille);

		if (this.isCreation) {
			this.editMode = true;
			this.famille = {fam_actif: true} as Famille;
		}
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}


	toggleEditMode(status?: boolean) {
		this.editMode = (status !== undefined)? status : !!!this.editMode;
	}

	submit(){
		this.submitted = true;

		if (this.isCreation) {
			this.nomenclatureService.createFamille(this.famille)
			.subscribe({
				next: () => {
					this.eventManager.emit('toast', { severity: 'success', summary: this.translationService.instant('families.family_created_success') });

					this.abonneService.refreshCurrentAbonneData().subscribe();
					this.dialogRef.close({refresh: true});
				}
			})
		}
		else {
			this.nomenclatureService.updateFamille(this.famille)
			.subscribe({
				next: () => {
					this.eventManager.emit('toast', { severity: 'success', summary: this.translationService.instant('families.family_updated_success') });

					this.abonneService.refreshCurrentAbonneData().subscribe();
					this.dialogRef.close({refresh: true});
				}
			})
		}
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ToolbarModule,
		PanelModule,
		DividerModule,
		InputTextareaModule
	],
	exports: [FamilleDialogComponent],
	declarations: [FamilleDialogComponent]
})
export class FamilleDialogModule { }
