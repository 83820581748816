import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from '@app/primeng-overrides/menu';
import { TableModule } from '@app/primeng-overrides/table';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { Abonne } from '@app/abonne/abonne.model';
import { Site } from '@app/site/site.model';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';
import { AbonneService } from '@app/abonne/abonne.service';

@Component({
	selector: 'evaluations',
	templateUrl: `selection-sites-evaluation.html`
})
export class SelectionSitesEvaluationComponent implements OnInit {

	@Input() me: Utilisateur;
	abonne: Abonne;

	selected_sit_ids: number[];
	sites: Site[];

	constructor(private abonneService: AbonneService, private stateService: StateService) {
		this.abonne = this.abonneService.currentAbonneValue!;
	}

	ngOnInit(): void {
		this.sites = this.abonne.sites;
	}

	load() {
		this.stateService.go('^.evaluations', {sit_ids: this.selected_sit_ids});
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		TableModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		InputTextModule,
		CheckboxModule,
		MultiSelectModule
	],
	exports: [SelectionSitesEvaluationComponent],
	declarations: [SelectionSitesEvaluationComponent]
})
export class SelectionSitesEvaluationModule { }
