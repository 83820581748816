import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HookResult, StateService, Transition, UIRouterModule } from '@uirouter/angular';

import { TranslateService } from '@codeandweb/ngx-translate';

import { Observable } from 'rxjs';

import { CalendarModule } from '@app/primeng-overrides/calendar';
import { ConfirmationService } from 'primeng/api';
import { DataViewModule } from 'primeng/dataview';
import { DividerModule } from 'primeng/divider';
import { DialogService } from 'primeng/dynamicdialog';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

import { EventManagerService } from '@app/_global/event-manager.service';
import { AbonneInfosTabModule } from '@app/abonne/abonne-infos-tab/abonne-infos-tab.component';
import { HTMLEditorModule } from '@app/components/html-editor';
import { Rubrique } from '@app/nomenclature/nomenclature.model';
import { NomenclatureService } from '@app/nomenclature/nomenclature.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';
import { AjoutRubriqueDialogComponent } from './ajout-rubrique-dialog/ajout-rubrique-dialog';
import { DeplacementArticlesDialogComponent } from './deplacement-articles-dialog';
import { StatutRecolementSelectorModule } from './statut-recolement-selector';
import { Article, Texte } from './texte.model';
import { ValidationModificationsDialogComponent } from './validation-modifications-dialog';

import { Datasource, UiScrollModule } from 'ngx-ui-scroll';

import { default_header_sizes } from '@app/_helpers/quill-customization';
import { ExtensibleObject, normalizeStringForSearch, simpleComparison } from '@app/_helpers/utils';


@Component({
	selector: 'texte',
	templateUrl: './texte.html'
})
export class TexteComponent implements OnInit {

	@Input() me: Utilisateur;

	@Input() texte: Texte;
	pristineTexte: Texte;

	isCreation = false;
	editMode = false;
	canEdit = false;
	submitted = false;
	canHaveRubriques = false;
	skipExitStateCheck = false;

	htmlEditorHeaderSizes: unknown[];

	expandedArticles: Record<number, boolean> = {};
	search: string | null;

	datasource = this.createScrollerDataSource();
	filteredArticlesCache: {search?: string, articles: Article[]} = {search: '', articles: []}

	constructor(
		private translateService: TranslateService,
		private dialogService: DialogService,
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private stateService: StateService,
		private nomenclatureService:  NomenclatureService
	) {
		this.htmlEditorHeaderSizes = default_header_sizes;
	}

	ngOnInit(): void {
		this.canEdit = this.me.uti_administrateur;
		this.isCreation = this.texte == undefined;
		if (this.isCreation) {
			this.texte = {
				txt_modification_majeure: false,
				txt_brouillon: true,
				txt_abrogation: false,
				txt_actif: true,
				rubriques: [] as Rubrique[],
				articles: [] as Article[],
			} as Texte;
			this.editMode = true;
		}
		else {
			// On agrandit tous les articles courts par défaut et on arrange les art_ordre
			this.texte.articles.forEach((article: Article, index: number) => {
				if (article.art_corps && article.art_corps.length < 100) {
					this.expandedArticles[article.art_id] = true;
				}
				article.art_ordre = index;
			});

			// On agrandit forcément le premier article si il est seul
			if (this.texte.articles.length == 1) {
				this.expandedArticles[this.texte.articles[0].art_id] = true;
			}

			this.texte = structuredClone(this.texte);
			this.canHaveRubriques = !this.texte.txt_brouillon;
		}
		this.pristineTexte = structuredClone(this.texte);
	}

	toggleEditMode(status?: boolean) {
		this.editMode = (status !== undefined)? status : !!!this.editMode;
		if (this.editMode) {
			this.texte.txt_modification_majeure = false;
			this.texte.articles.forEach((article: Article) => article.art_modification_majeure = false);
		}
	}

	onDateAbrogationChange() {
		if (this.texte.txt_date_abrogation) {
			this.texte.txt_abrogation = true;
		}
	}

	onArticleSearchChange() {
		this.refreshArticleList();
	}

	showRubriqueAdditionDialog() {
		const ref = this.dialogService.open(AjoutRubriqueDialogComponent, {
			data: {
				texte: this.pristineTexte
			},
			appendTo: 'body',
			header: this.translateService.instant('textes.link_to_rubrique_dialog_header'),
			width: '70rem',
		});

		ref.onClose.subscribe((rubrique?: Rubrique) => {
			if (rubrique != undefined && !this.texte.rubriques.some((rub: Rubrique) => rub.rub_id == rubrique.rub_id)) {
				this.texte.rubriques.push(rubrique);
			}
		});
	}

	removeRubrique(rubrique: Rubrique){
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: this.translateService.instant('textes.unlink_rubrique_confirmation', {rubrique: '<b>'+rubrique.rub_nom+'</b>'}),
			accept: () => {
				this.texte.rubriques = this.texte.rubriques.filter((rub: Rubrique) => rub.rub_id != rubrique.rub_id);
			}
		});
	}

	async addArticle(index: number) {
		let article = {
			art_ordre: index,
			art_id: -Math.random(), // id temporaire utilisé seulement côté client
			art_separateur: false,
			art_modification_majeure: false
		} as Article;

		// Incrémentation de l'art_ordre des articles qui suivent le nouvel article
		let after = this.texte.articles.slice(index);
		after.forEach((article: Article) => article.art_ordre ++);

		this.texte.articles = [
			...this.texte.articles.slice(0, index), // before
			article,
			...after
		];
		this.expandedArticles[article.art_id] = true;

		if (index == this.texte.articles.length -1) {
			await this.datasource.adapter.append({
				items: [ article ],
			});

		}
		else {
			await this.datasource.adapter.insert({
				items: [ article ],
				beforeIndex: index
			});

		}

		await this.datasource.adapter.relax();
		this.scrollToNearbyArticle(article);
	}

	openArticleMoveDialog(article: Article) {
		const ref = this.dialogService.open(DeplacementArticlesDialogComponent, {
			header: this.translateService.instant('textes.articles_move_dialog_header'),
			styleClass: 'min-w-30rem max-w-40rem',
			data: {
				articles: structuredClone(this.texte.articles),
				focusedArticleId: article.art_id
			}
		});
		ref.onClose.subscribe((articles?: Article[]) => {
			if (articles) {
				this.texte.articles = articles;
				this.datasource.adapter.reload();
			}
		});
	}

	deleteArticle(article: Article) {
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: this.translateService.instant('textes.article_deletion_confirmation'),
			accept: async () => {
				let index = this.texte.articles.findIndex((art: Article) => art == article);
				// On enlève l'article
				this.texte.articles.splice(index, 1);
				// On décrémente art_ordre des articles situés après celui qui vient d'être supprimé
				this.texte.articles.slice(index).forEach((article: Article) => article.art_ordre--);

				await this.datasource.adapter.remove({ indexes: [index] });

				if (index > 0) {
					this.scrollToNearbyArticle(this.texte.articles[index], 'start');
				}
			}
		});
	}

	scrollToNearbyArticle(article: Article, mode: 'start' | 'center' | 'end' = 'center') {
		document.querySelector(`#article${article.art_ordre}`)?.scrollIntoView({behavior: 'smooth', block: mode});
	}

	submit() {
		this.submitted = true;
		this.skipExitStateCheck = true;

		// Suppression des ids temporaires des articles pas encore persistés
		let texte = structuredClone(this.texte);
		texte.articles.forEach((art: ExtensibleObject) => {
			if (art.art_id < 0) {
				delete art.art_id;
			}
		});

		if (this.isCreation) {
			this.nomenclatureService.createTexte(texte)
			.subscribe({
				next: ({ txt_id }) => {
					this.eventManager.emit('toast', { severity: 'success', summary: this.translateService.instant('textes.texte_created_success') });
					this.stateService.go('^.single', {txt_id: txt_id});
				}
			});
		}
		else {
			const saveText = (textToSave: Texte) => {
				this.nomenclatureService.updateTexte(textToSave)
				.subscribe(() => {
					this.eventManager.emit('toast', { severity: 'success', summary: this.translateService.instant('textes.texte_updated_success') });
					this.stateService.go('.', undefined, {reload: '^'});
				});
			};

			if (this.texte.txt_brouillon) {
				saveText(this.texte)
			}
			else {
				const ref = this.dialogService.open(ValidationModificationsDialogComponent, {
					header: this.translateService.instant('common.save_title'),
					styleClass: 'min-w-30rem max-w-40rem',
					data: {
						texte: structuredClone(this.texte),
					}
				});
				ref.onClose.subscribe((textToSave?: Texte) => {
					if (textToSave != undefined) {
						saveText(textToSave)
					}
				});
			}
		}

	}

	publish() {
		this.nomenclatureService.publishTexte(this.texte.txt_id)
		.subscribe({
			complete: () => {
				this.eventManager.emit('toast', { severity: 'success', summary: this.translateService.instant('textes.texte_published_success') });
				this.stateService.go('.', undefined, {reload: '^'});
			}
		})
	}

	uiCanExit(newTransition?: Transition): HookResult {
		if (!this.skipExitStateCheck && !simpleComparison(this.pristineTexte, this.texte)) {
			return new Observable<any>(sub => {
				this.confirmationService.confirm({
					defaultFocus: 'reject',
					header: this.translateService.instant('common.leaving_unsaved_form_confirm_header'),
					message: this.translateService.instant('common.leaving_unsaved_form_confirm_message'),
					accept: () => {
						sub.next(true);
						sub.complete();
					},
					reject: () => {
						sub.next(false);
						sub.complete();
					}
				});
			}).toPromise();
		}
	}

	createScrollerDataSource() {
		return new Datasource({
			settings: {
				startIndex: 0, // Par défaut startIndex est à 1
				padding: 10 // On évite la majorité des incrémentation brutales du défilement
			},
			get: (index: number, count: number, success: Function) => {
				let articles = this.texte.articles;
				let search = this.search;

				if (search && search?.trim() != '') {
					search = search.trim();
					if (this.filteredArticlesCache.search == search) {
						articles = this.filteredArticlesCache.articles;
					}
					else {
						const regex = new RegExp(normalizeStringForSearch(search)!, 'i');

						articles = articles.filter((article: Article) => {
							let normalizedTitle = normalizeStringForSearch(article.art_titre ?? '');
							let normalizedBodyPlaintext = normalizeStringForSearch(article.art_corps_plaintext ?? '');

							return normalizedTitle.match(regex) || normalizedBodyPlaintext.match(regex);
						});

						this.filteredArticlesCache = {search: search, articles: articles};
					}
				}

				let result = articles.slice(index, index + count);
				success(result);
			}
		});
	}

	refreshArticleList() {
		return this.datasource.adapter.reset();
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ToolbarModule,
		PanelModule,
		DividerModule,
		AbonneInfosTabModule,
		CalendarModule,
		HTMLEditorModule,
		StatutRecolementSelectorModule,
		VirtualScrollerModule,
		DataViewModule,
		UiScrollModule,
	],
	exports: [TexteComponent],
	declarations: [TexteComponent]
})
export class TexteModule { }


