import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';

import { ConfirmationService } from 'primeng/api';

import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { Abonne, IPRequise } from './abonne.model';

import { Cache } from '@app/_helpers/cache';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@app/_helpers/prepare-query-params';
import { clone, convertDateFieldsToDate, convertDateFieldsToString, ExtensibleObject, uid } from '@helpers/utils';
import { Utilisateur, UtilisateurAbonne } from '@app/utilisateur/utilisateur.model';
import { StorageService } from '@app/_global/storage.service';


interface Abonnes {
	total: number;
	abonnes: Abonne[]
}

@Injectable({ providedIn: 'root' })
export class AbonneService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private currentAbonneSubject = new BehaviorSubject<Abonne|null>(null);
	public currentAbonne$ = this.currentAbonneSubject.asObservable();

	cacheAbonnes = new Cache<Abonnes>();

	constructor(
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private http: HttpClient,
		private storageService: StorageService,
	) {

		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.currentAbonneValue = null;
		});

		this.eventManager.registerEvent('logo_change', this, (args: any) => {
			if (args.length) {
				this.maybeRefreshCurrentAbo(args[0]);
			}
		});

	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
		this.eventManager.unregisterEvent('logo_change', this);
	}

	public get currentAbonneValue(): Abonne  | null {
		return this.currentAbonneSubject.value;
	}

	public set currentAbonneValue(value: Abonne  | null) {
		this.storageService.set('abo',  value);
		this.currentAbonneSubject.next(value);
	}

	public isUserGestionnaireAbonnement(utilisateur: Utilisateur) {
		let abonne = this.currentAbonneValue;
		if (abonne == undefined) {
			return false;
		}

		return !!abonne.utilisateurs_abonnes.find(
			(utilisateurAbonne: Utilisateur & UtilisateurAbonne) => utilisateurAbonne.uti_id == utilisateur.uti_id && utilisateurAbonne.utb_gestion_abonnement
		);
	}

	public isUserGestionnaireConformite(utilisateur: Utilisateur) {
		let abonne = this.currentAbonneValue;
		if (abonne == undefined) {
			return false;
		}

		return !!abonne.utilisateurs_abonnes.find(
			(utilisateurAbonne: Utilisateur & UtilisateurAbonne) => utilisateurAbonne.uti_id == utilisateur.uti_id && utilisateurAbonne.nac_id == 2
		);
	}

	public maybeRefreshCurrentAbo(abo_id: number) {
		if (this.currentAbonneValue && this.currentAbonneValue.abo_id == abo_id) {
			this.refreshCurrentAbonneData().subscribe();
		}
	}

	public refreshCurrentAbonneData(abo_id?: number): Observable<Abonne|undefined> {
		let abo = this.currentAbonneValue;
		if (!abo_id) abo_id = abo?.abo_id;
		if (abo_id) {
			return this.getAbonne(abo_id)
			.pipe(map(abonne => {
				this.currentAbonneValue = abonne;
				return this.currentAbonneValue;
			}));
		}
		return of(undefined);
	}

	public prepareAbonneFromServer(abonne: Abonne) {
		let tmp: Abonne = clone(abonne);
		convertDateFieldsToDate(tmp);
		return tmp;
	}

	public prepareAbonneForServer(abonne: Abonne) {
		let tmp = structuredClone(abonne) as ExtensibleObject;
		convertDateFieldsToString(tmp);

		delete tmp.nombre_utilisateurs_actifs;
		delete tmp.nombre_sites_actifs;
		delete tmp.abonnes;
		delete tmp.familles;
		return tmp;
	}

	public prepareAbonnesFromServer(abonnes: Abonne[]) {
		for (let i = 0; i < abonnes.length ; i++) {
			abonnes[i] = this.prepareAbonneFromServer(abonnes[i]);
		}
		return abonnes;
	}

	public getCacheAbonnes() {
		if (this.cacheAbonnes.isNotEmpty()) {
			return this.cacheAbonnes.currentCopyAsObservable()
		}

		return this.getAbonnes({})
		.pipe(map((result: Abonnes) => {
			this.cacheAbonnes.update(result);
			return structuredClone(result);
		}));
	}

	public getAbonnes(params: unknown) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`/abonnes`, tmpParams).pipe(
			map(({ abonnes, total }) => {
				let liste = this.prepareAbonnesFromServer(abonnes);
				return {abonnes: liste, total: total || 0 as number};
			})
		)
	}

	public getAbonne(abo_id: number) {
		return this.http.get<any>(`/abonnes/${abo_id}`,).pipe(
			map(response => this.prepareAbonneFromServer(response))
		)
	}

	public createAbonne(abonne: Abonne){
		this.cacheAbonnes.invalidate();

		let body = this.prepareAbonneForServer(abonne);
		return this.http.post<any>(`/abonnes`, body);
	}

	public updateAbonne(abonne: Abonne){
		this.cacheAbonnes.invalidate();

		let body = this.prepareAbonneForServer(abonne);
		return this.http.put<any>(`/abonnes/${abonne.abo_id}`, body)
		.pipe(tap(()=> {
			this.maybeRefreshCurrentAbo(abonne.abo_id)
		}))
	}

	public deleteAbonne(abo_id: number){
		this.cacheAbonnes.invalidate();

		return this.http.delete<any>(`/abonnes/${abo_id}`);
	}

	public linkUserToAbonne(user_id: number, abo_id: number, utiAbonne: UtilisateurAbonne){
		this.cacheAbonnes.invalidate();

		return this.http.post<any>(`/abonnes/${abo_id}/utilisateurs/${user_id}`, {
			nac_id: utiAbonne.nac_id,
			utb_gestion_abonnement: utiAbonne.utb_gestion_abonnement
		});
	}

	public unlinkUserFromAbonne(user_id: number, abo_id: number){
		this.cacheAbonnes.invalidate();

		return this.http.delete<any>(`/abonnes/${abo_id}/utilisateurs/${user_id}`);
	}

	public editAbonneUser(user_id: number, abo_id: number, utiAbonne: UtilisateurAbonne){
		return this.http.put<any>(`/abonnes/${abo_id}/utilisateurs/${user_id}`, {
			nac_id: utiAbonne.nac_id,
			utb_gestion_abonnement: utiAbonne.utb_gestion_abonnement
		});
	}

	public getAbonneLogoUploadURL(abo_id: number) {
		return `/abonnes/${abo_id}/logo`
	}

	private prepareIpForServer(ip: IPRequise) {
		let tmp = structuredClone(ip) as ExtensibleObject;
		delete tmp.ipr_date_creation;
		delete tmp.ipr_date_modification;
		return tmp;
	}

	public updateAbonneIps(abo_id: number, ips: IPRequise[]){
		let body = {
			ip_requises: ips.map(ip => this.prepareIpForServer(ip))
		};

		return this.http.put<any>(`/abonnes/${abo_id}/ip_requises`, body);
	}

	public exportAbonnes(params: unknown){
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`/abonnes/export`, tmpParams);
	}
}
