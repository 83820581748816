<div class="w-full">
	<div class="w-full" *ngIf="statistiquesConformite.sites.length == 1">
		<bloc-statistiques class="block w-12" [statistiques]="statistiquesConformite.sites[0]" [isBlockAlone]="true"></bloc-statistiques>
	</div>

	<div *ngIf="statistiquesConformite.sites.length > 1" class="p-fluid grid">
		<!-- Global -->
		<bloc-statistiques class="block col-12" [statistiques]="statistiquesConformite.total"></bloc-statistiques>

		<!-- Sites -->
		<bloc-statistiques class="block col-12 xl:col-6" *ngFor="let stats of statistiquesConformite.sites" [statistiques]="stats"></bloc-statistiques>
	</div>
</div>
