<!-- Titre -->
<div class="page-title">
	{{ 'abonnes.abonnes_list_page_title' | translate }}
</div>

<p-toolbar styleClass="pb-0">
	<ng-template pTemplate="left">
		<div class="grid formgrid fluid w-full">
			<div class="field col-fixed">
				<!-- Recherche -->
				<div class="p-inputgroup mr-2">
					<input
						pInputText
						type="text"
						[(ngModel)]="params.search"
						(onDebounce)="refresh()"
						[attr.placeholder]="'common.search_input_placeholder' | translate"
					/>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!params.search || loading" (click)="resetSearch()"></button>
				</div>
			</div>

			<!-- Coche Actif/Inactif -->
			<div class="col-fixed field field-checkbox">
				<p-checkbox
					name="abo_actif"
					inputId="abo_actif"
					[(ngModel)]="params.abo_actif"
					[binary]="true"
					[label]="'abonnes.inactive_state_plural' | translate"
					[trueValue]="false"
					[falseValue]="true"
					(ngModelChange)="refresh()"
				></p-checkbox>
			</div>
		</div>
	</ng-template>

	<ng-template pTemplate="right">
		<div class="grid formgrid fluid w-full">
			<div class="field col-fixed">
				<button
					type="button"
					pButton
					icon="pi pi-bars"
					[label]="'common.actions_button_label' | translate"
					class="p-panel-header-icon p-link"
					(click)="menu_list_abonnes.toggle($event)"
					*ngIf="menuItems.length"
				></button>
				<p-menu #menu_list_abonnes [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
			</div>
		</div>
	</ng-template>
</p-toolbar>
<p-table
	#tableAbonnes
	[value]="values"
	[lazy]="true"
	(onLazyLoad)="loadAbonnes($event)"
	[loading]="loading"
	[paginator]="true"
	[rows]="rows"
	[showCurrentPageReport]="true"
	[totalRecords]="totalRecords"
	currentPageReportTemplate="{{totalRecords | number}} {{'common.table_total_records'|translate}}"
	[rowsPerPageOptions]="[10,25,50]"
	styleClass="p-datatable-sm p-datatable-gridlines table-scrollable-hover"
	[autoLayout]="true"
	responsiveLayout="scroll"
	scrollDirection="both"
	[scrollable]="true"
	scrollHeight="flex"
>
	<ng-template pTemplate="header">
		<tr>
			<th class="col-logo hide-sm"></th>

			<th class="text-center" pSortableColumn="abo_nom_abonne">
				{{ 'abonnes.abonne_name_label' | translate }} <p-sortIcon field="grc_libelle"></p-sortIcon>
			</th>
			<th class="text-center">{{ 'abonnes.abonnement_families_label' | translate }}</th>

			<th class="text-center nowrap">{{ 'abonnes.abonnement_expiration_label' | translate }}</th>
			<th class="text-center nowrap">{{ 'abonnes.active_users_label' | translate }}</th>
			<th class="text-center nowrap">{{ 'abonnes.active_sites_label' | translate }}</th>

			<th></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="emptymessage">
		<tr>
			<td class="text-center" colspan="100">
				{{ 'common.no_table_results' | translate }}
			</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-abonne>
		<tr [ngClass]="{'inactive': !abonne.abo_actif}">
			<td class="col-logo hide-sm">
				<logo-display [abonne]="abonne"></logo-display>
			</td>

			<td>{{abonne.abo_nom_abonne}}</td>
			<td>{{formatFamilyList(abonne)}}</td>

			<td class="text-center nowrap" [ngClass]="{'font-bold text-color-danger': now > abonne.abo_date_expiration}">
				{{abonne.abo_date_expiration | date:'dd MMMM yyyy'}}
			</td>

			<td class="text-right nowrap">
				<span
					*ngIf="abonne.abo_nombre_utilisateurs != null"
					[ngClass]="(abonne.nombre_utilisateurs_actifs > abonne.abo_nombre_utilisateurs) ? 'text-color-danger font-bold' : ''"
				>
					{{abonne.nombre_utilisateurs_actifs}} / {{abonne.abo_nombre_utilisateurs ?? 0}}
				</span>

				<span *ngIf="abonne.abo_nombre_utilisateurs == null">
					{{abonne.nombre_utilisateurs_actifs}}
				</span>
			</td>

			<td class="text-right nowrap">
				<span
					*ngIf="abonne.abo_nombre_sites != null"
					[ngClass]="(abonne.nombre_sites_actifs > abonne.abo_nombre_sites) ? 'text-color-danger font-bold' : ''"
				>
					{{abonne.nombre_sites_actifs}} / {{abonne.abo_nombre_sites ?? 0}}
				</span>

				<span *ngIf="abonne.abo_nombre_sites == null">
					{{abonne.nombre_sites_actifs}}
				</span>
			</td>

			<td class="nowrap" *ngIf="!readonly">
				<a
					pButton
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					uiSref="admin.abonnes.single"
					[uiParams]="{abo_id: abonne.abo_id}"
				></a>
				<button
					type="button"
					pButton
					icon="pi pi-trash"
					class="p-button-rounded p-button-text p-button-danger"
					(click)="deleteAbonne(abonne)"
					*ngIf="canEdit"
				></button>
			</td>
		</tr>
	</ng-template>
</p-table>

