export const conformiteMenuItems: any[] = [
	{
		labelKey: 'menu.dashboard',
		icon: 'pi pi-home',
		state: 'abonne-courant.tableau-de-bord',
	},

	{
		labelKey: 'menu.applicability_reviews',
		icon: 'pi pi-clipboard',
		state: 'abonne-courant.selection-sites-recolements',
	},

	{
		labelKey: 'menu.assessments',
		icon: 'pi pi-list-check',
		state: 'abonne-courant.selection-sites-evaluations'
	}
];
