import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CodeEvaluation, StatutRecolement } from '@app/texte/texte.model';
import { environment } from '@environments/environment';

export type EvaluationDefinition = {
	eva_code: CodeEvaluation
	eva_libelle: string
}

export type StatutDefinition = {
	sta_code: StatutRecolement
	sta_libelle: string
}

@Injectable({ providedIn: 'root' })
export class ListesGeneriquesService {

	private listesGeneriquesSubject = new BehaviorSubject<any>(null);

	constructor(private http: HttpClient) {

	}

	public get definitionsEvaluations(): EvaluationDefinition[] {
		return this.getListe('evaluations') as EvaluationDefinition[]
	}

	public getDefinitionEvaluation(eva_code: CodeEvaluation): EvaluationDefinition {
		return this.definitionsEvaluations.find(def => def.eva_code == eva_code)!;
	}

	public get definitionsStatus(): StatutDefinition[] {
		return this.getListe('statuts') as StatutDefinition[]
	}

	public getDefinitionStatut(sta_code: StatutRecolement): StatutDefinition {
		return this.definitionsStatus.find(def => def.sta_code == sta_code)!;
	}

	public getListesGeneriques(refresh: boolean = false) {
		if (this.listesGeneriques && !refresh) {
			return of(this.listesGeneriques);
		}
		return this.http.get<any>(`${environment.api_url}/listes_generiques`)
		.pipe(
			map(
				(response: any) => {
					this.listesGeneriquesSubject.next(response);
					return response;
				}
			)
		);
	}

	private get listesGeneriques(): any {
		return this.listesGeneriquesSubject.getValue();
	}

	private getListe(name: string): any[] {
		if (typeof this.listesGeneriques[name] == 'undefined') {
			console.log(`La liste générique n'existe pas: ${name}`);
			return [];
		}
		return this.listesGeneriques[name];
	}
}
