import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Observable } from 'rxjs';

import { TranslateService } from '@codeandweb/ngx-translate';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { CalendarModule } from '@app/primeng-overrides/calendar';
import { MenuModule } from '@app/primeng-overrides/menu';
import { Table, TableModule } from '@app/primeng-overrides/table';
import { AccordionModule } from 'primeng/accordion';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService } from 'primeng/dynamicdialog';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';

import { EventManagerService } from '@app/_global/event-manager.service';
import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { FamilleSelectorModule } from '@app/famille/famille-selector';
import { NomenclatureSelectorModule } from '@app/nomenclature/nomenclature-selector';
import { Nomenclature } from '@app/nomenclature/nomenclature.model';
import { NomenclatureService } from '@app/nomenclature/nomenclature.service';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { RubriqueSelectorModule } from '@app/rubrique/rubrique-selector';
import { Site } from '@app/site/site.model';
import { SiteService } from '@app/site/site.service';
import { ConformiteTexteDialogComponent } from '@app/texte/conformite-texte-dialog';
import { EvaluationSelectorModule } from '@app/texte/evaluation-selector';
import { StatutRecolementSelectorModule } from '@app/texte/statut-recolement-selector';
import { CodeEvaluation, StatutRecolement, Texte, TexteConformite, TexteEvaluation, TexteRecolement } from '@app/texte/texte.model';
import { ThemeSelectorModule } from '@app/theme/theme-selector';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { GlobalModule } from '@global/global.module';
import { StorageService } from '@global/storage.service';

import { environment } from '@environments/environment';
import { createDownloadFromHttpResponse, simpleDateToString, startOfDay } from '@helpers/utils';

@Component({
	selector: 'liste-textes-conformite',
	templateUrl: `liste-textes-conformite.html`
})
export class ListeTextesConformiteComponent implements OnInit {

	@ViewChild('tableTextes', { static: true }) table: Table;

	@Input() me: Utilisateur;
	@Input() sites: Site[];
	@Input() etapeConformite: ('recolements' | 'evaluations') = 'recolements';

	@Input('selected_sta_code') initialStaCode?: StatutRecolement;
	@Input('selected_eva_code') initialEvaCode?: CodeEvaluation;

	abonne_courant: Abonne;

	siteNames: string;
	siteIds: number[];
	mergedNomenclatureDiagnostics: Nomenclature;

	canEdit = false;

	values: TexteConformite[] = [];
	pristineValues: TexteConformite[] = [];
	totalRecords: number = 0;
	loading: boolean;
	rows: number = environment.default_rows;
	expandedRows: any = {};

	remainingRecolementsArticleBySiteIdByTextId: Record</* sit_id*/ number, Record<number, number>> = {};

	// Paramètres et filtres

	_defaultParams: { [key: string]: any } = {
		search: null,
		search_reference: null,
		fam_id: null,
		thm_id: null,
		rub_id: null,
		order_by: null,
		sta_code: null,
		eva_code: null,
		eva_code_article: null,
		hors_diagnostic: null,
		prive: null,
		abroge: null,
		date_debut_reevaluation: null,
		date_fin_reevaluation: null,
		date_debut_sortie: null,
		date_fin_sortie: null,
		a_reevaluer: null
	};
	params = structuredClone(this._defaultParams);
	showAdvancedFilters: boolean = false;
	orderByOptions: {label: string, value: string | null, disabled?: boolean}[];
	nomenclatureFilter: any;
	today: Date = startOfDay(new Date());
	dateReevaluationRange: Date[] | null = null;
	dateSortieRange: Date[] | null = null;

	// Exports

	menuItems: MenuItem[];

	constructor(
		private storageService: StorageService,
		private stateService: StateService,
		private translate: TranslateService,
		private eventManager: EventManagerService,
		private siteService: SiteService,
		private utilisateurService: UtilisateurService,
		private nomenclatureService: NomenclatureService,
		private abonneService: AbonneService,
		private dialogService: DialogService
	) {
		this.abonne_courant = this.abonneService.currentAbonneValue!;
		this.orderByOptions = [
			{
				value: null,
				label: this.translate.instant('common.alphabetical_order'),
			},
			{
				value: 'pertinence',
				label: this.translate.instant('textes.sort_option_relevance'),
				disabled: true // Désactivé par défaut
			},
			{
				value: 'txt_date',
				label: this.translate.instant('textes.date_de_saisie')
			},
			{
				value: 'txt_date_signature',
				label: this.translate.instant('textes.date_signature')
			},
			{
				value: 'txt_date_application',
				label: this.translate.instant('textes.date_application')
			},
			{
				value: 'txt_date_modificative',
				label: this.translate.instant('textes.date_modificative')
			},
			{
				value: 'txt_date_abrogation',
				label: this.translate.instant('textes.date_abrogation')
			}
		];

		this.menuItems = [
			{
				label: this.translate.instant('common.export_action_label'),
				icon: 'pi pi-download',
				command: () => {
					this.exportTextes(false);
				}
			},
			{
				label: this.translate.instant('textes.export_with_articles'),
				icon: 'pi pi-download',
				command: () => {
					this.exportTextes(true);
				}
			},
		];
	}

	ngOnInit(): void {
		this.canEdit =
			this.me.uti_administrateur
			|| this.abonneService.isUserGestionnaireAbonnement(this.me)
			|| this.abonneService.isUserGestionnaireConformite(this.me);

		this.siteNames = this.sites.map((site: Site) => site.sit_libelle).join(', ');
		this.siteIds = this.sites.map((site: Site) => site.sit_id);
		this.siteService.getMergedNomenclatureDiagnostics(this.abonne_courant.abo_id,  this.sites.map(sit => sit.sit_id))
		.subscribe((nomenclature: Nomenclature) => {
			this.mergedNomenclatureDiagnostics = nomenclature;
		})

		this.getParamSet();
		this.showAdvancedFilters = this.areAdvancedFiltersVisible();

		if (this.initialStaCode != undefined) {
			this.params.sta_code = this.initialStaCode;
		}
		if (this.initialEvaCode != undefined) {
			this.params.eva_code = this.initialEvaCode;
			if (this.initialStaCode == undefined) {
				this.params.sta_code = null;
			}
		}
	}

	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('tableParams', undefined, true);

		if (tableParams) {
			for (let prop in this.params) {
				if (typeof tableParams[prop] != 'undefined') this.params[prop] = tableParams[prop];
			}
			if (tableParams.first) this.table.first = tableParams.first;
			if (tableParams.rows) this.rows = tableParams.rows;

			if (tableParams.date_debut_reevaluation && tableParams.date_fin_reevaluation) {
				this.dateReevaluationRange = [
					new Date(tableParams.date_debut_reevaluation),
					new Date(tableParams.date_fin_reevaluation)
				]
			}
			if (tableParams.nomenclatureFilter) {
				this.nomenclatureFilter = tableParams.nomenclatureFilter;
			}
		}
		this.table.rows = this.rows;
	}

	saveParamSet() {
		if (this.table) {
			let tableParams = Object.assign({}, this.params, {
				first: this.table.first,
				rows: this.table.rows,
				nomenclatureFilter: this.nomenclatureFilter,
				date_debut_sortie: undefined,
				date_fin_sortie: undefined
			});
			this.storageService.setForCurrentState('tableParams', tableParams, true);

			const uiParams: any = Object.assign({}, this.stateService.params, this.params, {
				sit_actif: undefined,
			});
			this.stateService.go('.', uiParams, { reload: false, location: 'replace', inherit: false });
		}
	}

	areAdvancedFiltersVisible() {
		if (this.etapeConformite == 'recolements') {
			return this.params.eva_code
				|| this.params.eva_code_article
				|| this.params.date_debut_reevaluation
				|| this.params.date_fin_reevaluation
				|| this.params.date_debut_sortie
				|| this.params.date_fin_sortie
				|| this.params.hors_diagnostic
				|| this.params.prive
				|| this.params.abroge
			;
		}
		else {
			return this.params.sta_code
			 	|| this.params.date_debut_reevaluation
				|| this.params.date_fin_reevaluation
				|| this.params.date_debut_sortie
				|| this.params.date_fin_sortie
				|| this.params.hors_diagnostic
				|| this.params.prive
				|| this.params.abroge
			;
		}
	}

	toggleAdvancedFilters() {
		this.showAdvancedFilters = !this.showAdvancedFilters;
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	loadTextes(event: LazyLoadEvent) {
		// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => {
			this.loading = true;
		});

		const tmpParams = Object.assign({}, event, this.params);

		this.saveParamSet();

		if (String(tmpParams.order_by).startsWith('txt_date')) {
			tmpParams.order = 'DESC';
		} else if (tmpParams.order_by == 'pertinence') {
			delete tmpParams.order_by;
		}

		if (this.etapeConformite == 'recolements') {

			this.siteService.getRecolementsTextes(this.abonne_courant.abo_id, this.siteIds, tmpParams)
			.subscribe({
				next: ({ total, textes }) => {
					this.totalRecords = total;
					this.values = textes;

					let pristineValues = structuredClone(textes);
					this.pristineValues = pristineValues;


					this.remainingRecolementsArticleBySiteIdByTextId = {};

					for (let pristineTexte of pristineValues) {
						for (let recolement of pristineTexte.recolements) {
							recolement.rec_commentaire ??= null;
						}
						for (let article of pristineTexte.articles) {
							for (let recolement of article.recolements) {
								this.remainingRecolementsArticleBySiteIdByTextId[recolement.sit_id] ??= {};

								if (recolement.sta_code != 'inc') {
									continue;
								}

								let remainingRecolementCount = this.remainingRecolementsArticleBySiteIdByTextId[recolement.sit_id];
								remainingRecolementCount[pristineTexte.txt_id] ??= 0;
								remainingRecolementCount[pristineTexte.txt_id] += 1;
							}
						}
					}

					// Si on revient sur la liste paginée et que la page en question est vide, on reset
					if (this.values.length === 0 && this.table.first != 0) {
						this.refresh();
					}

					this.expandedRows = Object.fromEntries(
						this.values.map((texte: TexteRecolement) => [
							//identifiant de ligne
							texte.txt_id,
							//expansion
							true
						])
					);
				}
			})
			.add(() => { this.loading = false; });
		}
		else {
			this.siteService.getEvaluationsTextes(this.abonne_courant.abo_id, this.siteIds, tmpParams)
			.subscribe({
				next: ({ total, textes }) => {
					this.totalRecords = total;
					this.values = textes;

					let pristineValues = structuredClone(textes);
					this.pristineValues = pristineValues;

					for (let pristineTexte of pristineValues) {
						for (let recolement of pristineTexte.evaluations) {
							recolement.rec_commentaire ??= null;
						}
					}

					// Si on revient sur la liste paginée et que la page en question est vide, on reset
					if (this.values.length === 0 && this.table.first != 0) {
						this.refresh();
					}

					this.expandedRows = Object.fromEntries(
						this.values.map((texte: TexteConformite) => [
							//identifiant de ligne
							texte.txt_id,
							//expansion
							true
						])
					);
				}
			})
			.add(() => { this.loading = false; });
		}
	}

	exportTextes(articles = false){
		const tmpParams = Object.assign({}, event, this.params);
		tmpParams.avec_articles = articles;

		let observable: Observable<any>;
		if (this.etapeConformite == 'recolements') {
			observable = this.siteService.exportRecolementsTextes(this.abonne_courant.abo_id, this.siteIds, tmpParams)
		}
		else {
			observable = this.siteService.exportEvaluationsTextes(this.abonne_courant.abo_id, this.siteIds, tmpParams)
		}

		return observable.subscribe(response => {
			createDownloadFromHttpResponse(response);
		});
	}

	nomenclatureSelectionChange(event: any) {
		this.params.fam_id = null;
		this.params.thm_id = null;
		this.params.rub_id = null;
		if (event) {
			this.params.fam_id = event.fam_id;
			this.params.thm_id = event.thm_id;
			this.params.rub_id = event.rub_id;
		}
		this.refresh();
	}

	searchChange() {
		if (this.params.search) {
			// Forcage du tri par pertinence
			for (let orderByOption of this.orderByOptions) {
				orderByOption.disabled = orderByOption.value != 'pertinence';
			}
			this.params.order_by = 'pertinence';
		}
		else {
			// Désativation du tri par pertinence
			for (let orderByOption of this.orderByOptions) {
				orderByOption.disabled = orderByOption.value == 'pertinence';
			}
			this.params.order_by = null;
		}
		this.refresh();
	}


	setReevaluationDates(dateRange?: any) {
		this.params.date_debut_reevaluation = null;
		this.params.date_fin_reevaluation = null;
		if (dateRange && dateRange[1]) {
			this.params.date_debut_reevaluation = simpleDateToString(dateRange[0], true);
			this.params.date_fin_reevaluation = simpleDateToString(dateRange[1], true);
		}
		else {
			this.dateReevaluationRange = null;
		}
		this.refresh();
	}

	setReleaseDates(dateRange?: any) {
		this.params.date_debut_sortie = null;
		this.params.date_fin_sortie = null;
		if (dateRange && dateRange[1]) {
			this.params.date_debut_sortie = simpleDateToString(dateRange[0], true);
			this.params.date_fin_sortie = simpleDateToString(dateRange[1], true);
		}
		else {
			this.dateSortieRange = null;
		}
		this.refresh();
	}


	resetFilters() {
		this.params = structuredClone(this._defaultParams);
		this.dateReevaluationRange = null;
		this.dateSortieRange = null;
		this.refresh();
	}

	openTexteConformiteDialog(texteConformite: TexteConformite) {

		this.nomenclatureService.getTexte(texteConformite.txt_id)
		.subscribe((texte: Texte) => {

			const ref = this.dialogService.open(ConformiteTexteDialogComponent, {
				header: texte.txt_reference,
				styleClass: 'max-w-120rem p-dialog-high-height',
				closable: !this.canEdit,
				data: {
					me: this.me,
					sit_ids: this.siteIds,
					texte: texte,
					texteConformite: texteConformite,
					etapeConformite: this.etapeConformite,
					canEdit: this.canEdit
				}
			});
			ref.onClose.subscribe(({refresh} = {}) => {
				if (refresh) {
					this.refresh();
				}
			});
		});
	}

	areSomeRecolementsInformative(texteEvaluation: TexteEvaluation) {
		return texteEvaluation.evaluations.some(evaluation => evaluation.sta_code == 'app')
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		AccordionModule,
		ButtonModule,
		CalendarModule,
		CheckboxModule,
		IconFieldModule,
		InputIconModule,
		MenuModule,
		PanelModule,
		TableModule,
		ToolbarModule,
		FamilleSelectorModule,
		ThemeSelectorModule,
		RubriqueSelectorModule,
		NomenclatureSelectorModule,
		DropdownModule,
		StatutRecolementSelectorModule,
		EvaluationSelectorModule,
		TooltipModule,
		InputTextareaModule
	],
	exports: [ListeTextesConformiteComponent],
	declarations: [ListeTextesConformiteComponent]
})
export class ListeTextesConformiteModule { }
