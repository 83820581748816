import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Observable } from 'rxjs';

import { HookResult, StateService, Transition, UiOnExit } from '@uirouter/angular';

import { TranslateModule, TranslateService } from '@codeandweb/ngx-translate';

import { MenuModule } from '@app/primeng-overrides/menu';
import { TriStateCheckboxModule } from '@app/primeng-overrides/tristatecheckbox';
import { GlobalModule } from '@global/global.module';
import { ConfirmationService, MenuItem, TreeNode } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';


import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { FamilleDialogComponent } from '@app/famille/famille-dialog';
import { Famille, Nomenclature, Rubrique, Theme } from '@app/nomenclature/nomenclature.model';
import {
	DiagnosticNodeData,
	expandTreeBasedOnFilters,
	getFilteredNomenclature,
	getNodeName,
	makeDiagnosticTreeNodes,
	makeNonIncludedNomenclatureTreeNodes,
	NomenclatureFilters,
	NomenclatureNodeData,
	NomenclatureService,
	NonIncludedNomenclatureNodeData,
	walkTree
} from '@app/nomenclature/nomenclature.service';
import { RubriqueDialogComponent } from '@app/rubrique/rubrique-dialog';
import { Site } from '@app/site/site.model';
import { SiteService } from '@app/site/site.service';
import { ThemeDialogComponent } from '@app/theme/theme-dialog';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { EventManagerService } from '@global/event-manager.service';
import { Diagnostic, RubriqueDiagnostic } from './diagnostic.model';
import { DiagnosticDuplicationDialogComponent } from './duplication-dialog';

import {
	createDownloadFromHttpResponse,
	expandTreeData,
	ExtensibleObject,
	simpleComparison
} from '@app/_helpers/utils';

const PENDING_ADD_MARKER = 'to_be_added'; // Marqueur dans les données d'un noeud

@Component({
	selector: 'diagnostic',
	templateUrl: `./diagnostic.html`
})
export class DiagnosticComponent implements OnInit, UiOnExit {

	PENDING_ADD_MARKER = PENDING_ADD_MARKER;

	@Input() me: Utilisateur;
	@Input() site: Site;

	currentAbonne: Abonne;

	unfilteredAvailableNomenclature: Nomenclature = {familles: [], rubriques: [], themes: []};
	nonIncludedHierarchy: TreeNode<NonIncludedNomenclatureNodeData>[] = [];

	pristineDiagnostic: Diagnostic;
	diagnostic: Diagnostic;
	diagnosticHierarchy:  TreeNode<DiagnosticNodeData>[] = [];

	hasChanges = false;

	canEdit: boolean = true;
	loadingAvailableNomenclature: boolean = false;
	loadingDiagnostic: boolean = false;

	_defaultFilter: any = {
		fam: null,
		thm: null,
		rub: null,
	}

	filters: NomenclatureFilters = structuredClone(this._defaultFilter);

	diagnosticFilters: NomenclatureFilters = structuredClone(this._defaultFilter);

	skipExitStateCheck = false;

	expandedNonIncludedNomenclature: boolean = false;
	expandedDiagnostic: boolean = false;

	// Exports

	menuItems: MenuItem[];

	constructor(
		private confirmationService: ConfirmationService,
		private stateService: StateService,
		private dialogService: DialogService,
		private eventManager: EventManagerService,
		private translate: TranslateService,
		private abonneService: AbonneService,
		private nomenclatureService: NomenclatureService,
		private siteService: SiteService,
	) {
		this.currentAbonne = this.abonneService.currentAbonneValue!;
	}

	ngOnInit() {
		this.canEdit = this.me.uti_administrateur || this.abonneService.isUserGestionnaireAbonnement(this.me);

		// Chargement de la nomenclature disponible et du diagnostic
		this.loadingAvailableNomenclature = true;
		this.nomenclatureService.getCacheAvailableNomenclature(this.currentAbonne.abo_id)
		.subscribe({
			next: (response: Nomenclature) => {
				this.unfilteredAvailableNomenclature = response;
				// L'arbre de la nomenclature disponible non incluse dans le diagnostic est construit une fois qu'on a chargé le diagnostic,
				// loadingAvailableNomenclature est mis à faux à ce moment là.
				this.loadDiagnostic();
			}
		});

		this.menuItems = [
			{
				label: this.translate.instant('diagnostics.export_rubriques'),
				icon: 'pi pi-download',
				command: () => {
					this.exportRubriques();
				}
			},
			{
				label: this.translate.instant('textes.export_textes'),
				icon: 'pi pi-download',
				command: () => {
					this.exportTextes(false);
				}
			},
			{
				label: this.translate.instant('textes.export_textes_with_articles'),
				icon: 'pi pi-download',
				command: () => {
					this.exportTextes(true);
				}
			},
		];
	}

	loadDiagnostic() {
		this.loadingDiagnostic = true;

		this.siteService.getDiagnostic(this.currentAbonne.abo_id, this.site.sit_id)
		.subscribe((diagnostic: Diagnostic) => {
			this.pristineDiagnostic = diagnostic;
			this.diagnostic = structuredClone(diagnostic);
			this.updateDiagnosticTree(getFilteredNomenclature(this.diagnostic, this.diagnosticFilters));

			setTimeout(() => {
				this.updateNonIncluded();
			}, 0);
		})
		.add(() => { this.loadingDiagnostic = false; });
	}

	updateNonIncluded() {
		this.loadingAvailableNomenclature = false;

		let availableNomenclature = getFilteredNomenclature(this.unfilteredAvailableNomenclature, this.filters);
		let expandedNodeKeys = new Set<string>();
		if (this.nonIncludedHierarchy != undefined) {
			walkTree(this.nonIncludedHierarchy, (node: TreeNode) => {
				if (node.key && node.expanded) {
					expandedNodeKeys.add(node.key);
				}
			});
		}
		this.nonIncludedHierarchy = makeNonIncludedNomenclatureTreeNodes(availableNomenclature, this.diagnostic, expandedNodeKeys);
	}

	updateDiagnosticTree(diagnostic: Diagnostic) {
		let expandedNodeKeys = new Set<string>();
		if (this.diagnosticHierarchy != undefined) {
			walkTree(this.diagnosticHierarchy, (node: TreeNode) => {
				if (node.key && node.expanded) {
					expandedNodeKeys.add(node.key);
				}
			});
		}
		this.diagnosticHierarchy = makeDiagnosticTreeNodes(diagnostic, false, expandedNodeKeys);

		walkTree(this.diagnosticHierarchy, (node: TreeNode<DiagnosticNodeData>) => {
			if (PENDING_ADD_MARKER in (node.data as any)) {
				node.label = '[+] ' + node.label;
			}
			if (node.data?.typeNomenclature == 'rubrique' && !node.data.rdi_actif) {
				node.styleClass ??= '' ;
				node.styleClass += ' inactive-tree-node';
			}
		});
	}

	resetFilter() {
		this.filters = structuredClone(this._defaultFilter);
		this.filterChange();
	}

	resetDiagnosticFilter() {
		this.diagnosticFilters = structuredClone(this._defaultFilter);
		this.diagnosticFilterChange();
	}

	filterChange() {
		this.updateNonIncluded();
		expandTreeBasedOnFilters(this.nonIncludedHierarchy, this.filters);
	}

	diagnosticFilterChange() {
		this.updateDiagnosticTree(getFilteredNomenclature(this.diagnostic, this.diagnosticFilters));
		expandTreeBasedOnFilters(this.diagnosticHierarchy, this.diagnosticFilters);
	}

	// Ajout ou réactivation
	addRubrique(node: TreeNode<NonIncludedNomenclatureNodeData>, disabledInDiagnostic = false) {
		if (node.data == undefined || node.data.typeNomenclature != 'rubrique') {
			return;
		}
		this.hasChanges = true;
		let rubrique = node.data;

		if (disabledInDiagnostic || rubrique.disabledInDiagnostic) {
			// Réactivation
			let actualRubriqueDiag = this.diagnostic.rubriques.find((rub: RubriqueDiagnostic) => rub.rub_id == rubrique.rub_id)!;
			actualRubriqueDiag.rdi_actif = true;
		}
		else {
			// Ajout
			let _data: ExtensibleObject = rubrique;
			_data[PENDING_ADD_MARKER] = true;

			this.diagnostic.rubriques.push({
				...rubrique,
				rdi_actif: true
			});
			this.hasChanges = true;

			// On marque la rubrique originelle comme étant ajoutée
			let rubriqueFromAvailableNomenclature = this.unfilteredAvailableNomenclature.rubriques.find((rub: Rubrique) => rub.rub_id == rubrique.rub_id);
			if (rubriqueFromAvailableNomenclature != undefined) {
				let _data: ExtensibleObject = rubriqueFromAvailableNomenclature;
				_data[PENDING_ADD_MARKER] = true;
			}
		}

		// Mise à jour des thèmes et familles
		this.diagnostic.themes = this.unfilteredAvailableNomenclature.themes.filter(
			(theme: Theme) => this.diagnostic.rubriques.some(rubrique => rubrique.thm_id == theme.thm_id)
		);

		this.diagnostic.familles = this.unfilteredAvailableNomenclature.familles.filter(
			(famille: Famille) => this.diagnostic.rubriques.some(rubrique => rubrique.fam_id == famille.fam_id)
		);

		this.resetDiagnosticFilter();
		this.updateDiagnosticTree(this.diagnostic);
		this.updateNonIncluded();

		// Expansion du 'chemin' contenant la rubrique
		let famNode = this.diagnosticHierarchy.find(node => node.data?.typeNomenclature == 'famille' && node.data.fam_id == rubrique.fam_id)!;
		let thmNode = famNode.children!.find(node => node.data?.typeNomenclature == 'theme' && node.data.thm_id == rubrique.thm_id)!;

		famNode.expanded = true;
		thmNode.expanded = true;
		this.diagnosticHierarchy = [...this.diagnosticHierarchy];
	}

	toggleExpandNonIncluded(expand: boolean = !this.expandedNonIncludedNomenclature) {
		this.nonIncludedHierarchy = expandTreeData(this.nonIncludedHierarchy, expand);
		this.expandedNonIncludedNomenclature = expand;
	}

	toggleExpandDiagnostic(expand: boolean = !this.expandedDiagnostic) {
		this.diagnosticHierarchy = expandTreeData(this.diagnosticHierarchy, expand);
		this.expandedDiagnostic = expand;
	}

	showItemDialog(item: NomenclatureNodeData) {
		let dialogTitle: string;
		let dialogComponent: any;
		let data: ExtensibleObject = {};

		switch (item.typeNomenclature) {
		case 'famille':
			dialogTitle = item.fam_nom;
			dialogComponent = FamilleDialogComponent;
			data = {
				famille: item,
			};
			break;
		case 'theme':
			dialogTitle = item.thm_nom;
			dialogComponent = ThemeDialogComponent;
			data = {
				famille: item,
				theme: item,
			};
			break;
		default: // rubrique
			dialogTitle = item.rub_nom;
			dialogComponent = RubriqueDialogComponent;
			data = {
				famille: item,
				theme: item,
				rubrique: item,
			};
		}

		this.dialogService.open(dialogComponent, {
			data: data,
			header: dialogTitle,
			width: '70rem'
		});
	}

	showDisableConfirmationDialog(item: NomenclatureNodeData) {
		if (item.typeNomenclature == 'famille') {
			return;
		}

		let message = item.typeNomenclature == 'theme' ?
			this.translate.instant('diagnostics.disable_theme_rubriques_confirmation_message', {name: getNodeName(item)})
			: this.translate.instant('diagnostics.disable_rubrique_confirmation_message', {name: getNodeName(item)});

		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: message,
			accept: () => {
				let disabledCount = 0;
				if (item.typeNomenclature == 'theme') {
					let rubriques = this.diagnostic.rubriques.filter((rub: RubriqueDiagnostic) => rub.thm_id == item.thm_id && rub.rdi_actif);
					rubriques.forEach((rub: RubriqueDiagnostic) => rub.rdi_actif = false);
					disabledCount = rubriques.length;
				}
				else {
					let actualRubriqueDiag = this.diagnostic.rubriques.find((rub: RubriqueDiagnostic) => rub.rub_id == item.rub_id)!;
					actualRubriqueDiag.rdi_actif = false;
					disabledCount = 1;
				}
				this.hasChanges = disabledCount > 0;
				this.updateDiagnosticTree(getFilteredNomenclature(this.diagnostic, this.diagnosticFilters));
				this.updateNonIncluded();
			}
		});
	}

	remove(item: NomenclatureNodeData) {
		if (item.typeNomenclature != 'rubrique') {
			return;
		}

		// On enlève la rubrique du diagnostic
		let index = this.diagnostic.rubriques.findIndex((rub: Rubrique) => rub.rub_id == item.rub_id)!;
		let actualRubrique: ExtensibleObject = this.diagnostic.rubriques.splice(index, 1)[0];
		delete actualRubrique[PENDING_ADD_MARKER];

		// On marque la rubrique originelle comme n'étant pas ajoutée
		let rubriqueFromAvailableNomenclature = this.unfilteredAvailableNomenclature.rubriques.find((rub: Rubrique) => rub.rub_id == item.rub_id);
		if (rubriqueFromAvailableNomenclature != undefined) {
			let _data: ExtensibleObject = rubriqueFromAvailableNomenclature;
			delete _data[PENDING_ADD_MARKER];
		}

		this.updateDiagnosticTree(getFilteredNomenclature(this.diagnostic, this.diagnosticFilters));
		this.updateNonIncluded();
	}

	submit() {
		let rubriqueIds = this.diagnostic.rubriques.filter((rub: RubriqueDiagnostic) => rub.rdi_actif).map(rub => rub.rub_id);

		this.siteService.updateDiagnostic(this.currentAbonne.abo_id, this.site.sit_id, rubriqueIds)
		.subscribe({
			complete: () => {
				this.hasChanges = false;
				this.eventManager.emit('toast', { severity: 'success', summary: this.translate.instant('diagnostics.update_success') });
				this.loadDiagnostic();
			}
		})
	}

	onDuplicationDialog() {
		this.dialogService.open(DiagnosticDuplicationDialogComponent, {
			header: this.translate.instant('diagnostics.duplication_modal_title'),
			width: '30rem',
			data: {
				sit_id_source: this.site.sit_id
			}
		});
	}

	exportRubriques() {
		this.siteService.exportDiagnostic(this.currentAbonne.abo_id, this.site.sit_id)
		.subscribe(response => {
			createDownloadFromHttpResponse(response);
		});
	}

	exportTextes(articles = false){
		const params = {avec_articles: articles};

		this.siteService.exportTextesDiagnostic(this.currentAbonne.abo_id, this.site.sit_id, params)
		.subscribe(response => {
			createDownloadFromHttpResponse(response);
		});
	}

	uiCanExit(newTransition?: Transition): HookResult {
		if (!this.skipExitStateCheck && !simpleComparison(this.pristineDiagnostic, this.diagnostic)) {
			return new Observable<any>(sub => {
				this.confirmationService.confirm({
					defaultFocus: 'reject',
					header: this.translate.instant('common.leaving_unsaved_form_confirm_header'),
					message: this.translate.instant('common.leaving_unsaved_form_confirm_message'),
					accept: () => {
						sub.next(true);
						sub.complete();
					},
					reject: () => {
						sub.next(false);
						sub.complete();
					}
				});
			}).toPromise();
		}
	}

}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		GlobalModule,
		InputTextModule,
		MenuModule,
		ToolbarModule,
		TreeModule,
		TriStateCheckboxModule,
		TranslateModule,
		TooltipModule
	],
	exports: [DiagnosticComponent],
	declarations: [DiagnosticComponent]
})
export class DiagnosticModule { }


