import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';

import { map, Observable } from 'rxjs';

import { ConfirmationService } from 'primeng/api';

import { Diagnostic } from '@app/diagnostic/diagnostic.model';
import { Nomenclature } from '@app/nomenclature/nomenclature.model';
import { UtilisateurSite } from '@app/utilisateur/utilisateur.model';
import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { Site } from './site.model';

import { Cache } from '@app/_helpers/cache';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@app/_helpers/prepare-query-params';
import { CodeEvaluation, StatutRecolement, TexteEvaluation, TexteRecolement } from '@app/texte/texte.model';
import { clone, convertDateFieldsToDate, convertDateFieldsToString, ExtensibleObject, startOfDay, uid } from '@helpers/utils';


interface Sites {
	total: number;
	sites: Site[]
}

export interface StatistiquesConformite {
	total: StatistiquesConformiteGlobales,
	sites: StatistiquesConformiteSite[]
}

export interface StatistiquesConformiteGlobales {
	taux_recolement: number;
	taux_conformite: number;

	nombre_textes: number;
	nombre_textes_statues: number;
    textes_par_statut: Record<StatutRecolement, {nombre: number, proportion: number}>;
    textes_par_etat_evaluation: Record<CodeEvaluation, {nombre: number, proportion: number}>;

	nombre_articles: number;
    articles_par_statut: Record<StatutRecolement, {nombre: number, proportion: number}>;
    articles_par_etat_evaluation: Record<CodeEvaluation, {nombre: number, proportion: number}>;
}

export interface StatistiquesConformiteSite extends StatistiquesConformiteGlobales {
	sit_id: number;
	sit_libelle: string;
}

@Injectable({ providedIn: 'root' })
export class SiteService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private sitesCacheByAboId = new Map<number, Cache<Sites>>();

	constructor(
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private http: HttpClient,
		private stateService: StateService,
	) {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.sitesCacheByAboId.clear();
		});
	}

	ngOnDestroy(): void {
	}

	public prepareSiteFromServer(site: Site) {
		let tmp: Site = clone(site);
		convertDateFieldsToDate(tmp);
		return tmp;
	}

	public prepareSiteForServer(site: Site) {
		let tmp = structuredClone(site) as ExtensibleObject;
		convertDateFieldsToString(tmp);
		return tmp;
	}

	public prepareSitesFromServer(sites: Site[]) {
		for (let i = 0; i < sites.length ; i++) {
			sites[i] = this.prepareSiteFromServer(sites[i]);
		}
		return sites;
	}

	public getCacheSites(abo_id: number) {
		let cache = this.sitesCacheByAboId.get(abo_id)
		if (cache == undefined) {
			cache = new Cache()
			this.sitesCacheByAboId.set(abo_id, cache);
		}
		if (cache.isNotEmpty()) {
			return cache.currentAsObservable();
		}
		return this.getSites(abo_id)
		.pipe(map((sites: Sites) => {
			cache.update(sites);
			return sites;
		}));
	}

	public getSites(abo_id: number, params: unknown = {}) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`/abonnes/${abo_id}/sites`, tmpParams).pipe(
			map(({ sites, total }) => {
				let liste = this.prepareSitesFromServer(sites);
				return {sites: liste, total: total || 0 as number};
			})
		)
	}

	public getCacheSite(abo_id: number, sit_id: number) {
		return this.getCacheSites(abo_id)
		.pipe(map((sites) => {
			let site = sites.sites.find(site => site.sit_id == sit_id);
			if (site != undefined) {
				return site;
			}
			return this.getSite(abo_id, sit_id);
		}));
	}

	public getSite(abo_id: number, sit_id: number) {
		return this.http.get<any>(`/abonnes/${abo_id}/sites/${sit_id}`).pipe(
			map(response => this.prepareSiteFromServer(response))
		)
	}

	public createSite(abo_id: number, site: Site){
		this.sitesCacheByAboId.get(abo_id)?.invalidate();
		let body = this.prepareSiteForServer(site);

		return this.http.post<any>(`/abonnes/${abo_id}/sites`, body);
	}

	public updateSite(abo_id: number, site: Site){
		this.sitesCacheByAboId.get(abo_id)?.invalidate();
		let body = this.prepareSiteForServer(site);

		return this.http.put<any>(`/abonnes/${abo_id}/sites/${site.sit_id}`, body);
	}

	public deleteSite(abo_id: number, sit_id: number){
		this.sitesCacheByAboId.get(abo_id)?.invalidate();

		return this.http.delete<any>(`/abonnes/${abo_id}/sites/${sit_id}`);
	}

	// Utilisateur

	public linkUserToSite(user_id: number, abo_id: number, sit_id: number, nac_id: number){
		this.sitesCacheByAboId.get(abo_id)?.invalidate();

		return this.http.post<any>(`/abonnes/${abo_id}/sites/${sit_id}/utilisateurs/${user_id}`, {
			nac_id: nac_id,
		});
	}

	public unlinkUserFromSite(user_id: number, abo_id: number, sit_id: number){
		this.sitesCacheByAboId.get(abo_id)?.invalidate();

		return this.http.delete<any>(`/abonnes/${abo_id}/sites/${sit_id}/utilisateurs/${user_id}`);
	}

	public editUtilisateurSite(user_id: number, abo_id: number, sit_id: number, utiSite: UtilisateurSite){
		this.sitesCacheByAboId.get(abo_id)?.invalidate();

		return this.http.put<any>(`/abonnes/${abo_id}/sites/${sit_id}/utilisateurs/${user_id}`, {
			nac_id: utiSite.nac_id,
		});
	}

	// Diagnostic

	public getDiagnostic(abo_id: number, sit_id: number) {
		return this.http.get<Diagnostic>(`/abonnes/${abo_id}/sites/${sit_id}/diagnostic`);
	}

	public updateDiagnostic(abo_id: number, sit_id: number, rub_ids: number[]) {
		return this.http.put(`/abonnes/${abo_id}/sites/${sit_id}/diagnostic`, rub_ids);
	}

	public duplicateDiagnostic(sit_id_source: number, abo_id_destination: number, sit_id_destination: number) {
		return this.http.put<{partial: boolean}>(`/abonnes/${abo_id_destination}/sites/${sit_id_destination}/diagnostic`, {sit_id_source: sit_id_source});
	}

	public exportDiagnostic(abo_id: number, sit_id: number) {
		let tmpParams = prepareQueryParamsForDownload({});
		return this.http.get(`/abonnes/${abo_id}/sites/${sit_id}/diagnostic/export`, tmpParams);
	}

	public exportTextesDiagnostic(abo_id: number, sit_id: number, params: ExtensibleObject = {}) {
		const tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get(`/abonnes/${abo_id}/sites/${sit_id}/diagnostic/textes/export`, tmpParams);
	}

	public getMergedNomenclatureDiagnostics(abo_id: number, sit_ids: number[]): Observable<Nomenclature> {
		return this.http.get<Nomenclature>(`/abonnes/${abo_id}/nomenclature_diagnostics_combinee?sit_ids=${sit_ids.join(',')}`);
	}

	// Récolements

	public prepareTexteRecolementFromServer(texteRecolement: TexteRecolement): TexteRecolement {
		let tmp = clone(texteRecolement);
		convertDateFieldsToDate(tmp);

		let now = startOfDay(new Date())
		// On ignore les dates de réévaluation dans le passé
		for(let recolement of tmp.recolements){
			if (recolement.rec_date_reevaluation < now) {
				delete recolement.rec_date_reevaluation
			}
		}
		for (let article of tmp.articles) {
			for(let recolement of article.recolements){
				if (recolement.ear_date_reevaluation < now) {
					delete recolement.ear_date_reevaluation
				}
			}
		}
		return tmp;
	}

	public prepareTexteRecolementForServer(texteRecolement: TexteRecolement) {
		let tmp = structuredClone(texteRecolement) as ExtensibleObject;
		convertDateFieldsToString(tmp);
		return tmp;
	}

	public getRecolementsTextes(abo_id: number, sit_ids: number[], params: ExtensibleObject = {}) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<{total: number, textes: TexteRecolement[] }>(`/abonnes/${abo_id}/recolements_textes?sit_ids=${sit_ids.join(',')}`, tmpParams)
		.pipe(map(({total, textes}) => {
			return {
				total: total,
				textes: textes.map(this.prepareTexteRecolementFromServer)
			};
		}));
	}

	public exportRecolementsTextes(abo_id: number, sit_ids: number[], params: unknown = {}) {
		const tmpParams = prepareQueryParamsForDownload(params);

		return this.http.get(`/abonnes/${abo_id}/recolements_textes/export?sit_ids=${sit_ids.join(',')}`, tmpParams);
	}

	public updateSomeRecolementsTextes(abo_id: number, sit_ids: number[], textes: TexteRecolement[]) {
		let body: any = textes.map(this.prepareTexteRecolementForServer);

		for (let texte of body) {
			texte.recolements.forEach((recolement: any) => {
				delete recolement.eva_code;
				delete recolement.rec_date_reevaluation;
			});

			texte.articles.forEach((article: any) => {
				article.recolements.forEach((recolement: any) => {
					delete recolement.eva_code;
					delete recolement.ear_date_reevaluation;
				});
			});
		}

		return this.http.patch<{total: number, textes: TexteRecolement[] }>(`/abonnes/${abo_id}/recolements_textes?sit_ids=${sit_ids.join(',')}`, body);
	}

	// Evaluations

	public prepareTexteEvaluationFromServer(texteEvaluation: TexteEvaluation): TexteEvaluation {
		let tmp = clone(texteEvaluation);
		convertDateFieldsToDate(tmp);

		let now = startOfDay(new Date())
		// On ignore les dates de réévaluation dans le passé
		for(let evaluation of tmp.evaluations){
			if (evaluation.rec_date_reevaluation < now) {
				delete evaluation.rec_date_reevaluation
			}
		}
		for (let article of tmp.articles) {
			for(let evaluation of article.evaluations){
				if (evaluation.ear_date_reevaluation < now) {
					delete evaluation.ear_date_reevaluation
				}
			}
		}
		return tmp;
	}

	public prepareTexteEvaluationForServer(texteEvaluation: TexteEvaluation) {
		let tmp = structuredClone(texteEvaluation) as ExtensibleObject;
		convertDateFieldsToString(tmp);
		return tmp;
	}

	public getEvaluationsTextes(abo_id: number, sit_ids: number[], params: ExtensibleObject = {}) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<{total: number, textes: TexteEvaluation[] }>(`/abonnes/${abo_id}/evaluations_textes?sit_ids=${sit_ids.join(',')}`, tmpParams)
		.pipe(map(({total, textes}) => {
			return {
				total: total,
				textes: textes.map(this.prepareTexteEvaluationFromServer)
			};
		}));
	}

	public exportEvaluationsTextes(abo_id: number, sit_ids: number[], params: unknown = {}) {
		const tmpParams = prepareQueryParamsForDownload(params);

		return this.http.get(`/abonnes/${abo_id}/evaluations_textes/export?sit_ids=${sit_ids.join(',')}`, tmpParams);
	}

	public updateSomeEvaluationsTextes(abo_id: number, sit_ids: number[], textes: TexteEvaluation[]) {
		let body: any = textes.map(this.prepareTexteEvaluationForServer);

		for (let texte of body) {
			texte.evaluations.forEach((evaluation: any) => {
				delete evaluation.sta_code;
			});

			texte.articles.forEach((article: any) => {
				article.evaluations.forEach((evaluation: any) => {
					delete evaluation.sta_code;
				});
			});
		}

		return this.http.patch<{total: number, textes: TexteRecolement[] }>(`/abonnes/${abo_id}/evaluations_textes?sit_ids=${sit_ids.join(',')}`, body);
	}

	// Statistiques

	public getStatsConformite(abo_id: number): Observable<StatistiquesConformite> {
		return this.http.get<StatistiquesConformite>(`/abonnes/${abo_id}/statistiques_conformite`);
	}
}