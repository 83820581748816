import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subscription } from 'rxjs';

import { TranslateService } from '@codeandweb/ngx-translate';

import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { EvaluationDefinition, ListesGeneriquesService } from '@global/listes-generiques.service';
import { CodeEvaluation } from '@app/texte/texte.model';

import { ControlValueAccessorBase } from '@app/_helpers/control-value-accessor-base';

@Component({
	selector: 'evaluation-selector',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => EvaluationSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[filter]="options.length > 5"
			filterBy="rub_nom"
			[readonly]="readonly"
			[ngClass]="{'p-readonly': readonly}"
			[placeholder]="_placeholder"
			[disabled]="disabled"
			[showClear]="nullOption"
			[virtualScroll]="true"
			[virtualScrollItemSize]="virtualScrollItemSize"
			optionValue="eva_code"
			optionLabel="eva_libelle"
			appendTo="body"
			[styleClass]="styleClass"
		>
		</p-dropdown>
	`
})
export class EvaluationSelectorComponent extends ControlValueAccessorBase<string> implements OnInit, OnDestroy  {

	@Input() name: string;
	@Input('value') innerValue: string;
	@Input() required: boolean;
	@Input() readonly: boolean;
	@Input() placeholder: string;
	@Input() nullIfInvalid: boolean;
	@Input() nullOption: boolean;
	@Input() disabled: boolean;
	@Input() unavailableRubriques?: number[];
	@Input() excludedValues?: CodeEvaluation[];
	@Input() fam_id: number;
	@Input() thm_id: number;
	@Input() styleClass?: string

	_placeholder: string = 'Sélectionnez';

	options: EvaluationDefinition[] = [];

	subscriptionLangChange?: Subscription;

	// TODO on devrait centraliser ça
	virtualScrollItemSize: number = 18;

	constructor(
		private listesGeneriquesService: ListesGeneriquesService,
		private translateService: TranslateService,
	) {
		super();

		this.options = this.listesGeneriquesService.definitionsEvaluations;

		if (this.placeholder == undefined) {
			this.subscriptionLangChange = this.translateService.onLangChange.subscribe(() => {
				this._placeholder = translateService.instant('common.selector_placeholder');
			});
		}
	}

	ngOnInit() {
		if (this.placeholder != undefined) {
			this._placeholder = this.placeholder;
		}
		if (this.excludedValues != undefined) {
			this.options = this.options.filter((def: EvaluationDefinition) => !this.excludedValues!.includes(def.eva_code));
		}
	}

	ngOnDestroy(): void {
		this.subscriptionLangChange?.unsubscribe();
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		AutoCompleteModule,
		DropdownModule
	],
	exports: [EvaluationSelectorComponent],
	declarations: [EvaluationSelectorComponent]
})
export class EvaluationSelectorModule { }
