import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';

import { UIRouterModule } from '@uirouter/angular';

import { TranslateService } from '@codeandweb/ngx-translate';

import { MenuModule } from '@app/primeng-overrides/menu';
import { ChartModule } from 'primeng/chart';
import { DividerModule } from 'primeng/divider';
import { MeterGroupModule, MeterItem } from 'primeng/metergroup';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import { ListesGeneriquesService } from '@app/_global/listes-generiques.service';
import { AbonneService } from '@app/abonne/abonne.service';
import { StatistiquesConformiteGlobales, StatistiquesConformiteSite } from '@app/site/site.service';
import { CodeEvaluation, StatutRecolement } from '@app/texte/texte.model';
import { GlobalModule } from '@global/global.module';

const COLORS = {
	statutRecolement: {
		inc: 'lightgrey',
		inf: 'bisque',
		app: 'deepskyblue',
		nap: 'gold'
	} satisfies Record<StatutRecolement, string>,
	etatEvaluation: {
		aev: 'gold',
		arv: 'blue',
		con: 'limegreen',
		nco: 'crimson',
		nap: 'lightgrey',
	} satisfies Record<CodeEvaluation, string>
}

@Component({
	selector: 'bloc-statistiques',
	templateUrl: `bloc-statistiques.html`
})
export class BlocStatistiques implements OnInit {

	@Input({alias: 'statistiques'}) stats: StatistiquesConformiteGlobales | StatistiquesConformiteSite
	@Input() isBlockAlone = false;

	isGlobal = false;
	sit_libelle?: string;
	sit_id?: number;

	accessibleSitIds: number[];

	textes_par_statut: MeterItem[] = [];
	textes_par_etat_evaluation: MeterItem[] = []; // hors à réévaluer et non-applicable
	textes_a_reevaluer: {nombre: number, label: string, color: string};
	textes_non_applicables: {nombre: number, label: string, color: string};

	articles_par_statut: MeterItem[] = [];
	articles_par_etat_evaluation: MeterItem[] = [];  // hors à réévaluer et non-applicable
	articles_a_reevaluer: {nombre: number, label: string, color: string};
	articles_non_applicables: {nombre: number, label: string, color: string};

	sta_codes: StatutRecolement[] = ['app', 'nap', 'inf', 'inc'];
	eva_codes: CodeEvaluation[] = ['con', 'nco', 'aev', 'arv', 'nap'];

	chartJsPlugins = [ChartDataLabels];

	reviewRateData: any;
	complianceRateData: any;

	reviewRatePieChartOptions = {
		plugins: {
			legend: {position: 'bottom'},
			datalabels: {
				color: ['black', 'transparent'],
				formatter: (n: number) => this.formatPercent(100 * n) + '%',
				font: {
					weight: 'bold',
					size: 16
				}
			}
		},
		responsive: true,
		maintainAspectRatio: false,
	};


	complianceRatePieChartOptions = {
		...this.reviewRatePieChartOptions,
		plugins: {
			...this.reviewRatePieChartOptions.plugins,
			datalabels: {
				color: ['black', 'transparent'],
				formatter: (n: number) => this.formatPercent(100 * n) + '%',
				font: {
					weight: 'bold',
					size: 16
				}
			}
		},
	};

	constructor(
		private abonneService: AbonneService,
		private listeGeneriquesService: ListesGeneriquesService,
		private translate: TranslateService
	) {
		this.accessibleSitIds = abonneService.currentAbonneValue!.sites.map(site => site.sit_id);
	}

	ngOnInit(): void {
		if('sit_id' in this.stats) {
			this.sit_libelle = this.stats.sit_libelle;
			this.sit_id = this.stats.sit_id;
		}
		else {
			this.isGlobal = true;
		}

		this.reviewRateData = {
			labels: [
				this.translate.instant('dashboard.reviewed_textes_legend_label'),
				//this.translate.instant('dashboard.non_reviewed_articles_legend_label'),
			],
			datasets: [
				{
					data: [this.stats.taux_recolement, 1 - this.stats.taux_recolement],
					borderWidth: 1,
					backgroundColor: ['limegreen', 'lightgrey'],
				},
			]
		};

		this.complianceRateData = {
			labels: [ this.translate.instant('dashboard.compliant_articles_legend_label') ],
			datasets: [
				{
					data: [this.stats.taux_conformite, 1 - this.stats.taux_conformite],
					borderWidth: 1,
					backgroundColor: ['limegreen', 'lightgrey'],
				}
			]
		};

		for (let sta_code of this.sta_codes) {
			let decompteTextes = this.stats.textes_par_statut[sta_code];

			this.textes_par_statut.push({
				label:  decompteTextes.nombre + ' ' + this.listeGeneriquesService.getDefinitionStatut(sta_code).sta_libelle.toLowerCase() + 's',
				value: decompteTextes.proportion * 100,
				color: COLORS.statutRecolement[sta_code]
			});

			let decompteArticles = this.stats.articles_par_statut[sta_code];

			this.articles_par_statut.push({
				label: decompteArticles.nombre + ' ' + this.listeGeneriquesService.getDefinitionStatut(sta_code).sta_libelle.toLowerCase() + 's',
				value: decompteArticles.proportion * 100,
				color: COLORS.statutRecolement[sta_code]
			});
		}

		for (let eva_code of this.eva_codes) {

			let baseLabel = '';
			switch (eva_code) {
			case 'aev':
				baseLabel += 'à évaluer'
				break
			case 'arv':
				baseLabel += 'à réévaluer'
				break
			default:
				baseLabel += this.listeGeneriquesService.getDefinitionEvaluation(eva_code).eva_libelle.toLowerCase() + 's'
				break;
			}

			let decompteTextes = this.stats.textes_par_etat_evaluation[eva_code];
			let decompteArticles = this.stats.articles_par_etat_evaluation[eva_code];

			switch (eva_code) {
			case 'arv':
				this.textes_a_reevaluer = {
					nombre: decompteTextes.nombre,
					label:  decompteTextes.nombre + ' ' + baseLabel,
					color: COLORS.etatEvaluation[eva_code],
				};
				this.articles_a_reevaluer = {
					nombre: decompteArticles.nombre,
					label: decompteArticles.nombre + ' ' + baseLabel,
					color: COLORS.etatEvaluation[eva_code],
				};
				break;
			case 'nap':
				this.textes_non_applicables = {
					nombre: decompteTextes.nombre,
					label: decompteTextes.nombre + ' ' + baseLabel,
					color: COLORS.etatEvaluation[eva_code],
				};
				this.articles_non_applicables = {
					nombre: decompteArticles.nombre,
					label:  decompteArticles.nombre + ' ' + baseLabel,
					color: COLORS.etatEvaluation[eva_code],
				};
				break;
			default:
				this.textes_par_etat_evaluation.push({
					label: decompteTextes.nombre + ' ' + baseLabel,
					value: decompteTextes.proportion * 100,
					color: COLORS.etatEvaluation[eva_code],
				});

				this.articles_par_etat_evaluation.push({
					label: decompteArticles.nombre + ' ' + baseLabel,
					value: decompteArticles.proportion * 100,
					color: COLORS.etatEvaluation[eva_code],
				});
			}

		}
	}

	formatPercent(n: number) {
		let s = n.toFixed(2).replace('.', ',');
		if (s.endsWith(',00')) {
			return s.slice(0, -3);
		}
		if (s.endsWith('00')) {
			return s.slice(0, -1);
		}
		return s;
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		DividerModule,
		MeterGroupModule,
		ChartModule
	],
	exports: [BlocStatistiques],
	declarations: [BlocStatistiques]
})
export class BlocStatistiquesModule { }
