import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { CalendarModule } from '@app/primeng-overrides/calendar';
import { MenuModule } from '@app/primeng-overrides/menu';
import { Table, TableModule } from '@app/primeng-overrides/table';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, LazyLoadEvent, MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { EventManagerService } from '@app/_global/event-manager.service';
import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { FamilleSelectorModule } from '@app/famille/famille-selector';
import { NomenclatureSelectorModule } from '@app/nomenclature/nomenclature-selector';
import { NomenclatureService } from '@app/nomenclature/nomenclature.service';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { RubriqueSelectorModule } from '@app/rubrique/rubrique-selector';
import { ThemeSelectorModule } from '@app/theme/theme-selector';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { GlobalModule } from '@global/global.module';
import { StorageService } from '@global/storage.service';
import { Texte } from '../texte/texte.model';

import { environment } from '@environments/environment';
import { createDownloadFromHttpResponse, ExtensibleObject, simpleDateToString, startOfDay } from '@helpers/utils';


@Component({
	selector: 'liste-textes',
	templateUrl: `liste-textes.component.html`
})
export class ListeTextesComponent implements OnInit {

	@ViewChild('tableTextes', { static: true }) table: Table;

	abonne_courant: Abonne;

	values: Texte[] = [];
	totalRecords: number = 0;
	loading: boolean;
	rows: number = environment.default_rows;

	_defaultParams: { [key: string]: any } = {
		search: null,
		search_reference: null,
		fam_id: null,
		thm_id: null,
		rub_id: null,
		orphelin: false,
		date_debut: null,
		date_fin: null,
		order_by: null
	};

	params = structuredClone(this._defaultParams);
	advancedFilters: boolean = false;
	orderByOptions: {label: string, value: string, disabled?: boolean}[];

	menuItems: MenuItem[] = [];
	canEdit = false;

	nomenclatureFilter: any;

	today: Date = startOfDay(new Date());

	dateRange: Date[] | null = null;

	constructor(
		private storageService: StorageService,
		private stateService: StateService,
		private translate: TranslateService,
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private nomenclatureService: NomenclatureService,
		private utilisateurService: UtilisateurService,
		private abonneService: AbonneService,
	) {
		this.abonne_courant = this.abonneService.currentAbonneValue!;

		this.canEdit = !!this.utilisateurService.currentUtilisateurValue?.uti_administrateur;
		this.orderByOptions = [
			{
				value: 'pertinence',
				label: this.translate.instant('textes.sort_option_relevance'),
				disabled: true // Désactivé par défaut
			},
			{
				value: 'txt_date',
				label: this.translate.instant('textes.date_de_saisie')
			},
			{
				value: 'txt_date_signature',
				label: this.translate.instant('textes.date_signature')
			},
			{
				value: 'txt_date_application',
				label: this.translate.instant('textes.date_application')
			},
			{
				value: 'txt_date_modificative',
				label: this.translate.instant('textes.date_modificative')
			},
			{
				value: 'txt_date_abrogation',
				label: this.translate.instant('textes.date_abrogation')
			}
		];
	}

	private initializeMenu() {
		if (this.canEdit) {
			this.menuItems = [
				{
					label: this.translate.instant('common.create_action_label'),
					icon: 'pi pi-plus',
					command: () => {
						this.stateService.go('.create');
					}
				}
			];
		}

		this.menuItems.push(
			{
				label: this.translate.instant('common.export_action_label'),
				icon: 'pi pi-download',
				command: () => {
					this.exportTextes(false);
				}
			},
			{
				label: this.translate.instant('textes.export_with_articles'),
				icon: 'pi pi-download',
				command: () => {
					this.exportTextes(true);
				}
			},
		);
	}

	ngOnInit(): void {
		this.initializeMenu();
		this.getParamSet();

		this.advancedFilters = this.showAdvancedFilters();
	}

	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('tableParams', undefined, true);
		if (tableParams) {
			for (let prop in this.params) {
				if (typeof tableParams[prop] != 'undefined') this.params[prop] = tableParams[prop];
			}
			if (tableParams.first) this.table.first = tableParams.first;
			if (tableParams.rows) this.rows = tableParams.rows;

			if (tableParams.date_debut && tableParams.date_fin) {
				this.dateRange = [
					new Date(tableParams.date_debut),
					new Date(tableParams.date_fin)
				]
			}
			if (tableParams.nomenclatureFilter) {
				this.nomenclatureFilter = tableParams.nomenclatureFilter;
			}
		}
		this.table.rows = this.rows;
	}

	saveParamSet() {
		if (this.table) {
			let tableParams = Object.assign({}, this.params, {
				first: this.table.first,
				rows: this.table.rows,
				nomenclatureFilter: this.nomenclatureFilter
			});
			this.storageService.setForCurrentState('tableParams', tableParams, true);

			const uiParams: any = Object.assign({}, this.stateService.params, this.params, {
				sit_actif: undefined
			});
			this.stateService.go('.', uiParams, { reload: false, location: 'replace', inherit: false });
		}
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	loadTextes(event: LazyLoadEvent) {
		// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => {
			this.loading = true;
		});

		const tmpParams = Object.assign({}, event, this.params);

		this.saveParamSet();

		this.postProcessQueryParams(tmpParams);

		this.nomenclatureService.getTextes(tmpParams)
		.subscribe({
			next: ({ textes, total }) => {
				this.totalRecords = total;
				this.values = textes;

				// si on revient sur la liste paginée et que la page en question est vide, on reset
				if (this.values.length === 0 && this.table.first != 0) {
					this.refresh();
				}
			}
		})
		.add(() => { this.loading = false; });
	}

	exportTextes(articles = false){
		const tmpParams = Object.assign({}, event, this.params);
		tmpParams.avec_articles = articles;
		this.postProcessQueryParams(tmpParams);

		this.nomenclatureService.exportTextes(tmpParams)
		.subscribe(response => {
			createDownloadFromHttpResponse(response);
		});
	}

	postProcessQueryParams(tmpParams: ExtensibleObject) {
		if (String(tmpParams.order_by).startsWith('txt_date')) {
			tmpParams.order = 'DESC';
		} else if (tmpParams.order_by == 'pertinence') {
			delete tmpParams.order_by;
		}
	}

	showAdvancedFilters() {
		return this.params.orphelin
			|| this.params.date_debut
			|| this.params.date_fin
		;
	}

	toggleAdvancedFilters() {
		this.advancedFilters = !this.advancedFilters;
	}

	setDates(dateRange?: any) {
		this.params.date_debut = null;
		this.params.date_fin = null;
		if (dateRange && dateRange[1]) {
			this.params.date_debut = simpleDateToString(dateRange[0], true);
			this.params.date_fin = simpleDateToString(dateRange[1], true);
		}
		else {
			this.dateRange = null;
		}
		this.refresh();
	}

	resetDates() {
		this.setDates();
	}

	nomenclatureSelectionChange(event: any) {
		this.params.fam_id = null;
		this.params.thm_id = null;
		this.params.rub_id = null;
		if (event) {
			this.params.fam_id = event.fam_id;
			this.params.thm_id = event.thm_id;
			this.params.rub_id = event.rub_id;
		}
		this.refresh();
	}

	searchChange() {
		if (this.params.search) {
			// Forcage du tri par pertinence
			for (let orderByOption of this.orderByOptions) {
				orderByOption.disabled = orderByOption.value != 'pertinence';
			}
			this.params.order_by = 'pertinence';
		}
		else {
			// Désativation du tri par pertinence
			for (let orderByOption of this.orderByOptions) {
				orderByOption.disabled = orderByOption.value == 'pertinence';
			}
			this.params.order_by = null;
		}
		this.refresh();
	}

	resetFilters() {
		this.params = structuredClone(this._defaultParams);
		this.dateRange = null;
		this.refresh();
	}

	deleteTexte(texte: Texte) {
		let message = this.translate.instant('textes.deletion_confirmation', {text: texte.txt_reference});

		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: message,
			accept: () => {
				this.nomenclatureService.deleteTexte(texte.txt_id).subscribe({
					complete: () => {
						this.eventManager.emit('toast', { severity: 'success', summary: this.translate.instant('textes.deletion_success') });
						this.refresh(true);
					}
				});
			}
		});
	}
}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		AccordionModule,
		ButtonModule,
		CalendarModule,
		CheckboxModule,
		IconFieldModule,
		InputIconModule,
		MenuModule,
		PanelModule,
		TableModule,
		ToolbarModule,
		FamilleSelectorModule,
		ThemeSelectorModule,
		RubriqueSelectorModule,
		NomenclatureSelectorModule,
		DropdownModule
	],
	exports: [ListeTextesComponent],
	declarations: [ListeTextesComponent]
})
export class ListeTextesModule { }
