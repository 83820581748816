<p-panel
	class="no-bottom-padding-header-panel"
	[header]="sit_libelle ?? ('dashboard.global_stats_header' | translate)"
>
	<div class="p-fluid grid">

		<div [ngClass]="(isGlobal || isBlockAlone) ? 'col-12 xl:col-8' : 'col-12'">
			<!-- Textes -->
			<div class="field col-12 mb-0">
				<label>{{'dashboard.texts_section_label' | translate}} ({{stats.nombre_textes}})</label>

				<!-- Décomposition par statut -->
				<p-meterGroup [value]="textes_par_statut" #group>
					<ng-template pTemplate="label" let-value>
						<ol class="p-metergroup-labels p-metergroup-label-list p-component p-metergroup-label-list-horizontal gap-4">
							<li *ngFor="let labelItem of value; let index = index; trackBy: group.trackByFn">
								<a
									uiSref="abonne-courant.recolements"
									[uiParams]="{sit_ids: sit_id ?? accessibleSitIds, selected_sta_code: sta_codes[index]}"
									class="p-metergroup-label clickable-stat"
								>
									<span class="p-metergroup-label-marker" [ngStyle]="{'background-color': labelItem.color}"></span>
									<span class="p-metergroup-label-text">{{ labelItem.label }}</span>
									<span class="p-metergroup-label-text font-medium">{{ formatPercent(labelItem.value) }}%</span>
								</a>
							</li>
						</ol>
					</ng-template>
				</p-meterGroup>

				<div class="mt-2 col-12"></div>

				<div class="p-fluid grid">

					<!-- Décomposition par état d'évaluation hormis à réévaluer et non-applicable -->
					<p-meterGroup
						[value]="textes_par_etat_evaluation"
						[ngClass]="(isGlobal || isBlockAlone) ? 'col-12 xxxl:col-8' : 'col-12'"
					>
						<ng-template pTemplate="label" let-value>
							<ol class="p-metergroup-labels p-metergroup-label-list p-component p-metergroup-label-list-horizontal gap-4">
								<li *ngFor="let labelItem of value; let index = index; trackBy: group.trackByFn" class="p-metergroup-label">

									<ng-container *ngIf="eva_codes[index] == 'arv' || eva_codes[index] == 'nap'">
										<span class="p-metergroup-label-marker" [ngStyle]="{'background-color': labelItem.color}"></span>
										<span class="p-metergroup-label-text">{{ labelItem.label }}</span>
										<span class="p-metergroup-label-text font-medium">{{ formatPercent(labelItem.value) }}%</span>
									</ng-container>

									<a
										*ngIf="eva_codes[index] != 'arv' && eva_codes[index] != 'nap'"
										uiSref="abonne-courant.evaluations"
										[uiParams]="{sit_ids: sit_id ?? accessibleSitIds, selected_eva_code: eva_codes[index]}"
										class="p-metergroup-label clickable-stat"
									>
										<span class="p-metergroup-label-marker" [ngStyle]="{'background-color': labelItem.color}"></span>
										<span class="p-metergroup-label-text">{{ labelItem.label }}</span>
										<span class="p-metergroup-label-text font-medium">{{ formatPercent(labelItem.value) }}%</span>
									</a>
								</li>
							</ol>
						</ng-template>
					</p-meterGroup>

					<div
						class="flex flex-rows align-items-end gap-4"
						[ngClass]="(isGlobal || isBlockAlone) ? 'col-12 xxxl:col-4 xxxl:pl-6' : 'col-12'"
					>
						<div>
							<div class="stat-marker"></div>
							{{textes_a_reevaluer.label}}
						</div>

						<div>
							<div class="stat-marker"></div>
							{{textes_non_applicables.label}}
						</div>
					</div>
				</div>
			</div>

			<p-divider class="col-12 m-0 p-0 mt-1"></p-divider>

			<!-- Articles -->
			<div class="field col-12 mb-0">
				<label>{{'dashboard.articles_section_label' | translate}} ({{stats.nombre_articles}})</label>

				<!-- Décomposition par statut -->
				<p-meterGroup class="w-full" [value]="articles_par_statut">
					<ng-template pTemplate="label" let-value>
						<ol class="p-metergroup-labels p-metergroup-label-list p-component p-metergroup-label-list-horizontal gap-4">
							<li *ngFor="let labelItem of value; let index = index; trackBy: group.trackByFn" class="p-metergroup-label">
								<span class="p-metergroup-label-marker" [ngStyle]="{'background-color': labelItem.color}"></span>
								<span class="p-metergroup-label-text">{{ labelItem.label }}</span>
								<span class="p-metergroup-label-text font-medium">{{ formatPercent(labelItem.value) }}%</span>
							</li>
						</ol>
					</ng-template>
				</p-meterGroup>

				<div class="mt-2 col-12"></div>

				<div class="p-fluid grid">

					<!-- Décomposition par état d'évaluation -->
					<p-meterGroup
						[value]="articles_par_etat_evaluation"
						[ngClass]="(isGlobal || isBlockAlone) ? 'col-12 xxxl:col-8' : 'col-12'"
					>
						<ng-template pTemplate="label" let-value>
							<ol class="p-metergroup-labels p-metergroup-label-list p-component p-metergroup-label-list-horizontal gap-4">
								<li *ngFor="let labelItem of value; let index = index; trackBy: group.trackByFn" class="p-metergroup-label">
									<span class="p-metergroup-label-marker" [ngStyle]="{'background-color': labelItem.color}"></span>
									<span class="p-metergroup-label-text">{{ labelItem.label }}</span>
									<span class="p-metergroup-label-text font-medium">{{ formatPercent(labelItem.value) }}%</span>
								</li>
							</ol>
						</ng-template>
					</p-meterGroup>

					<div
						class="flex flex-rows align-items-end gap-4"
						[ngClass]="(isGlobal || isBlockAlone) ? 'col-12 xxxl:col-4 xxxl:pl-6' : 'col-12'"
					>
						<div>
							<div class="stat-marker"></div>
							{{articles_a_reevaluer.label}}
						</div>

						<div>
							<div class="stat-marker"></div>
							{{articles_non_applicables.label}}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="!isGlobal && !isBlockAlone" class="col-12"></div>

		<div class="p-fluid grid" [ngClass]="(isGlobal || isBlockAlone) ? 'col-12 xl:col-4' : 'col-8'">
			<!-- Taux de récolement -->
			<div class="field col-12 md:col-7 mb-0">
				<label>{{'dashboard.reviewed_rate_section_label' | translate}}</label>

				<div class="w-full mt-2">
					<p-chart type="pie"
						[data]="reviewRateData"
						[options]="reviewRatePieChartOptions"
						[plugins]="chartJsPlugins"
						height="10rem"
					>
					</p-chart>
				</div>
			</div>

			<!-- Taux de conformité -->
			<div class="field col-12 md:col-5 mb-0">
				<label>{{'dashboard.compliance_rate_section_label' | translate}}</label>

				<div class="w-full mt-2">
					<p-chart type="pie"
						[data]="complianceRateData"
						[options]="complianceRatePieChartOptions"
						[plugins]="chartJsPlugins"
						height="10rem"
					>
					</p-chart>
				</div>
			</div>
		</div>
	</div>
</p-panel>