<!-- Titre -->
<div class="page-title">
	{{ 'textes.admin_page_title' | translate }}
</div>

<p-toolbar styleClass="pb-0">
	<ng-template pTemplate="left">
		<div class="grid formgrid fluid w-full">
			<!-- Sélection de la nomenclature -->
			<div class="field col-12 md:col-4">
				<label for="search">{{'nomenclature.label'|translate}}</label>
				<nomenclature-selector
					class="block max-w-40rem"
					[(ngModel)]="nomenclatureFilter"
					placeholder="{{'common.selector_placeholder' | translate}}"
					(onSelectionChange)="nomenclatureSelectionChange($event)"
					[treeSelect]="true"
				></nomenclature-selector>
			</div>

			<!-- Recherche -->
			<div class="field col-12 md:col-3">
				<label for="search">{{'common.search_label'|translate}}</label>
				<div class="p-inputgroup mr-2">
					<input
						pInputText
						type="text"
						name="search"
						[(ngModel)]="params.search"
						(onDebounce)="searchChange()"
						[debounce]="500"
						placeholder="{{'common.search_input_placeholder' | translate}}"
					/>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!params.search || loading" (click)="params.search = null" ></button>
				</div>
			</div>

			<!-- Recherche par référence -->
			<div class="field col-12 md:col-3">
				<label for="filter_fam">{{'textes.reference'|translate}}</label>
				<div class="p-inputgroup mr-2">
					<input
						pInputText
						type="text"
						name="search_reference"
						[(ngModel)]="params.search_reference"
						(onDebounce)="refresh()"
						[attr.placeholder]="'common.search_input_placeholder' | translate"
					/>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!params.search_reference || loading" (click)="params.search_reference = null"></button>
				</div>
			</div>

			<!-- Tri -->
			<div class="field col-12 md:col-3 xl:col-2">
				<label for="order_by">{{'common.sort_label'|translate}}</label>
				<p-dropdown
					name="order_by"
					[options]="orderByOptions"
					[(ngModel)]="params.order_by"
					(onChange)="refresh()"
					optionValue="value"
					optionLabel="label"
					[showClear]="!params.search"
					appendTo="body"
					[placeholder]="'common.sort_label'|translate"
					class="p-dropdown-w-full"
				>
				</p-dropdown>
			</div>

			<!-- Filtres avancés -->
			<ng-container *ngIf="advancedFilters">

				<!-- Intervalle de date -->
				<div class="field col-fixed">
					<label for="date_debut">{{'common.date_between'|translate}}</label>
					<p-calendar
						#dateRangeSelector
						[(ngModel)]="dateRange"
						selectionMode="range"
						appendTo="body"
						view="date"
						dateFormat="dd/mm/yy"
						[maxDate]="today"
						[showIcon]="true"
						[showButtonBar]="true"
						(onClose)="setDates(dateRange)"
						class="p-inputgroup"
						placeholder="{{'common.select_two_dates'|translate}}"
					>
					</p-calendar>
				</div>

				<!-- Coche textes sans rubrique -->
				<div class="field-checkbox col-fixed align-items-end">
					<p-checkbox
						name="orphelin"
						inputId="orphelin"
						[(ngModel)]="params.orphelin"
						[binary]="true"
						label="{{'textes.orphan'|translate}}"
						[trueValue]="true"
						[falseValue]="false"
						(ngModelChange)="refresh()"
						class="mb-2"
					></p-checkbox>
				</div>

			</ng-container>

			<!-- Activation des filtres avancés -->
			<div class="field col-fixed flex align-items-end md:pl-0">
				<button
					type="button"
					pButton
					class="p-button-rounded p-button-text p-button-sm"
					(click)="toggleAdvancedFilters()"
				>
					<span [translate]="advancedFilters? 'common.hide_filters_button_label' : 'common.advanced_filters_button_label'"></span>
				</button>
			</div>

			<!-- Réinitialisation des filtres -->
			<div class="field col-fixed flex align-items-end">
				<button
					type="button"
					pButton
					class="p-button-rounded p-button-text p-button-sm"
					(click)="resetFilters()"
					label="{{'common.reset_filters'|translate}}"
				>
				</button>
			</div>

			<div class="field col-fixed flex align-items-end">
				<button
					type="button"
					pButton
					icon="pi pi-refresh"
					class="p-button-rounded p-button-text p-button-sm"
					(click)="refresh()"
					title="{{ 'common.refresh_button_label' | translate}}"
				>
				</button>
			</div>

		</div>

	</ng-template>

	<ng-template pTemplate="right">
		<div class="grid formgrid fluid w-full">
			<div class="field col-fixed">
				<button
					type="button"
					pButton
					icon="pi pi-bars"
					[label]="'common.actions_button_label' | translate"
					class="p-panel-header-icon p-link"
					(click)="menu_list_textes.toggle($event)"
					*ngIf="menuItems.length"
				></button>
				<p-menu #menu_list_textes [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
			</div>
		</div>
	</ng-template>
</p-toolbar>

<div class="bg-primary p-2 text-white mb-2 border-round flex-shrink-0" *ngIf="nomenclatureFilter">
	{{nomenclatureFilter|nomenclature}}
</div>

<p-table
	#tableTextes
	[value]="values"
	[lazy]="true"
	(onLazyLoad)="loadTextes($event)"
	[loading]="loading"
	[paginator]="true"
	[rows]="rows"
	[showCurrentPageReport]="true"
	[totalRecords]="totalRecords"
	currentPageReportTemplate="{{totalRecords | number}} {{'common.table_total_records'|translate}}"
	[rowsPerPageOptions]="[10,25,50]"
	styleClass="p-datatable-sm p-datatable-gridlines table-scrollable-hover"
	[autoLayout]="true"
	responsiveLayout="scroll"
	scrollDirection="both"
	[scrollable]="true"
	scrollHeight="flex"
	dataKey="txt_id"
>
	<ng-template pTemplate="emptymessage">
		<tr>
			<td class="text-center" colspan="100">
				{{ 'common.no_table_results' | translate }}
			</td>
		</tr>
	</ng-template>

	<ng-template pTemplate="header">
		<tr>
			<th class="text-center w-17rem xl:min-w-15rem">{{'textes.reference'|translate}}</th>
			<th class="text-center min-w-20rem xl:min-w-30rem">{{'textes.intitule'|translate}}</th>
			<th class="text-center min-w-15rem xl:min-w-30rem">{{'rubriques.rubrique_label'|translate}}</th>
			<th class="text-center">{{'textes.date_signature'|translate}}</th>
			<th class="text-center">{{'textes.date_application'|translate}}</th>
			<th class="text-center">{{'textes.date_modificative'|translate}}</th>
			<th class="text-center">{{'textes.date_abrogation'|translate}}</th>
			<th class="text-center">{{'textes.date_de_saisie'|translate}}</th>
			<th class="text-center">{{'textes.abrogated'|translate}}</th>
			<th></th>
		</tr>
	</ng-template>

	<ng-template pTemplate="body" let-texte let-expanded="expanded">
		<tr [ngClass]="{'inactive': !texte.txt_actif, 'text-green-500 font-bold': texte.txt_brouillon}">

			<td>
				<i class="pi pi-pen-to-square" *ngIf="texte.txt_brouillon"></i>
				{{texte.txt_reference}}
			</td>
			<td>{{texte.txt_intitule_plaintext}}</td>
			<td>

				<div class="mb-1" *ngIf="texte.rubriques.length">
					{{texte.rubriques[0] | nomenclature}}
				</div>

				<ng-container *ngIf="texte.rubriques.length > 1">
					<p-accordion class="accordion-transparent">
						<p-accordionTab [header]="'common.and_x_more'|translate:{number: texte.rubriques.length -1}">
							<ng-container *ngFor="let rub of texte.rubriques; first as isFirst">
								<div class="text-sm mb-1" *ngIf="!isFirst">
									• {{rub | nomenclature}}
								</div>
							</ng-container>
						</p-accordionTab>
					</p-accordion>
				</ng-container>
			</td>

			<td class="text-center nowrap">{{texte.txt_date_signature | date:'shortDate'}}</td>
			<td class="text-center nowrap">{{texte.txt_date_application | date:'shortDate'}}</td>
			<td class="text-center nowrap">{{texte.txt_date_modificative | date:'shortDate'}}</td>
			<td class="text-center nowrap">{{texte.txt_date_abrogation | date:'shortDate'}}</td>
			<td class="text-center nowrap">{{texte.txt_date | date:'shortDate'}}</td>
			<td class="text-center nowrap font-bold">
				{{
					texte.txt_abrogation ?
					('common.yes' | translate)
					: ''
				}}
			</td>

			<td class="nowrap" *ngIf="!readonly">
				<a
					pButton
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					uiSref=".single"
					[uiParams]="{txt_id: texte.txt_id}"
				></a>
				<button
					type="button"
					pButton
					icon="pi pi-trash"
					class="p-button-rounded p-button-text p-button-danger"
					(click)="deleteTexte(texte)"
					*ngIf="canEdit"
				></button>
			</td>
		</tr>
	</ng-template>

</p-table>
