import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, NgModule, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { GlobalModule } from '@app/_global/global.module';
import { NomenclatureSelectorModule } from '@app/nomenclature/nomenclature-selector';
import { Article, Texte } from '@app/texte/texte.model';
import { EventManagerService, IEventListener } from '@global/event-manager.service';

import { uid } from '@helpers/utils';

@Component({
	selector: 'validation-modifications-dialog',
	template: `
		<form #form class="mb-2 h-full" #ajoutRubriqueDialog="ngForm" (ngSubmit)="submit()">

			<div class="text-center pb-4">
				<!-- Bouton enregistrer -->
				<button
					type="button"
					pButton
					class="label-md-none"
					[label]="'common.save_button_label' | translate"
					icon="pi pi-save"
					(click)="submit()"
				></button>
			</div>

			<p-checkbox
				name="txt_modification_majeure"
				[(ngModel)]="texte.txt_modification_majeure"
				[binary]="true"
				label="{{'textes.major_text_modification_label' | translate}}"
			></p-checkbox>

			<!-- Articles -->
			<div class="h-30rem overflow-y-auto mt-2 border-top-1 border-gray-200">
				<div
					*ngFor="let article of texte.articles"
					class="border-bottom-1 border-gray-200 flex align-items-center"
					id="article-mover{{article.art_id}}"
				>
					<span class="text-sm flex-shrink-0 mr-1">#{{article.art_ordre +1}}</span>
					<span class="flex-grow-1 white-space-nowrap overflow-hidden text-overflow-ellipsis" [ngClass]="{'font-bold': article.art_id == focusedArticleId}">
						{{article.art_titre || article.art_corps_plaintext?.slice(0, 50)}}
					</span>

					<div class="flex align-items-center flex-shrink-0 py-1 mr-3">
						<p-checkbox
							name="art_modification_majeure{{article.art_id}}"
							[(ngModel)]="article.art_modification_majeure"
							[binary]="true"
							label="{{'textes.major_modification_label' | translate}}"
						></p-checkbox>
					</div>
				</div>
			</div>
		</form>
	`,
	providers: []
})
export class ValidationModificationsDialogComponent implements OnInit, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	texte: Texte;

	@ViewChild('form') formElement: ElementRef<HTMLFormElement>;

	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
	) {
		this.texte = config.data.texte;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	submit(){
		this.dialogRef.close(this.texte);
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		GlobalModule,
		ButtonModule,
		InputTextareaModule,
		NomenclatureSelectorModule
	],
	exports: [ValidationModificationsDialogComponent],
	declarations: [ValidationModificationsDialogComponent]
})
export class ValidationModificationsDialogModule { }

