import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { UIRouterModule } from '@uirouter/angular';

import { MenuModule } from '@app/primeng-overrides/menu';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { StatistiquesConformite } from '@app/site/site.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';
import { BlocStatistiquesModule } from './bloc-statistiques/bloc-statistiques';

@Component({
	selector: 'tableau-de-bord',
	templateUrl: `tableau-de-bord.html`
})
export class TableauDeBordComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input() statistiquesConformite: StatistiquesConformite;

	abonne: Abonne;

	constructor(private abonneService: AbonneService) {
		this.abonne = this.abonneService.currentAbonneValue!;
	}

	ngOnInit(): void {

	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		DividerModule,
		BlocStatistiquesModule,
	],
	exports: [TableauDeBordComponent],
	declarations: [TableauDeBordComponent]
})
export class TableauDeBordModule { }
