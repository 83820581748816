import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { Subscription } from 'rxjs';

import { MenuModule } from '@app/primeng-overrides/menu';
import { Table, TableModule } from '@app/primeng-overrides/table';
import { ConfirmationService, LazyLoadEvent, MenuItem } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';

import { EventManagerService } from '@app/_global/event-manager.service';
import { AbonneSelectorModule } from '@app/abonne/abonne-selector/abonne-selector';
import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { GlobalModule } from '@global/global.module';
import { StorageService } from '@global/storage.service';

import { createDownloadFromHttpResponse } from '@app/_helpers/utils';
import { environment } from '@environments/environment';

@Component({
	selector: 'liste-utilisateurs-admin',
	templateUrl: `./liste-utilisateurs-admin.component.html`
})
export class ListeUtilisateursAdminComponent implements OnInit, OnDestroy {

	@ViewChild('tableUtilisateurs', { static: true }) table: Table;

	@Input() me: Utilisateur;
	@Input('selected_abo_id') initialAboId?: number; // Peut seulement être initialisé dans la liste de tous les utilisateurs (admin)

	values: Utilisateur[] = [];
	totalRecords: number = 0;
	loading: boolean;
	rows: number = environment.default_rows;

	params: { [key: string]: any } = {
		search: null,
		abo_id: undefined,
		uti_actif: true,
		uti_administrateur: undefined
	};

	menuItems: MenuItem[] = [];
	canEdit = false;
	subscriptionLangChange: Subscription;

	constructor(
		private storageService: StorageService,
		private stateService: StateService,
		private utilisateurService: UtilisateurService,
		private abonneService: AbonneService,
		private translate: TranslateService,
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
	) {
		this.subscriptionLangChange = this.translate.onLangChange.subscribe(() => {
			this.initializeMenu();
		});
	}

	ngOnInit(): void {
		this.canEdit = this.me.uti_administrateur;

		this.initializeMenu();
		this.getParamSet();

		if (this.initialAboId != undefined) {
			this.params.abo_id = this.initialAboId;
		}
	}

	ngOnDestroy(): void {
		this.subscriptionLangChange.unsubscribe();
	}

	private initializeMenu() {
		if (this.canEdit) {
			this.menuItems = [
				{
					label: this.translate.instant('common.create_action_label'),
					icon: 'pi pi-user-plus',
					command: () => {
						this.stateService.go('.create');
					}
				},
			];
		}

		this.menuItems.push(	{
			label: this.translate.instant('common.export_action_label'),
			icon: 'pi pi-download',
			command: () => {
				this.exportUserList();
			}
		});
	}

	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('tableParams', undefined, true);
		if (tableParams) {
			for (let prop in this.params) {
				if (typeof tableParams[prop] != 'undefined') this.params[prop] = tableParams[prop];
			}
			if (tableParams.first) this.table.first = tableParams.first;
			if (tableParams.rows) this.rows = tableParams.rows;
		}
		this.table.rows = this.rows;
	}

	saveParamSet() {
		if (this.table) {
			let tableParams = Object.assign({}, this.params, {
				first: this.table.first,
				rows: this.table.rows
			});
			this.storageService.setForCurrentState('tableParams', tableParams, true);

			const uiParams: any = Object.assign({}, this.stateService.params, this.params, {
				ars_actif: undefined
			});
			this.stateService.go('.', uiParams, { reload: false, location: 'replace', inherit: false });
		}
	}

	resetSearch() {
		this.params.search = null;
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	formatAbonneListColumn(utilisateur: Utilisateur){
		return utilisateur.abonnes.map((abo: Abonne) => abo.abo_nom_abonne).join(', ');
	}

	loadUtilisateurs(event: LazyLoadEvent) {
		const tmpParams = Object.assign({}, event, this.params);

		// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => {
			this.loading = true;
		});

		if (tmpParams.abo_id == -1) {
			tmpParams.abo_id = 'null';
		}

		this.saveParamSet();

		this.utilisateurService
		.getUtilisateurs(tmpParams)
		.subscribe({
			next: ({ utilisateurs, total }) => {
				this.totalRecords = total;
				this.values = utilisateurs;

				// si on revient sur la liste paginée et que la page en question est vide, on reset
				if (this.values.length === 0 && this.table.first != 0) {
					this.refresh();
				}
			}
		})
		.add(() => {
			this.loading = false;
		});
	}

	deleteUtilisateur(utilisateur: Utilisateur) {
		let name = '<b>' + utilisateur.uti_nom + ' ' + utilisateur.uti_prenom + '</b>';
		let message = this.translate.instant('users.deletion_confirmation', {name: name});

		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: message,
			accept: () => {
				this.utilisateurService.deleteUtilisateur(utilisateur.uti_id).subscribe({
					complete: () => {
						this.eventManager.emit('toast', { severity: 'success', summary: this.translate.instant('users.deletion_success') });
						this.refresh(true);
					}
				});
			}
		});
	}

	exportUserList(){
		const params = {...this.params};

		if (params.abo_id == -1) {
			params.abo_id = 'null';
		}

		this.utilisateurService.exportUtilisateurs(params)
		.subscribe(response => {
			createDownloadFromHttpResponse(response);
		});
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		FormsModule,
		UIRouterModule,
		ButtonModule,
		TableModule,
		ToolbarModule,
		MenuModule,
		AbonneSelectorModule,
		BadgeModule
	],
	exports: [ListeUtilisateursAdminComponent],
	declarations: [ListeUtilisateursAdminComponent]
})
export class ListeUtilisateursAdminModule { }
