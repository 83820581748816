import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { MenuModule } from '@app/primeng-overrides/menu';
import { ConfirmationService } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { DialogService } from 'primeng/dynamicdialog';
import { PanelModule } from 'primeng/panel';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';

import { EventManagerService } from '@app/_global/event-manager.service';
import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { AuthService } from '@app/auth/auth.service';
import { CiviliteSelectorModule } from '@app/utilisateur/civilite-selector';
import { Utilisateur, UtilisateurAbonne } from '@app/utilisateur/utilisateur.model';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { TranslateService } from '@codeandweb/ngx-translate';
import { GlobalModule } from '@global/global.module';

@Component({
	selector: 'utilisateur-general-tab',
	templateUrl: `utilisateur-general-tab.component.html`
})
export class UtilisateurGeneralTabComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input() utilisateur: Utilisateur;
	@Input() linkToAbonneAtCreation = false;
	@Input() isAdminView = false;

	initialUtilisateur: Utilisateur;

	submitted = false;

	isCreation = false;
	canEditUser = false;
	editMode = false;
	selfEditing = false;
	isSubscriptionManagerView = false;
	reinitialisationMotDePasseLoading: boolean = false;
	loading = false;

	// Vue du gestionnaire d'abonnement
	currentAbonne: Abonne | null;
	currentUtiAbonne: UtilisateurAbonne;

	constructor(
		private stateService: StateService,
		private abonneService: AbonneService,
		private utilisateurService: UtilisateurService,
		private eventManager: EventManagerService,
		private dialogService: DialogService,
		private confirmationService: ConfirmationService,
		private translationService: TranslateService,
		private authService: AuthService,
	) {
		this.currentAbonne = this.abonneService.currentAbonneValue!;
	}

	ngOnInit(): void {
		this.canEditUser = this.me.uti_administrateur;
		let utiAbonne = this.currentAbonne?.utilisateurs_abonnes.find(utilisateur => utilisateur.uti_id == this.me.uti_id);
		if (utiAbonne && utiAbonne.utb_gestion_abonnement) {
			this.isSubscriptionManagerView = true;
			this.currentUtiAbonne = utiAbonne;
		}

		if (this.canEditUser) {
			this.isCreation = this.utilisateur == undefined;
			if (this.isCreation) {
				this.utilisateur = {uti_actif: true, uti_bloque: false} as Utilisateur;
				this.editMode = true;
			}
		}

		this.initialUtilisateur = structuredClone(this.utilisateur);
		this.selfEditing = this.me.uti_id == this.utilisateur.uti_id;
	}

	toggleEditMode(status?: boolean) {
		this.editMode = (status !== undefined)? status : !!!this.editMode;
	}

	submit(){
		this.submitted = true;

		if (this.isCreation) {
			let abo_id: number | undefined;
			if (this.linkToAbonneAtCreation && this.currentAbonne) {
				abo_id = this.currentAbonne.abo_id;
			}

			this.utilisateurService.createUtilisateur(this.utilisateur, abo_id)
			.subscribe({
				next: ({ uti_id }) => {
					this.eventManager.emit('toast', { severity: 'success', summary: 'Utilisateur créé' });

					this.abonneService.refreshCurrentAbonneData().subscribe(() => {
						this.stateService.go('^.single', {uti_id: uti_id});
					});
				}
			})
		}
		else {
			this.utilisateurService.updateUtilisateur(this.utilisateur)
			.subscribe({
				next: () => {
					this.eventManager.emit('toast', { severity: 'success', summary: 'Utilisateur modifié' });

					this.abonneService.refreshCurrentAbonneData().subscribe(() => {
						this.stateService.go('.', {uti_id: this.utilisateur.uti_id}, {reload: '^'});
					});
				}
			})
		}
	}

	resetPassword() {
		this.confirmationService.confirm({
				defaultFocus: 'reject',
				message: this.translationService.instant('auth.password_reinit_explanation'),
				acceptLabel: 'Envoyer',
				rejectLabel: 'Annuler',
				header: 'Confirmation',
				accept: () => {
					this.reinitialisationMotDePasseLoading = true;
						this.authService.reinitialisationMotDePasse(this.utilisateur.uti_email)
						.subscribe(
							(response: any) => {
								this.eventManager.emit('toast', {
									severity: 'success',
									summary: this.translationService.instant('auth.password_reinit_sending_success_summary'),
									detail:  this.translationService.instant('auth.password_reinit_sending_success_detail') + ' ' + this.utilisateur.uti_email,
								});
							}
						)
						.add(() => { this.reinitialisationMotDePasseLoading = false; });
					},
				}
		);
	}
}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		CiviliteSelectorModule,
		ToggleButtonModule,
		DividerModule,
	],
	exports: [UtilisateurGeneralTabComponent],
	declarations: [UtilisateurGeneralTabComponent]
})
export class UtilisateurGeneralTabModule { }
