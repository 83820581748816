import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { TableModule } from '@app/primeng-overrides/table';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { LogoUploaderModule } from '@app/abonne/abonne-logo-uploader';
import { Abonne } from '@app/abonne/abonne.model';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';


@Component({
	templateUrl: `abonne-logo-tab.component.html`
})
export class AbonneLogoTabComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input() abonne: Abonne;

	isCreation = false;
	canEdit = false;
	editMode = false;

	constructor(private stateService: StateService) {}

	ngOnInit(): void {

	}

	onSuccessfullUpload(){
		this.stateService.go('.', undefined, {reload: '^'});
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		TableModule,
		ToolbarModule,
		PanelModule,
		DividerModule,
		LogoUploaderModule,
	],
	exports: [AbonneLogoTabComponent],
	declarations: [AbonneLogoTabComponent]
})
export class AbonneLogoTabModule { }
