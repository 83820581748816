import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs';

import type { TreeNode } from 'primeng/api/treenode.d.ts';

import { Diagnostic, RubriqueDiagnostic } from '@app/diagnostic/diagnostic.model';
import { Famille, FamilleAbonne, Nomenclature, Rubrique, Theme } from '@app/nomenclature/nomenclature.model';
import { Article, Texte } from '@app/texte/texte.model';
import { EventManagerService, IEventListener } from '@global/event-manager.service';

import { ExpiringCache } from '@app/_helpers/cache';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@app/_helpers/prepare-query-params';
import {
	clone,
	convertDateFieldsToDate,
	convertDateFieldsToString,
	ExtensibleObject,
	normalizeStringForSearch,
	stringSort,
	trimHTML,
	uid
} from '@helpers/utils';

const CACHE_NOMENCLATURE_TIMEOUT = 30_000;

export type NomenclatureNodeData =
	| Famille & {key: string, actif: boolean, typeNomenclature: 'famille'}
	| Theme & {key: string, actif: boolean, typeNomenclature: 'theme'}
	| Rubrique & {key: string, actif: boolean, typeNomenclature: 'rubrique'}

export type NonIncludedNomenclatureNodeData =
	| Famille & {key: string, actif: boolean, disabledInDiagnostic?: boolean, typeNomenclature: 'famille'}
	| Theme & {key: string, actif: boolean, disabledInDiagnostic?: boolean, typeNomenclature: 'theme'}
	| Rubrique & {key: string, actif: boolean, disabledInDiagnostic?: boolean, typeNomenclature: 'rubrique'}

export interface NomenclatureFilters {
	fam: string | null;
	thm: string | null;
	rub: string | null
}

export type DiagnosticNodeData =
	| Famille & {key: string, actif: boolean, typeNomenclature: 'famille'}
	| Theme & {key: string, actif: boolean, typeNomenclature: 'theme'}
	| RubriqueDiagnostic & {key: string, actif: boolean, typeNomenclature: 'rubrique'}

export type NomenclatureNodeType = 'famille' | 'theme' | 'rubrique'

@Injectable({ providedIn: 'root' })
export class NomenclatureService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private cacheNomenclature = new ExpiringCache<Nomenclature>(CACHE_NOMENCLATURE_TIMEOUT);
	private cacheAvailableNomenclature = new Map<number /*abo_id*/, ExpiringCache<Nomenclature>>();

	constructor(
		private eventManager: EventManagerService,
		private http: HttpClient,
	) {

	}

	ngOnDestroy(): void {
	}

	public getCacheNomenclature() {
		if (this.cacheNomenclature.isNotEmpty()) {
			return this.cacheNomenclature.currentCopyAsObservable();
		}
		return this.getNomenclature()
		.pipe(map((nomenclature: Nomenclature) => {

			this.cacheNomenclature.update(nomenclature);
			return structuredClone(nomenclature);
		}));
	}

	private getNomenclature(params?: any) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<Nomenclature>(`/nomenclature`, tmpParams);
	}

	public getCacheAvailableNomenclature(abo_id: number) {
		let cache = this.cacheAvailableNomenclature.get(abo_id);

		if (cache == undefined) {
			cache = new ExpiringCache(CACHE_NOMENCLATURE_TIMEOUT);
			this.cacheAvailableNomenclature.set(abo_id, cache);
		}

		if (cache.isNotEmpty()) {
			return cache.currentCopyAsObservable();
		}

		return this.getAvailableNomenclature(abo_id)
		.pipe(map((nomenclature: Nomenclature) => {
			cache.update(nomenclature);
			return structuredClone(nomenclature);
		}));
	}

	private getAvailableNomenclature(abo_id: number) {
		return this.http.get<Nomenclature>(`/abonnes/${abo_id}/nomenclature_disponible`);
	}

	// Familles

	public getThemesFamille(fam_id: number, params: ExtensibleObject = {}) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`/familles/${fam_id}/themes`, tmpParams).pipe(
			map(({ themes, total }: any) => {

				return {
					themes: themes as Theme[],
					total
				}
			})
		)
	}

	public prepareFamilleAbonneFromServer(famille: FamilleAbonne) {
		let tmp: FamilleAbonne = clone(famille);
		convertDateFieldsToDate(tmp);
		return tmp;
	}

	public prepareFamilleAbonneForServer(famille: FamilleAbonne) {
		let tmp = structuredClone(famille) as ExtensibleObject;
		convertDateFieldsToString(tmp);
		return tmp;
	}

	public prepareFamilesAbonneFromServer(familles: FamilleAbonne[]) {
		for (let i = 0; i < familles.length ; i++) {
			familles[i] = this.prepareFamilleAbonneFromServer(familles[i]);
		}
		return familles;
	}

	public getFamilles(params: unknown = {}) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`/familles`, tmpParams).pipe(
			map(({ familles, total }: any) => {
				return {familles: familles as Famille[], total: total || 0 as number};
			})
		)
	}

	public getFamille(fam_id: number) {
		return this.http.get<any>(`/familles/${fam_id}`).pipe(
			map(response => response as Famille)
		)
	}

	public createFamille(famille: Famille){
		this.cacheNomenclature.invalidate();
		this.cacheAvailableNomenclature.clear();

		let body: ExtensibleObject = structuredClone(famille);
		delete body.fam_id;

		return this.http.post<any>(`/familles`, body);
	}

	public updateFamille(famille: Famille){
		this.cacheNomenclature.invalidate();
		this.cacheAvailableNomenclature.clear();

		let body: ExtensibleObject = {...famille};
		delete body.hno;

		return this.http.put<any>(`/familles/${famille.fam_id}`, body);
	}

	public deleteFamille(fam_id: number){
		this.cacheNomenclature.invalidate();
		this.cacheAvailableNomenclature.clear();

		return this.http.delete<any>(`/familles/${fam_id}`);
	}


	public linkFamilleToAbonne(fam_id: number, abo_id: number){
		this.cacheAvailableNomenclature.delete(abo_id);

		return this.http.post<any>(`/abonnes/${abo_id}/familles/${fam_id}`, {});
	}

	public unlinkFamilleFromAbonne(fam_id: number, abo_id: number){
		this.cacheAvailableNomenclature.delete(abo_id);

		return this.http.delete<any>(`/abonnes/${abo_id}/familles/${fam_id}`);
	}

	public exportFamilles(params: unknown){
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`/familles/export`, tmpParams);
	}

	// Thèmes

	public getTheme(fam_id: number, thm_id: number) {
		return this.http.get<Theme>(`/familles/${fam_id}/themes/${thm_id}`);
	}

	public createTheme(theme: Theme){
		this.cacheNomenclature.invalidate();
		this.cacheAvailableNomenclature.clear();

		return this.http.post<any>(`/familles/${theme.fam_id}/themes`, theme);
	}

	public updateTheme(actual_fam_id: number, theme: Theme){
		this.cacheNomenclature.invalidate();
		this.cacheAvailableNomenclature.clear();

		let body: ExtensibleObject = {...theme};
		delete body.hno;

		return this.http.put<any>(`/familles/${actual_fam_id}/themes/${theme.thm_id}`, body);
	}

	public deleteTheme(fam_id: number, thm_id: number){
		this.cacheNomenclature.invalidate();
		this.cacheAvailableNomenclature.clear();

		return this.http.delete<any>(`/familles/${fam_id}/themes/${thm_id}`);
	}

	// Rubriques

	public createRubrique(fam_id: number, rubrique: Rubrique){
		this.cacheNomenclature.invalidate();
		this.cacheAvailableNomenclature.clear();

		return this.http.post<any>(`/familles/${fam_id}/themes/${rubrique.thm_id}/rubriques`, rubrique);
	}

	public updateRubrique(fam_id: number, actual_thm_id: number, rubrique: Rubrique){
		this.cacheNomenclature.invalidate();
		this.cacheAvailableNomenclature.clear();

		let body: ExtensibleObject = {...rubrique};
		delete body.hno;

		return this.http.put<any>(`/familles/${fam_id}/themes/${actual_thm_id}/rubriques/${rubrique.rub_id}`, body);
	}

	public deleteRubrique(fam_id: number, thm_id: number, rub_id: number){
		this.cacheNomenclature.invalidate();
		this.cacheAvailableNomenclature.clear();

		return this.http.delete<any>(`/familles/${fam_id}/themes/${thm_id}/rubriques/${rub_id}`);
	}

	// Textes

	public prepareTexteFromServer(texte: any) {
		let tmp: Texte = clone(texte);
		convertDateFieldsToDate(tmp);
		return tmp;
	}

	public prepareTexteForServer(texte: Texte) {
		let tmp = structuredClone(texte);
		convertDateFieldsToString(tmp);

		tmp.txt_intitule = trimHTML(tmp.txt_intitule);
		tmp.txt_commentaire = trimHTML(tmp.txt_commentaire);

		// On arrange les art_ordre
		tmp.articles.forEach((article: Article, index: number) => {
			article.art_ordre = index;
			// et on nettoie
			article.art_corps = trimHTML(article.art_corps);
		});

		(tmp as ExtensibleObject).rubriques = texte.rubriques.map((rubrique: Rubrique) => rubrique.rub_id);
		return tmp;
	}

	public prepareTextesFromServer(textes: Texte[]) {
		for (let i = 0; i < textes.length ; i++) {
			textes[i] = this.prepareTexteFromServer(textes[i]);
		}
		return textes;
	}

	public getTextes(params: unknown = {}) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`/textes`, tmpParams).pipe(
			map(({ textes, total }) => {
				let liste = this.prepareTextesFromServer(textes);
				return {textes: liste, total: total || 0 as number};
			})
		)
	}

	public getTexte(txt_id: number) {
		return this.http.get<any>(`/textes/${txt_id}`).pipe(
			map(response => this.prepareTexteFromServer(response))
		)
	}

	public exportTextes(params: unknown = {}) {
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`/textes/export`, tmpParams);
	}

	public createTexte(texte: Texte) {
		let body = this.prepareTexteForServer(texte);

		return this.http.post<any>(`/textes`, body);
	}

	public updateTexte(texte: Texte) {
		let body = this.prepareTexteForServer(texte);

		return this.http.put<any>(`/textes/${texte.txt_id}`, body);
	}

	public deleteTexte(txt_id: number) {
		return this.http.delete<any>(`/textes/${txt_id}`);
	}

	public publishTexte(txt_id: number) {
		return this.http.put(`/textes/${txt_id}/publication`, {txt_brouillon: false});
	}

}

export function makeNomenclatureTreeNodes(
	nomenclature: Nomenclature,
	showInactive: boolean | null,
	expandedNodeKeys?: Set<string>,
) {
	let tmp = makeTreeNodes(nomenclature, expandedNodeKeys);

	// nettoyage et transformation en array
	tmp = tmp.filter((fam: TreeNode<NomenclatureNodeData>) => fam != null );

	tmp.forEach((fam: TreeNode<NomenclatureNodeData>) => {
		fam.children = fam.children!.filter((thm: TreeNode<NomenclatureNodeData>) => thm != null );

		fam.children.forEach((thm: TreeNode<NomenclatureNodeData>) => {
			// On conserve forcément toutes les rubriques si on est en mode 'inactif seulement' et que le thème est inactif
			let keepAllChildren = showInactive === true && !isActiveNode(thm);

			if (!keepAllChildren) {
				thm.children = filterActiveNodes(showInactive, thm.children!, 'rub');
			}
			stringSort(thm.children!, 'label');
		});

		fam.children = filterActiveNodes(showInactive, fam.children, 'thm');
		stringSort(fam.children, 'label');
	});

	tmp = filterActiveNodes(showInactive, tmp, 'fam');
	stringSort(tmp, 'label');

	return tmp;
}

export function makeNonIncludedNomenclatureTreeNodes(
	nomenclature: Nomenclature,
	diagnostic: Diagnostic,
	expandedNodeKeys?: Set<string>
) {
	let tmp = makeTreeNodes(nomenclature, expandedNodeKeys) as TreeNode<NonIncludedNomenclatureNodeData>[];

	const getIncludedRubrique =
		(rub_id: number) => diagnostic.rubriques.find((includedRubrique: RubriqueDiagnostic) => includedRubrique.rub_id == rub_id);

	// nettoyage et transformation en array
	tmp = tmp.filter((fam: TreeNode<NonIncludedNomenclatureNodeData>) => fam != null );

	tmp.forEach((fam: TreeNode<NomenclatureNodeData>) => {
		fam.children = fam.children!.filter((thm: TreeNode<NomenclatureNodeData>) => thm != null );

		fam.children.forEach((thm: TreeNode<NonIncludedNomenclatureNodeData>) => {
			// On exclut les rubriques déjà incluses qui sont actives
			thm.children = thm.children!.filter((rubriqueNode: TreeNode<NonIncludedNomenclatureNodeData>) => {
				let rubriqueDiag = getIncludedRubrique((rubriqueNode.data as Rubrique).rub_id);
				let isIncludedAndActive = rubriqueDiag != undefined && rubriqueDiag.rdi_actif;

				let isIncludedAndInactive = rubriqueDiag != undefined && !rubriqueDiag.rdi_actif;
				if (isIncludedAndInactive) {
					rubriqueNode.styleClass += ' inactive-tree-node';
					rubriqueNode.data!.disabledInDiagnostic = true;
				}

				return !isIncludedAndActive;
			});

			stringSort(thm.children!, 'label');
		});

		fam.children = fam.children.filter((thm: TreeNode) => thm.children?.length);
		stringSort(fam.children, 'label');
	});

	tmp = tmp.filter((fam: TreeNode) => fam.children?.length);
	stringSort(tmp, 'label');

	return tmp;
}


export function makeDiagnosticTreeNodes(
	nomenclature: Diagnostic,
	showDisabled: boolean | null,
	expandedNodeKeys?: Set<string>
) {
	let tmp = makeTreeNodes(nomenclature, expandedNodeKeys) as TreeNode<DiagnosticNodeData>[];

	// nettoyage et transformation en array
	tmp = tmp.filter((fam: TreeNode<DiagnosticNodeData>) => fam != null );

	tmp.forEach((fam: TreeNode<NomenclatureNodeData>) => {
		fam.children = fam.children!.filter((thm: TreeNode<NomenclatureNodeData>) => thm != null );

		fam.children.forEach((thm: TreeNode<DiagnosticNodeData>) => {
			if (showDisabled !== null) {
				thm.children = thm.children!.filter((rubrique: TreeNode<DiagnosticNodeData>) => {
					let rdi_actif = (rubrique.data as RubriqueDiagnostic).rdi_actif;
					if (showDisabled === true) {
						return !rdi_actif;
					}

					if (showDisabled === false) {
						return rdi_actif;
					}

					return false;
				});
			}

			stringSort(thm.children!, 'label');
		});

		fam.children = fam.children.filter((thm: TreeNode) => thm.children?.length);
		stringSort(fam.children, 'label');
	});

	tmp = tmp.filter((fam: TreeNode) => fam.children?.length);
	stringSort(tmp, 'label');

	return tmp;
}

export function areAllRubriquesIncludedInDiagnostic(nomenclature: Nomenclature, diagnostic: Diagnostic) {
	return nomenclature.rubriques.every((rubrique: Rubrique) => diagnostic.rubriques.find(rub => rub.rub_id == rubrique.rub_id));
}

function makeTreeNodes(nomenclature: Nomenclature, expandedNodeKeys = new Set<string>()){
	let tmp: TreeNode<NomenclatureNodeData>[] = [];

	// Création des noeuds des familles
	for (let famille of nomenclature.familles) {
		if (tmp[famille.fam_id] == undefined) {
			let key = `fam-${famille.fam_id}`;
			let data: NomenclatureNodeData = {
				...famille,
				key: key,
				typeNomenclature: 'famille',
				actif: famille.fam_actif,
			};

			let familleNode = {
				label: `${famille.fam_nom}`, // (${famille.fam_id})`,
				key: key,
				children: [],
				expanded: expandedNodeKeys.has(key),
				leaf: false,
				data: data,
				typeNomenclature: data.typeNomenclature,
			};
			tmp[famille.fam_id] = familleNode;
		}
	}

	// Création des noeuds des thèmes
	for (let theme of nomenclature.themes) {

		let familleNode = tmp[theme.fam_id];
		if (familleNode == undefined) {
			continue; // Le filtre ne prend pas en compte la famille
		}
		let famille = familleNode.data as Famille;

		if (familleNode.children![theme.thm_id] == undefined) {
			let key = `thm-${theme.thm_id}`;
			let data: NomenclatureNodeData = {
				...theme,
				...famille, // on ajoute la famille, ainsi, le thème a toutes les infos
				key: key,
				typeNomenclature: 'theme',
				actif: theme.thm_actif
			};

			let themeNode = {
				label: `${theme.thm_nom}`, // (${theme.thm_id})`,
				key: key,
				children: [],
				expanded: expandedNodeKeys.has(key),
				leaf: false,
				data: data,
				typeNomenclature: data.typeNomenclature,
				styleClass: famille.fam_actif ? '': 'inactive-tree-node'
			}
			familleNode.children![theme.thm_id] = themeNode;
		}
	}

	// Création des noeuds des rubriques
	for (let rubrique of nomenclature.rubriques) {
		let familleNode = tmp[rubrique.fam_id];
		if (familleNode == undefined) {
			continue; // Le filtre ne prend pas en compte la famille
		}
		let famille = familleNode.data as Famille;

		let themeNode = familleNode.children![rubrique.thm_id];
		if (themeNode == undefined) {
			continue; // Le filtre ne prend pas en compte le thème
		}
		let theme = themeNode.data as Theme;

		let key = `rub-${rubrique.rub_id}`;
		let data: NomenclatureNodeData = {
			...rubrique,
			...theme, // on ajoute le thème, ainsi, la rubrique a toutes les infos
			...famille, // on ajoute la famille, ainsi, la rubrique a toutes les infos
			key: key,
			typeNomenclature: 'rubrique',
			actif: rubrique.rub_actif
		};

		let rubriqueNode = {
			label: `${rubrique.rub_nom}`, // (${rubrique.rub_id})`,
			key: key,
			children: [],
			expanded: expandedNodeKeys.has(key),
			leaf: true,
			data: data,
			typeNomenclature: data.typeNomenclature,
			styleClass: famille.fam_actif && theme.thm_actif ? '' : 'inactive-tree-node'
		}
		themeNode.children?.push(rubriqueNode);
	}
	return tmp;
}

function filterActiveNodes(showInactive: boolean | null, treeNodes: TreeNode[], attr: string) {
	if (showInactive === null) return treeNodes;
	return treeNodes.filter((item: TreeNode) => {
		if (showInactive === true) {
			return !item.data[`${attr}_actif`] || !!item.children?.length;
		}

		if (showInactive === false) {
			return item.data[`${attr}_actif`];
		}

		return false;
	});
}

function isActiveNode(node: TreeNode<NomenclatureNodeData>) {
	switch (node.data?.typeNomenclature) {
	case 'famille':
		return node.data.fam_actif;
	case 'theme':
		return node.data.thm_actif;
	case 'rubrique':
		return node.data.rub_actif;
	}
	return true;
}

export function getNodeName(node: NomenclatureNodeData) {
	switch (node.typeNomenclature) {
	case 'famille':
		return node.fam_nom;
	case 'theme':
		return node.thm_nom;
	case 'rubrique':
		return node.rub_nom;
	}
}

export function disableNode(node: NomenclatureNodeData | Famille | Theme | Rubrique) {
	if ('rub_id' in node) {
		node.rub_actif = false;
	}
	else if ('thm_id' in node) {
		node.thm_actif = false;
	}
	else {
		node.fam_actif = false;
	}

	if ('actif' in node) {
		node.actif = false;
	}
}

export function getFilteredNomenclature<T extends Nomenclature>(unfilteredNomenclature: T, filters: NomenclatureFilters): T {

	// Si il n'y aucun filtre aucun noeud n'est exclus, même si il n'a pas d'enfants.
	if (Object.values(filters).every(filter => filter == null)) {
		return unfilteredNomenclature;
	}

	let filtered: T = {
		...unfilteredNomenclature,
		familles: filterLevel(unfilteredNomenclature.familles, filters.fam, 'fam'),
		themes: filterLevel(unfilteredNomenclature.themes, filters.thm, 'thm'),
		rubriques: filterLevel(unfilteredNomenclature.rubriques, filters.rub, 'rub')
	};

	if (filters.rub != null) {
		// Si on cherche des rubriques spécifiques on exclut les thèmes ne contenant aucune des rubriques trouvées
		filtered.themes = filtered.themes.filter((theme: Theme) => {
			return filtered.rubriques.some((rubrique: Rubrique) => rubrique.thm_id == theme.thm_id)
		});

		// Exclusion des familles ne contenant aucun thème restant
		filtered.familles = filtered.familles.filter((famille: Famille) => {
			return filtered.themes.some((theme: Theme) => theme.fam_id == famille.fam_id)
		});
	}
	else if (filters.thm != null) {
		// Si on cherche des thème spécifiques on exclut les familles ne contenant aucun des thèmes trouvés
		filtered.familles = filtered.familles.filter((famille: Famille) => {
			return filtered.themes.some((theme: Theme) => theme.fam_id == famille.fam_id)
		});
	}

	return filtered;
}

function filterLevel<T extends  Famille[] | Theme[] | Rubrique[]>(data: T, search: string|null|undefined, attr: string): T {
	if (search == undefined || search.trim() == '') return data;

	search = search.trim();
	const regex = new RegExp(normalizeStringForSearch(search)!, 'i');

	return data.filter((item: any) => {
		const normalized = normalizeStringForSearch(item[`${attr}_nom`])
		return normalized.match(regex);
	}) as T;
}

export function walkTree<T>(hierarchy: TreeNode<T>[], callbackFn: (node: TreeNode<T>) => void | 'prune') {
	for (let node of hierarchy) {
		if (callbackFn(node) != 'prune') {
			walkTree(node.children ?? [], callbackFn);
		}
	}
}

export function collapseTree(hierarchy: TreeNode[]){
	walkTree(hierarchy, (node: TreeNode<NomenclatureNodeData>) => {
		switch (node.data?.typeNomenclature) {
		case 'famille':
			node.expanded = false;
			break;
		case 'theme':
			node.expanded = false;
			// On évite de visiter les rubriques car ce n'est pas utile et elles peuvent être très nombreuses
			return 'prune';
		}
		return;
	});
}

export function expandTree(hierarchy: TreeNode[], maxLevel?: 'famille' | 'theme'){
	walkTree(hierarchy, (node: TreeNode<NomenclatureNodeData>) => {
		switch (node.data?.typeNomenclature) {
		case 'famille':
			node.expanded = true;

			break;
		case 'theme':
			node.expanded = maxLevel == 'theme';
			// On évite de visiter les rubriques car ce n'est pas utile et elles peuvent être très nombreuses
			return 'prune';
		}
		return;
	});
}

export function expandTreeBasedOnFilters(hierarchy: TreeNode[], filters: NomenclatureFilters) {
	// Si on cherche (entre autres) une rubrique on déroule jusqu'aux rubriques
	if (filters.rub) {
		expandTree(hierarchy, 'theme');
	}
	// Si on cherche un thème sans chercher une rubrique on déroule jusqu'aux thèmes
	else if (filters.thm) {
		expandTree(hierarchy, 'famille');
	}
}
