import { Ng2StateDeclaration, Transition } from '@uirouter/angular';


import { MainContentComponent } from '@app/main-content';
import { AbonneComponent } from '@app/abonne/abonne.component';
import { AppComponent } from '@app/app.component';
import { AuthComponent } from '@app/auth/auth.component';
import { ChangePasswordComponent, ChangePasswordConnectedComponent } from '@app/auth/change-password';
import { LoginComponent } from '@app/auth/login';
import { Login2faComponent } from '@app/auth/login-2fa';
import { ResetPasswordComponent } from '@app/auth/reset-password';
import { ListeAbonnesComponent } from '@app/liste-abonnes';
import { NomenclatureComponent } from '@app/nomenclature/nomenclature';
import { ListeSitesComponent } from '@app/liste-sites';
import { ListeUtilisateursAdminComponent } from '@app/liste-utilisateurs-admin';
import { ListeUtilisateursAbonneComponent } from '@app/liste-utilisateurs-abonne';
import { ListeTextesComponent } from '@app/liste-textes';
import { TexteComponent } from '@app/texte/texte';
import { RootComponent } from '@app/root';
import { SelectionAbonneCourantComponent } from '@app/selection-abonne-courant';
import { UtilisateurComponent } from '@app/utilisateur/utilisateur/utilisateur.component';
import * as resolves from '@global/router.resolves';
import { ServiceUnavailableComponent } from '@global/service-unavailable.component';
import { AbonneCreationComponent } from './abonne/abonne-creation/abonne-creation.component';
import { AbonneAbonnementComponent } from './abonne/abonne-abonnement/abonne-abonnement.component';
import { SelectionSitesRecolementComponent } from './selection-sites-recolement';
import { SelectionSitesEvaluationComponent } from './selection-sites-evaluation';
import { ListeTextesConformiteComponent } from './liste-textes-conformite';
import { TableauDeBordComponent } from './tableau-de-bord/tableau-de-bord';
import { AbonneFamillesTabComponent } from './abonne/abonne-familles-tab';
import { AbonneInfosTabComponent } from './abonne/abonne-infos-tab/abonne-infos-tab.component';
import { AbonneLogoTabComponent } from './abonne/abonne-logo-tab/abonne-logo-tab.component';
import { AbonneSecurityTabComponent } from './abonne/abonne-security-tab';
import { AbonneService } from './abonne/abonne.service';
import { SiteComponent } from './site/site';
import { UtilisateurAbonnesTabComponent } from './utilisateur/utilisateur-abonnes-tab/utilisateur-abonnes-tab.component';
import { UtilisateurAccesTabComponent } from './utilisateur/utilisateur-acces-tab/utilisateur-acces-tab.component';
import { UtilisateurCreationComponent } from './utilisateur/utilisateur-creation/utilisateur-creation.component';
import { UtilisateurGeneralTabComponent } from './utilisateur/utilisateur-general-tab/utilisateur-general-tab.component';
import { SiteInfosTabComponent } from './site/site-infos-tab';
import { DiagnosticComponent } from './diagnostic';
import { Site } from './site/site.model';
import { Abonne } from './abonne/abonne.model';

export const APP_STATES: Ng2StateDeclaration[] = [
	{
		name: 'app',
		component: AppComponent,
		redirectTo: 'root',
		data: {
			parametresGuard: true,
		},
	},

	{
		parent: 'app',
		name: 'auth',
		url: '/auth',
		redirectTo: 'auth.login',
		component: AuthComponent,
		resolve: [
			resolves.returnToResolve,
		],
	},
	{
		name: 'auth.login',
		url: '/login',
		component: LoginComponent,
		data: { forbiddenWhenAuth: true },
		breadcrumb: () => 'auth.login_form_title'
	},
	{
		name: 'auth.reset_password',
		url: '/forgotten-password?{email:string}',
		params: {
			email: { dynamic: true, squash: true },
		},
		component: ResetPasswordComponent,
		breadcrumb: () => 'auth.password_reset_form_title'
	},
	{
		name: 'auth.change_password',
		url: '/password-change/:resetPasswordToken',
		component: ChangePasswordComponent,
		breadcrumb: () => 'auth.password_change_form_title'
	},
	{
		name: 'auth.challenge_2fa',
		url: '/2fa',
		data: {
			requiresAuth: true,
			forbiddenWhen2faPassed: true,
		},
		component: Login2faComponent,
		breadcrumb: () => 'auth.login_2fa_form_title'
	},

	{
		parent: 'app',
		name: 'service_unavailable',
		url: '/error?{error:string}',
		component: ServiceUnavailableComponent,
		breadcrumb: () => 'availability.service_momentarily_unavailable'
	},

	{
		parent: 'app',
		name: 'root',
		component: RootComponent,
		redirectTo: 'main',
		data: {
			requiresAuth: true,
			auth2faPassed: true,
		},
		resolve: [
			resolves.getMeResolve,
			resolves.getListesGeneriquesResolve
		],
		resolvePolicy: { when: 'EAGER', async: 'WAIT' }, // we want to wait for the resolves to complete before going in the substates
	},

	{
		parent: 'root',
		name: 'main',
		url: '/app',
		views: {
			'rootContent@root': {
				component: MainContentComponent,
			},
		},
		resolve: [
			resolves.redirectAbonne,
			resolves.initializeAppMenuResolve,
		],
	},

	{
		name: 'root.change_password',
		url: '/password',
		views: {
			'rootContent@root': {
				component: ChangePasswordConnectedComponent,
			},
		},
		resolve: [
			resolves.returnToResolve,
		],
		data: {
			forbiddenWhenPasswordNotExpired: true,
		},
		breadcrumb: () => 'auth.password_change_form_title'
	},

	// Sélection abonné
	{
		parent: 'root',
		name: 'selection-abonne-courant',
		url: '/subscriber-selection',
		views: {
			'rootContent@root': {
				component: SelectionAbonneCourantComponent,
			},
		},
		breadcrumb: () => 'abonnes.current_abonne_selector'
	},

	// Admin
	{
		parent: 'main',
		name: 'admin',
		url: '/admin',
		data: {
			acces: ['admin']
		},
		resolve: [{
			token: 'isAdminView',
			deps: [],
			resolveFn: () => true
		}],
		breadcrumb: () => 'menu.administration'
	},

	// Gesion Abonnés
	{
		name: 'admin.abonnes',
		url: '/subscribers',
		views: {
			'mainContent@main': {
				component: ListeAbonnesComponent,
			},
		},
		breadcrumb: () => 'abonnes.abonnes_list_page_title'
	},

	{
		name: 'admin.abonnes.create',
		url: '/creation',
		views: {
			'mainContent@main': {
				component: AbonneCreationComponent,
			},
		},
		breadcrumb: () => 'abonnes.new_abonne'
	},

	{
		name: 'admin.abonnes.single',
		url: '/{abo_id:int}',
		redirectTo: 'admin.abonnes.single.informations',
		views: {
			'mainContent@main': {
				component: AbonneComponent,
			},
		},
		resolve: [
			{
				token: 'abonne',
				deps: [AbonneService, Transition],
				resolveFn: (abonneService: AbonneService, transition: Transition) => {
					const abo_id = transition.params().abo_id;
					return abonneService.getAbonne(abo_id).toPromise();
				}
			}
		],
		breadcrumb: (trans: Transition) => {
			const abonne = trans.injector().get('abonne');
			return `${abonne.abo_nom_abonne}`;
		}
	},

	{
		name: 'admin.abonnes.single.informations',
		url: '/informations',
		views: {
			'abonneTabContent': {
				component: AbonneInfosTabComponent
			}
		}
	},

	{
		name: 'admin.abonnes.single.logo',
		url: '/logo',
		views: {
			'abonneTabContent': {
				component: AbonneLogoTabComponent
			}
		},
		breadcrumb: () => 'abonnes.logo_tab_name'
	},

	{
		name: 'admin.abonnes.single.familles',
		url: '/familles',
		views: {
			'abonneTabContent': {
				component: AbonneFamillesTabComponent
			}
		},
		breadcrumb: () => 'abonnes.families_tab_name'
	},


	{
		name: 'admin.abonnes.single.securite',
		url: '/security',
		views: {
			'abonneTabContent': {
				component: AbonneSecurityTabComponent
			}
		},
		breadcrumb: () => 'abonnes.security_tab_name'
	},

	// Utilisateurs (vue admin)

	{
		name: 'admin.utilisateurs',
		url: '/users?{selected_abo_id:int}',
		params: {
			'selected_abo_id': { dynamic: true, squash: true },
		},
		views: {
			'mainContent@main': {
				component: ListeUtilisateursAdminComponent,
			},
		},
		resolve: [
			{
				token: 'selected_abo_id',
				deps: [Transition],
				resolveFn: (transition: Transition) => {
					return transition.params().selected_abo_id;
				}
			},
		],
		breadcrumb: () => 'menu.users'
	},

	{
		name: 'admin.utilisateurs.create',
		url: '/creation',
		views: {
			'mainContent@main': {
				component: UtilisateurCreationComponent,
			},
		},
		breadcrumb: () => 'users.new_user'
	},

	{
		name: 'admin.utilisateurs.single',
		redirectTo: 'admin.utilisateurs.single.general',
		url: '/{uti_id:int}',
		views: {
			'mainContent@main': {
				component: UtilisateurComponent,
			},
		},
		resolve: [
			resolves.getUtilisateurResolve
		],
		breadcrumb: (trans: Transition) => {
			const utilisateur = trans.injector().get('utilisateur');
			return `${utilisateur.label}`;
		}
	},


	{
		name: 'admin.utilisateurs.single.general',
		url: '/general',
		views: {
			'utilisateurTabContent@admin.utilisateurs.single': {
				component: UtilisateurGeneralTabComponent
			}
		}
	},

	{
		name: 'admin.utilisateurs.single.abonnes',
		url: '/linked-subscribers',
		views: {
			'utilisateurTabContent@admin.utilisateurs.single': {
				component: UtilisateurAbonnesTabComponent
			}
		},
		breadcrumb: () => 'users.linked_abonnes_tab_name'
	},

	// Admin nomenclature
	{
		name: 'admin.nomenclature',
		url: '/nomenclature',
		views: {
			'mainContent@main': {
				component: NomenclatureComponent,
			},
		},
		breadcrumb: () => 'nomenclature.admin_page_title'
	},

	// Admin textes
	{
		name: 'admin.textes',
		url: '/texts',
		views: {
			'mainContent@main': {
				component: ListeTextesComponent,
			},
		},
		breadcrumb: () => 'textes.admin_page_title'
	},

	{
		name: 'admin.textes.create',
		url: '/creation',
		views: {
			'mainContent@main': {
				component: TexteComponent,
			},
		},
		breadcrumb: () => 'textes.new_text'
	},

	{
		name: 'admin.textes.single',
		url: '/{txt_id:int}',
		resolve: [
			{
				token: 'txt_id',
				deps: [Transition],
				resolveFn: (transition: Transition) => {
					return transition.params().txt_id;
				}
			},
			resolves.getTexteResolve
		],
		views: {
			'mainContent@main': {
				component: TexteComponent,
			},
		},
		breadcrumb: (trans: Transition) => {
			const texte = trans.injector().get('texte');
			return `${texte.txt_reference}`;
		}
	},

	// Abonné courant
	{
		parent: 'main',
		name: 'abonne-courant',
		url: '/subscriber/{curr_abo_id:int}',
		redirectTo: 'abonne-courant.tableau-de-bord',
		resolve: [
			{
				token: 'curr_abo_id',
				deps: [Transition],
				resolveFn: (transition: Transition) => {
					return transition.params().curr_abo_id;
				}
			},
			resolves.getAbonneCourantResolve,
		],
		breadcrumb: (trans: Transition) => {
			const abonne_courant = trans.injector().get('abonne_courant');
			return `${abonne_courant.abo_nom_abonne}`;
		}
	},

	// Tableau de bord
	{
		name: 'abonne-courant.tableau-de-bord',
		url: '/compliance-summary',
		resolve: [
			resolves.getStatsConformiteResolve
		],
		data: {
			hideBreadcrumbs: true
		},
		views: {
			'mainContent@main': {
				component: TableauDeBordComponent
			}
		},
	},


	// Recolements
	{
		name: 'abonne-courant.selection-sites-recolements',
		url: '/applicability-reviews-site-selection',
		// Auto redirection si un seul site
		redirectTo: async (transition: Transition) => {
			let abonne = await transition.injector().getAsync('abonne_courant') as Abonne
			if (abonne.sites.length == 1) {
				return {
					state: 'abonne-courant.recolements',
					params: {
						...transition.params(),
						sit_ids: [abonne.sites[0].sit_id]
					}
				};
			}
			return undefined; // pas de redirection
		},
		views: {
			'mainContent@main': {
				component: SelectionSitesRecolementComponent
			}
		},
		breadcrumb: () => 'menu.applicability_reviews'
	},

	{
		name: 'abonne-courant.recolements',
		url: '/applicability-reviews/{sit_ids:string}?{selected_sta_code:string}',
		params: {
			sit_ids: { array: true },
			selected_sta_code: { dynamic: true, squash: true },
		},
		resolve: [
			resolves.getSitesResolve,
			{
				token: 'selected_sta_code',
				deps: [Transition],
				resolveFn: (transition: Transition) => {
					return transition.params().selected_sta_code;
				}
			},
		],
		views: {
			'mainContent@main': {
				component: ListeTextesConformiteComponent
			}
		},
		breadcrumb: () => 'menu.applicability_reviews'
	},

	// Evaluations
	{
		name: 'abonne-courant.selection-sites-evaluations',
		url: '/evaluation-site-selection',
		// Auto redirection si un seul site
		redirectTo: async (transition: Transition) => {
			let abonne = await transition.injector().getAsync('abonne_courant') as Abonne
			if (abonne.sites.length == 1) {
				return {
					state: 'abonne-courant.evaluations',
					params: {
						...transition.params(),
						sit_ids: [abonne.sites[0].sit_id]
					}
				};
			}
			return undefined; // pas de redirection
		},
		views: {
			'mainContent@main': {
				component: SelectionSitesEvaluationComponent
			}
		},
		breadcrumb: () => 'menu.assessments'
	},

	{
		name: 'abonne-courant.evaluations',
		url: '/assessments/{sit_ids:string}?{selected_eva_code:string}',
		params: {
			sit_ids: { array: true },
			selected_eva_code: { dynamic: true, squash: true },
		},
		resolve: [
			resolves.getSitesResolve,
			{
				token: 'etapeConformite',
				deps: [],
				resolveFn(){
					return 'evaluations'
				}
			},
			{
				token: 'selected_eva_code',
				deps: [Transition],
				resolveFn: (transition: Transition) => {
					return transition.params().selected_eva_code;
				}
			},
		],
		views: {
			'mainContent@main': {
				component: ListeTextesConformiteComponent
			}
		},
		breadcrumb: () => 'menu.assessments'
	},


	// Abonnement
	{
		name: 'abonne-courant.abonnement',
		url: '/subscription',
		data: {
			acces: ['gestionnaire_abonnement']
		},
		views: {
			'mainContent@main': {
				component: AbonneAbonnementComponent
			}
		},
		breadcrumb: () => 'abonnes.abonnement_label'
	},

	// Utilisateurs de l'abonné courant
	{
		name: 'abonne-courant.utilisateurs',
		url: '/users',
		data: {
			acces: ['gestionnaire_abonnement']
		},
		views: {
			'mainContent@main': {
				component: ListeUtilisateursAbonneComponent,
			},
		},
		breadcrumb: () => 'menu.users'
	},

	{
		name: 'abonne-courant.utilisateurs.single',
		redirectTo: 'abonne-courant.utilisateurs.single.general',
		url: '/{uti_id:int}',
		views: {
			'mainContent@main': {
				component: UtilisateurComponent,
			},
		},
		resolve: [
			resolves.getUtilisateurResolve
		],
		breadcrumb: (trans: Transition) => {
			const utilisateur = trans.injector().get('utilisateur');
			return `${utilisateur.label}`;
		}
	},

	{
		name: 'abonne-courant.utilisateurs.create',
		url: '/creation',
		data: {
			acces: ['admin']
		},
		views: {
			'mainContent@main': {
				component: UtilisateurCreationComponent,
			},
		},
		resolve: [
			{
				token: 'linkToAbonneAtCreation',
				resolveFn: () => true
			}
		],
		breadcrumb: () => 'users.new_user'
	},

	{
		name: 'abonne-courant.utilisateurs.single.general',
		url: '/general',
		views: {
			'utilisateurTabContent@abonne-courant.utilisateurs.single': {
				component: UtilisateurGeneralTabComponent
			}
		}
	},

	{
		name: 'abonne-courant.utilisateurs.single.abonnes',
		url: '/linked-subscribers',
		views: {
			'utilisateurTabContent@abonne-courant.utilisateurs.single': {
				component: UtilisateurAbonnesTabComponent
			}
		},
		breadcrumb: () => 'users.linked_abonnes_tab_name'
	},

	{
		name: 'abonne-courant.utilisateurs.single.acces',
		url: '/access',
		views: {
			'utilisateurTabContent@abonne-courant.utilisateurs.single': {
				component: UtilisateurAccesTabComponent
			}
		},
		breadcrumb: () => 'users.access_tab_name'
	},

	// Sites
	{
		name: 'abonne-courant.sites',
		url: '/sites',
		data: {
			acces: ['gestionnaire_abonnement']
		},
		views: {
			'mainContent@main': {
				component: ListeSitesComponent,
			},
		},
		breadcrumb: () => 'common.sites_label'
	},

	{
		name: 'abonne-courant.sites.create',
		url: '/creation',
		views: {
			'mainContent@main': {
				component: SiteInfosTabComponent,
			},
		},
		breadcrumb: () => 'sites.new_site'
	},

	{
		name: 'abonne-courant.sites.single',
		url: '/{sit_id:int}',
		redirectTo: 'abonne-courant.sites.single.informations',
		resolve: [
			resolves.getSiteResolve
		],
		views: {
			'mainContent@main': {
				component: SiteComponent,
			},
		},
		breadcrumb: (trans: Transition) => {
			const site = trans.injector().get('site');
			return `${site.sit_libelle}`;
		}
	},

	{
		name: 'abonne-courant.sites.single.informations',
		url: '/informations',
		views: {
			'siteTabContent@abonne-courant.sites.single': {
				component: SiteInfosTabComponent,
			},
		},
	},

	{
		name: 'abonne-courant.sites.single.diagnostic',
		url: '/diagnostic',
		views: {
			'siteTabContent@abonne-courant.sites.single': {
				component: DiagnosticComponent,
			},
		},
		breadcrumb: () => 'sites.diagnostic_tab_name'
	}

];
