import { CommonModule } from '@angular/common';
import { Component, ElementRef, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { Datasource, UiScrollModule } from 'ngx-ui-scroll';

import { CalendarModule } from '@app/primeng-overrides/calendar';
import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from '@app/primeng-overrides/menu';
import { TableModule } from '@app/primeng-overrides/table';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DataViewModule } from 'primeng/dataview';
import { DividerModule } from 'primeng/divider';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

import { EventManagerService } from '@app/_global/event-manager.service';
import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { HTMLEditorModule } from '@app/components/html-editor';
import { SiteSelectorModule } from '@app/site/site-selector';
import { SiteService } from '@app/site/site.service';
import { EvaluationSelectorModule } from '@app/texte/evaluation-selector';
import { StatutRecolementSelectorModule } from '@app/texte/statut-recolement-selector';
import { TexteModule } from '@app/texte/texte';
import { Article, ArticleConformite, ArticleEvaluation, ArticleRecolement, EvaluationArticle, EvaluationTexte, RecolementArticle, RecolementTexte, StatutRecolement, Texte, TexteEvaluation, TexteRecolement } from '@app/texte/texte.model';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';

import { default_header_sizes } from '@app/_helpers/quill-customization';
import { normalizeStringForSearch, simpleComparison, startOfDay, uid } from '@helpers/utils';


@Component({
	selector: 'conformite-texte-dialog',
	templateUrl: './conformite-texte-dialog.html'
})
export class ConformiteTexteDialogComponent implements OnInit, OnDestroy {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	@ViewChild('topBar', { static: true, read: ElementRef }) topBar: ElementRef<HTMLDivElement>;

	abonne_courant: Abonne;
	me: Utilisateur;
	siteIds: number[];
	today: Date = startOfDay(new Date());

	etapeConformite: 'evaluations' | 'recolements';
	texte: Texte;

	texteRecolement?: TexteRecolement;
	pristineTexteRecolement?: TexteRecolement;
	effectiveStatutTexteBySite: Record<number, StatutRecolement> = {};
	recolementsArticlesById: Record<number, RecolementArticle[]> = {};
	pristineRecolementsArticlesById: Record<number, RecolementArticle[]> = {};
	forcedRecolementTextesBySiteId: Record<number, boolean> = {};

	bulkStatutsReplacementSiteId?: number
	bulkStatutsReplacement?: StatutRecolement

	texteEvaluation?: TexteEvaluation;
	pristineTexteEvaluation?: TexteEvaluation;
	evaluationsArticlesById: Record<number, EvaluationArticle[]> = {};
	pristineEvaluationsArticlesById: Record<number, EvaluationArticle[]> = {};

	articlesConformites: ArticleConformite[] // articles affichés

	canEdit = false;
	showTexteDetails = false;
	hasChanges = false;

	htmlEditorHeaderSizes: unknown[];
	search: string | null = null;
	sta_code_article?: string;
	expandedArticles: Record<number, boolean> = {};

	datasource = this.createScrollerDataSource();
	filteredArticlesCache: {search?: string, articles: Article[]} = {search: '', articles: []}

	constructor(
		private config: DynamicDialogConfig,
		private stateService: StateService,
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private dialogRef: DynamicDialogRef,
		private translate: TranslateService,
		private abonneService: AbonneService,
		private siteService: SiteService
	) {
		this.abonne_courant = this.abonneService.currentAbonneValue!;

		this.siteIds = config.data.sit_ids;
		this.canEdit = config.data.canEdit;
		this.etapeConformite = config.data.etapeConformite;
		this.texte = config.data.texte;
		this.articlesConformites = config.data.articlesConformites;
		this.me = config.data.me;

		if (this.etapeConformite == 'recolements') {
			this.texteRecolement = structuredClone(config.data.texteConformite);
			this.articlesConformites = this.texteRecolement!.articles;

			for (let article of this.articlesConformites as ArticleRecolement[]) {
				this.recolementsArticlesById[article.art_id] = article.recolements;
			}

			this.pristineRecolementsArticlesById = structuredClone(this.recolementsArticlesById);
			this.pristineTexteRecolement = structuredClone(this.texteRecolement);

			for (let recolement of this.texteRecolement!.recolements) {
				this.effectiveStatutTexteBySite[recolement.sit_id] = recolement.sta_code;
			}
		}
		else {
			let texteEvaluation = structuredClone(config.data.texteConformite);
			this.texteEvaluation = texteEvaluation;
			this.articlesConformites = [];

			// On n'affiche que les articles applicables ou informatifs
			for (let article of texteEvaluation.articles) {
				let articleEvaluation = article as ArticleEvaluation;
				let evaluations = articleEvaluation.evaluations.filter(evaluation => evaluation.sta_code == 'app' || evaluation.sta_code == 'inf');
				if (evaluations.length > 0) {
					this.articlesConformites.push({
						...article,
						evaluations: evaluations
					});
					this.evaluationsArticlesById[article.art_id] = evaluations;
				}
			}

			this.pristineEvaluationsArticlesById = structuredClone(this.evaluationsArticlesById);
			this.pristineTexteEvaluation = structuredClone(this.texteEvaluation);

			for (let evaluation of this.texteEvaluation!.evaluations) {
				this.effectiveStatutTexteBySite[evaluation.sit_id] = evaluation.sta_code;
			}
		}

		this.htmlEditorHeaderSizes = default_header_sizes;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});

		// On agrandit tous les articles courts par défaut et on arrange les art_ordre
		this.texte.articles.forEach((article: Article, index: number) => {
			if (article.art_corps && article.art_corps.length < 100) {
				this.expandedArticles[article.art_id] = true;
			}
			article.art_ordre = index;
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	createScrollerDataSource() {
		return new Datasource({
			settings: {
				startIndex: 0, // Par défaut startIndex est à 1
				padding: 10 // On évite la majorité des incrémentation brutales du défilement
			},
			get: (index: number, count: number, success: Function) => {
				let shownArticleIds = this.articlesConformites.map(article => article.art_id);
				let articles = this.texte.articles.filter(article => shownArticleIds.includes(article.art_id));
				let search = this.search;

				if (this.etapeConformite == 'recolements' && this.sta_code_article) {
					articles = articles.filter((article: Article) => {
						let articleConformite = this.articlesConformites.find(art => art.art_id == article.art_id)

						if (articleConformite == undefined) {
							return false;
						}

						if ('recolements' in articleConformite) {
							return articleConformite.recolements.some((recolement: RecolementArticle) => recolement.sta_code == this.sta_code_article);
						}

						return false;
					});
				}

				if (search && search?.trim() != '') {
					search = search.trim();
					if (this.filteredArticlesCache.search == search) {
						articles = this.filteredArticlesCache.articles;
					}
					else {
						const regex = new RegExp(normalizeStringForSearch(search)!, 'i');

						articles = articles.filter((article: Article) => {
							let normalizedTitle = normalizeStringForSearch(article.art_titre ?? '');
							let normalizedBodyPlaintext = normalizeStringForSearch(article.art_corps_plaintext ?? '');

							return normalizedTitle.match(regex) || normalizedBodyPlaintext.match(regex);
						});

						this.filteredArticlesCache = {search: search, articles: articles};
					}
				}

				let result = articles.slice(index, index + count);
				success(result);
			}
		});
	}

	refreshArticleList() {
		return this.datasource.adapter.reset();
	}

	onRecolementTexteChange(recolementTexte: RecolementTexte) {
		// Cette fonction peut seulement être appelée pour le récolement

		if (this.etapeConformite != 'recolements') {
			return;
		}
		let sit_id = recolementTexte.sit_id;
		let articles = this.articlesConformites as ArticleRecolement[];

		switch (recolementTexte.sta_code) {
			case 'inf':
				// On remet les récolements autres que 'Non-applicables' à 'Inconnu'
				let found = false;
				{
					let articles = this.articlesConformites as ArticleRecolement[];

					for (let article of articles) {
						for (let recolement of article.recolements) {
							if (recolement.sit_id == sit_id && recolement.sta_code == 'nap') {
								found = true;
								recolement.sta_code = 'inc';
							}
						}
					}
				}

				if (found) {
					this.eventManager.emit('toast', {severity: 'success', summary: this.translate.instant('recolements.applicable_articles_has_been_set_to_unknown')});
				}

				this.effectiveStatutTexteBySite[sit_id] = recolementTexte.sta_code;
				this.checkHasChanges();
				break;
			case 'nap':
				// Si tous les récolements du site ont le statut 'Inconnu' ou 'Non applicable', on les passe à 'Non applicable'
				if (articles.every((art: ArticleRecolement) => art.recolements.every(rec => rec.sit_id != sit_id || rec.sta_code == 'inc' || rec.sta_code == 'nap'))) {
					this.forceStatusOfAllArticlesForSite('nap', sit_id);
					this.effectiveStatutTexteBySite[sit_id] = recolementTexte.sta_code;
					this.checkHasChanges();
				}
				// Sinon on demande confirmation pour les passer à 'Non applicable'
				else {
					this.confirmationService.confirm({
						defaultFocus: 'reject',
						message: this.translate.instant('recolements.non_applicable_confirmation_message'),
						accept: () => {
							this.forceStatusOfAllArticlesForSite('nap', sit_id);
							this.effectiveStatutTexteBySite[sit_id] = recolementTexte.sta_code;
							this.checkHasChanges();
						},
						reject: () => {
							// On revient au statut précédent
							recolementTexte.sta_code = this.effectiveStatutTexteBySite[sit_id];
							this.checkHasChanges();
						}
					});
				}
				break;
			default:
				this.effectiveStatutTexteBySite[sit_id] = recolementTexte.sta_code;
				this.checkHasChanges();
		}
	}

	onRecolementArticleChange(recolementArticle: RecolementArticle) {
		let sit_id = recolementArticle.sit_id;
		let recolements = this.texteRecolement!.articles
			.map(article => article.recolements)
			.flat()
			.filter(recArticle => recArticle.sit_id == sit_id);

		this.forcedRecolementTextesBySiteId[sit_id] = false;

		switch (recolementArticle.sta_code) {
		case 'app':
			// Si au moins un article est applicable on met le texte en applicable
			let recolementTexte = this.texteRecolement!.recolements.find(rec => rec.sit_id == sit_id);
			recolementTexte!.sta_code = 'app';
			this.effectiveStatutTexteBySite[sit_id] = 'app';
			this.forcedRecolementTextesBySiteId[sit_id] = true;
			break;
		case 'nap':
			// Si tous les articles sont non-applicables on met le texte en non-applicable
			if (recolements.every((recolement: RecolementArticle) => recolement.sta_code == 'nap')) {
				let recolementTexte = this.texteRecolement!.recolements.find(rec => rec.sit_id == sit_id);
				recolementTexte!.sta_code = 'nap';
				this.effectiveStatutTexteBySite[sit_id] = 'nap';
				this.forcedRecolementTextesBySiteId[sit_id] = true;
			}
			break;
		case 'inf':
			// Si tous les articles sont informatifs on met le texte en informatif
			if (recolements.every((recolement: RecolementArticle) => recolement.sta_code == 'inf')) {
				let recolementTexte = this.texteRecolement!.recolements.find(rec => rec.sit_id == sit_id);
				recolementTexte!.sta_code = 'inf';
				this.effectiveStatutTexteBySite[sit_id] = 'inf';
				this.forcedRecolementTextesBySiteId[sit_id] = true;
			}
			break;
		}

		this.checkHasChanges();
	}

	forceStatusOfAllArticlesForSite(status: StatutRecolement, sit_id: number) {
		if (this.etapeConformite != 'recolements') {
			return;
		}
		let articles = this.articlesConformites as ArticleRecolement[];

		for (let article of articles) {
			for (let recolement of article.recolements) {
				if (recolement.sit_id == sit_id) {
					recolement.sta_code = status;
				}
			}
		}
		this.checkHasChanges();
	}

	replaceUnknownStatusInBulk() {
		if (this.bulkStatutsReplacement == undefined || this.bulkStatutsReplacementSiteId == undefined) {
			return;
		}

		let articles = this.articlesConformites as ArticleRecolement[];

		let anyEncounteredRecolementArticle;

		for (let article of articles) {
			for (let recolement of article.recolements) {
				if (recolement.sit_id == this.bulkStatutsReplacementSiteId && recolement.sta_code == 'inc') {
					recolement.sta_code = this.bulkStatutsReplacement;

					if (anyEncounteredRecolementArticle == undefined) {
						anyEncounteredRecolementArticle = recolement;
					}
				}
			}
		}

		if (anyEncounteredRecolementArticle) {
			this.onRecolementArticleChange(anyEncounteredRecolementArticle);
		}
		this.checkHasChanges();
	}

	submit() {
		if (this.etapeConformite == 'recolements') {

			this.siteService.updateSomeRecolementsTextes(this.abonne_courant.abo_id, this.siteIds, [this.texteRecolement!])
			.subscribe(() => {
				this.eventManager.emit('toast', {severity: 'success', summary: this.translate.instant('recolements.updated_success')});
				this.dialogRef.close({refresh: true});
			});
		}
		else { // évaluations
			let texte = structuredClone(this.texteEvaluation!);

			// On envoie seulement les évaluations correspondant à un récolement "applicable"
			texte.evaluations = texte.evaluations.filter((evaluation: EvaluationTexte) => evaluation.sta_code == 'app');
			for (let article of texte.articles) {
				article.evaluations = article.evaluations.filter((evaluation: EvaluationArticle) => evaluation.sta_code == 'app');
			}

			this.siteService.updateSomeEvaluationsTextes(this.abonne_courant.abo_id, this.siteIds, [texte])
			.subscribe(() => {
				this.eventManager.emit('toast', {severity: 'success', summary: this.translate.instant('assessments.updated_success')});
				this.dialogRef.close({refresh: true});
			});
		}
	}

	checkHasChanges() {
		if (this.etapeConformite == 'recolements') {
			this.hasChanges =
				!simpleComparison(this.texteRecolement!.recolements, this.pristineTexteRecolement!.recolements)
				|| !simpleComparison(this.recolementsArticlesById, this.pristineRecolementsArticlesById);
		}
		else {
			this.hasChanges =
				!simpleComparison(this.texteEvaluation!.evaluations, this.pristineTexteEvaluation!.evaluations)
				|| !simpleComparison(this.evaluationsArticlesById, this.pristineEvaluationsArticlesById);
		}
	}

	exit() {
		this.dialogRef.close();
	}

	scrollToTheTop() {
		this.topBar.nativeElement.scrollIntoView();
	}
}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		MenuModule,
		PanelModule,
		DividerModule,
		InputTextareaModule,
		TexteModule,
		StatutRecolementSelectorModule,
		HTMLEditorModule,
		VirtualScrollerModule,
		DataViewModule,
		UiScrollModule,
		EvaluationSelectorModule,
		SiteSelectorModule,
		CalendarModule
	],
	exports: [ConformiteTexteDialogComponent],
	declarations: [ConformiteTexteDialogComponent]
})
export class ConformiteTexteDialogModule { }
